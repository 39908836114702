import React, { Component } from 'react';
// import db from '../../config/firebase';
import { GATEWAY_URL, FUNCTION_API_KEY, HIDE_TRY_NOW_COUNTRIES, PAYMENT_TRIAL_PERIOD, currencyData, PAYMENT_CHECKOUT_URL, DOMAIN_NAME, AMPLITUDE_KEY } from '../../config/siteConfig';
import { logActivityInFirestore } from '../../services/service';
import moment from 'moment-timezone';
import { getDateInUTC } from "../livechat/components/Comman";
import { checkPaymentStatus } from '../../services/payment';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import firebaseServices from '../../firebase';


class UpgradeAccpunt extends Component {
    constructor(props) {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (this.agent === false) {
            window.location.href = "/"
        } else if (this.agent.roleType !== 'Owner') {
            const orgnizationAlias = this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire;
            window.location.href = "/" + orgnizationAlias;
        } else if (this.agent.visitedUpgrade === true&&false) {
            const orgnizationAlias = this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire;
            window.location.href = "/" + orgnizationAlias;
        }

        this.state = {
            loader: true,
            plan: '',
            modalVisible: false,
            billing_cycle: '',
            amount: '',
            agents: '',
            chats_completed: '',
            next_billing_date: '',
            billing_information: '',
            plan_data: [],
            billingList: '',
            no_of_agent: 1,
            requestKey: '',
            errormessage: '',
            currency: '$',
            errormessage: '',
            showFree:false,
            planprice: '',
            totalprice: '',
            payment_list: [],
            historyCount: 0,
            history_link: '',
            owner_id: '',
            company_name: '',
            owner_email: '',
            current_plan_id: '',
            current_plan_period: '',
            current_agent: 1,
            commonlogin_user_id: '',
            payment_status: '',
            payment_enabled: 0,
            chat_count: 1,
            currencyData: currencyData,
            show_currency: '',
            country_code: 'US',
            plan_list: [],
            planPriceYearly: 84,
            planPriceMonthly: 10,
            planPriceDiscount: 3,
            showGoToDashboard: true,
            totalAgents: 1,
            showTryNowButon: false,
            trialStatus: true,
            countryCode: '',
            originalCountryCode: '',
            currencyCode: '',
            showModal: false,
            buttonClicked: '',
            hasLoaded: false,
            showFreePopup: false,
            crossPopUpClick:false
        }
    }

    checkPayment = (userDetails) => {
        return new Promise((resolve, reject) => {
            firebaseServices.db.collection('users').doc(userDetails.ownerId).get().then(async (docs) => {
                if (docs.exists) {
                    const userData = docs.data();
                    const paymentStatus = await checkPaymentStatus(userData);
                    resolve(paymentStatus);
                } else {
                    resolve(false)
                }
            }).catch((err) => {
                console.log('error at upgrade-account 92', err);
                resolve(true);
            })
        })
    }

     windowFocusOut = () => {
        console.log('yes');
        const windowFocusOut = localStorage.getItem('windowFocusOut');
        if (windowFocusOut) {

        } else {
            this.setState({
                showFreePopup: true
            })
            localStorage.setItem('windowFocusOut', true);
        }
    } 

    componentWillUnmount() {
        window.removeEventListener('focusout');
    }

    addEvent = (obj, evt, fn) => {
        if (obj.addEventListener) {
            obj.addEventListener(evt, fn, false);
        }
        else if (obj.attachEvent) {
            obj.attachEvent("on" + evt, fn);
        }
    }

    async componentDidMount() {
        const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails === false) {
            window.location.href = "/";
        }
        const paymentStatus = await this.checkPayment(userDetails);
        if (paymentStatus === true) {
            const orgnizationAlias = userDetails.organization_alias ? userDetails.organization_alias : userDetails.ownerIdentifire;
            window.location.href = "/" + orgnizationAlias;
        }
        this.setState({
            ownerId: userDetails.ownerId,
            liveChatUrl: '/' + userDetails.ownerIdentifire
        }, async () => {


            var urlParams = new URLSearchParams(window.location.search);
            // urlParams.has("cid");
            var appcountry
            setTimeout(() => {
                if (this.state.showTryNowButon)
                    this.setState({ showFree: true });
            }, 15000);
            if (urlParams.has("appcountry")) {
                appcountry = urlParams.get("appcountry")
                const showTryNowButon = HIDE_TRY_NOW_COUNTRIES.indexOf(appcountry) > -1 ? false : true;
                this.setState({ country_code: appcountry, showTryNowButon: showTryNowButon });
            } else {
                await fetch('https://snappy.appypie.com/webservices/ClientDetails.php', {
                    method: "get",
                    headers: {
                        Accept: 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/json'
                    }
                }).then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 1) {
                            const showTryNowButon = HIDE_TRY_NOW_COUNTRIES.indexOf(responseJson.CountryCode) > -1 ? false : true;
                            this.setState({ country_code: responseJson.CountryCode, showTryNowButon: showTryNowButon });
                        } else {
                            this.setState({ country_code: 'US' });
                        }
                    });
            }




            await this.getOwnerDetails();
         
        })
        this.setState({});
    }

    getPlanData = async () => {
        var agentData = JSON.parse(localStorage.getItem('agent'));
        var body = { ownerIdentifire: agentData.ownerIdentifire, email: this.state.owner_email, product_type: 'livechat', userid: this.state.commonlogin_user_id, country_code: this.state.country_code };
        //await fetch(MAIL_FUNCTION_URL + "/payment/plandetail", {
        await fetch(GATEWAY_URL + "/plandetail", {
            method: "post",
            body: JSON.stringify(body),
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                Accept: 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    var priceData = responseJson.priceData;
                    // alert(priceData.length);
                    if (priceData[0].planPeriod === 'yearly') {
                        [priceData[0], priceData[1]] = [priceData[1], priceData[0]];
                      }
                    if (priceData.length > 0) {
                        var billing_information = '';
                        var planPrice;
                        var agent = parseInt(this.state.no_of_agent);
                        var planPriceYearly = parseInt(priceData[1].planPrice);
                        console.log("planPriceYearly",planPriceYearly)
                        var planPriceYearlyMonth = parseInt(planPriceYearly) / 12;
                        var PlanDiscountYear = parseInt(planPriceMonthly - planPriceYearlyMonth);
                        this.setState({planPriceYearlyMonth:planPriceYearlyMonth})
                        var planPriceMonthly = parseInt(priceData[0].planPrice);
                        var PlanDiscountYear = parseInt(planPriceMonthly - planPriceYearlyMonth);
                        if (this.state.current_plan_period === 'yearly') {
                            let currencySign = priceData[1].currencySign == "<span class='appyicon-indian-rupee'></span>" ? "₹" : priceData[1].currencySign;
                            billing_information = priceData[1].productName + '_' + priceData[1].planId + '_' + priceData[1].planPeriod + '_' + currencySign + '_' + priceData[1].planPrice;
                            planPrice = priceData[1].planPrice;
                        } else {
                            let currencySign = priceData[1].currencySign == "<span class='appyicon-indian-rupee'></span>" ? "₹" : priceData[0].currencySign;
                            billing_information = priceData[1].productName + '_' + priceData[1].planId + '_' + priceData[1].planPeriod + '_' + currencySign + '_' + priceData[1].planPrice;
                            planPrice = priceData[1].planPrice;
                        }

                        var netprice = parseInt(planPrice) * parseInt(agent);
                        //  var history_link = PAYMENT_CHECKOUT_URL+"/billing/show/" + responseJson.history_link
                        var planList = [];
                        var optionData;

                        for (const [index, value] of responseJson.priceData.entries()) {
                            optionData = { name: value.planPeriod, keyValue: value.productName + '_' + value.planId + '_' + value.planPeriod + '_' + this.state.currencyData[value.currencyCode] + '_' + value.planPrice };
                            if ((this.state.current_plan_period === 'yearly') && value.planPeriod === 'monthly') {
                                // Do Nothing
                            } else {
                                planList.push(optionData);
                            }
                        }
                        var history_link = responseJson.history_link
                        this.setState({ totalprice: netprice, history_link: history_link, currency: this.state.currencyData[priceData[0].currencyCode], planprice: planPrice, billing_information: billing_information, plan_data: responseJson.priceData, plan_list: planList, planPriceMonthly: planPriceMonthly, planPriceYearly: planPriceYearly, planPriceDiscount: PlanDiscountYear, countryCode: priceData[0].country, originalCountryCode: priceData[0].originalCountryCode, currencyCode: priceData[0].currencyCode }, () => {
                            setTimeout(() => {
                                this.addEvent(document, "mouseout", function (e) {
                                    e = e ? e : window.event;
                                    var from = e.relatedTarget || e.toElement;
                                    if (!from || from.nodeName == "HTML") {
                                        const windowFocusOut = localStorage.getItem('windowFocusOut');
                                        if (windowFocusOut) {

                                        } else {
                                            this.setState({
                                                showFreePopup: true,
                                                showModal: false
                                            })
                                            localStorage.setItem('windowFocusOut', true);
                                        }
                                    }
                                }.bind(this));
                            }, 2000)
                        });
                    }
                    return true
                } else {
                    return false;
                }
            }).then(() => {
                this.setState({
                    loader: false
                })
                return true;
            });
    }

    getOwnerDetails = async () => {
        const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        await firebaseServices.db.collection('users').doc(this.state.ownerId).get().then(async (docs) => {
            if (docs.exists) {
                let userData = docs.data();
                let currentUnixTimeStamp = await getDateInUTC();
                var currentStatus = '0';
                var plan_type = 'Free';
                var billing_cycle = '';
                var cost = '';
                var next_billing_date = '';
                var no_of_agents = 1;
                var plan_id = '';
                var user_id = userData.user_id;
                var no_of_agent = 1;
                var owner_email = '';
                var payment_status = '';
                var payment_enabled = this.state.payment_enabled;
                var chat_count = 1;
                var show_currency = '';
                var totalAgents = 1;

                if (userDetails.ownerEmail === 'undefined' || userDetails.ownerEmail === undefined) {
                    owner_email = userData.email;
                }
                else {
                    owner_email = userDetails.ownerEmail;
                }
                if (userData.payment_status === 'undefined' || userData.payment_status === undefined) {
                    currentStatus = '0'
                    payment_status = 'complete'
                    payment_enabled = 0;
                    chat_count = 1;
                    show_currency = '';
                    totalAgents = parseInt(no_of_agents) + 1;
                }
                else {
                    let splitDate = userData.next_billing_date ? userData.next_billing_date.split('-') : '';
                    let splitDay = parseInt(splitDate[2]);
                    var paymentDate = moment(splitDate[1] + "/" + splitDay + "/" + splitDate[0] + "0:00", "M/D/YYYY H:mm").valueOf();
                    paymentDate = paymentDate + 86400000;

                    var billingDate = new Date(userData.next_billing_date);
                    var dateString = '';
                    if (billingDate.getDate() < 10) {
                        dateString = '0' + billingDate.getDate();
                    }
                    else {
                        dateString = billingDate.getDate();
                    }
                    if (billingDate.getMonth() + 1 < 10) {
                        dateString = dateString + '-0' + (billingDate.getMonth() + 1);
                    }
                    else {
                        dateString = dateString + '-' + (billingDate.getMonth() + 1);
                    }
                    dateString = dateString + '-' + billingDate.getFullYear();
                    if (userData.payment_status === 'complete') {
                        show_currency = this.state.currencyData[userData.currency]
                        if (userData.currency === 'undefined' || userData.currency === undefined) {
                            show_currency = this.state.currencyData.USD;
                        }
                        payment_enabled = 0;
                        chat_count = 0;
                        payment_status = userData.payment_status;
                        currentStatus = 1;
                        plan_type = userData.plan_type;
                        billing_cycle = userData.billing_cycle;
                        cost = userData.plan_price;
                        next_billing_date = dateString;
                        no_of_agents = userData.no_of_agents;
                        plan_id = userData.plan_id;
                        user_id = userData.user_id;
                        no_of_agent = parseInt(no_of_agents) + 1;
                        totalAgents = no_of_agent;
                    }
                    else if (paymentDate >= currentUnixTimeStamp) {
                        show_currency = this.state.currencyData[userData.currency]
                        if (userData.currency === 'undefined' || userData.currency === undefined) {
                            show_currency = this.state.currencyData.USD;
                        }
                        payment_enabled = 1;
                        chat_count = 0;
                        payment_status = userData.payment_status;
                        currentStatus = 1;
                        plan_type = userData.plan_type;
                        billing_cycle = userData.billing_cycle;
                        cost = userData.plan_price;
                        next_billing_date = dateString;
                        no_of_agents = userData.no_of_agents;
                        plan_id = userData.plan_id;
                        user_id = userData.user_id;
                        no_of_agent = parseInt(no_of_agents) + 1;
                        totalAgents = no_of_agent;
                    }
                    else {
                        show_currency = '';
                        payment_enabled = 0;
                        chat_count = 1;
                    };
                }
                var no_chat_served = userData.no_chat_served;
                var chat_limited = userData.chat_limited;
                if (userData.no_chat_served === 'undefined' || userData.no_chat_served === undefined) {
                    no_chat_served = 0;
                }

                if (userData.chat_limited === 'undefined' || userData.chat_limited === undefined) {
                    chat_limited = 500;
                }


                // billing_cycle = billing_cycle ? billing_cycle : 'yearly';

                this.setState({
                    payment_enabled: payment_enabled,
                    payment_status: payment_status,
                    owner_id: docs.id,
                    company_name: userData.company_name,
                    owner_email: owner_email,
                    current_plan_id: plan_id,
                    current_agent: parseInt(no_of_agents),
                    current_plan_period: billing_cycle,
                    plan: plan_type,
                    billing_cycle: billing_cycle,
                    amount: cost,
                    agents: no_of_agents,
                    chats_completed: no_chat_served + '/' + chat_limited,
                    commonlogin_user_id: user_id,
                    next_billing_date: next_billing_date,
                    no_of_agent: no_of_agent,
                    chat_count: chat_count,
                    show_currency: show_currency,
                    ownerEmail: userData.email,
                    ownername: userData.company_name,
                    totalAgents: totalAgents,
                    trialStatus: userData.trialStatus === true ? true : false,
                    hasLoaded: true
                }, async () => {
                    await this.getPlanData();
                });
            }
        })
    }

    handleSubmit = async (paymentType = 'pay') => {
        // e.preventDefault();
        this.setState({ loader: true , showFree:false});

        var no_of_agent = this.state.no_of_agent;
        var billing_information = this.state.billing_information;
        var checkCondition = await this.validateStatusData();
        if (checkCondition === false) {
            return false;
        }
        else {
            var agentData = JSON.parse(localStorage.getItem('agent'));
            var ownerEmail;
            var ownerId;
            var companyName;
            var curentPlanId;
            var currentPlanPeriod;

            if (billing_information === undefined || billing_information === '') {
                
                console.log("STATIC US ")
                billing_information = "livechat_6_yearly_$_10";
            }
            var billingData = billing_information.split('_');

            var uniqueId = Date.now() + ((Math.random() * 100000).toFixed());
            var orderId = 'livechat_' + uniqueId;

            if (curentPlanId === "undefined" || curentPlanId === undefined) {
                curentPlanId = "";
                currentPlanPeriod = "";
            }

            /* Added for script chat start */
            let success_url = DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/install-widget?type=success';
            if (paymentType === "pay") {
                success_url += '&ptype=paid&cpId=' + this.state.current_plan_id;
            } else {
                success_url += '&ptype=trial&cpId=' + this.state.current_plan_id;
            }
            if (this.state.plan === "Free") {
                no_of_agent = 0;
            }
            var agentString = "Current no of agent " + no_of_agent;
            var myArray = JSON.stringify({ "1": agentString });
            /* Added for script chat end */
            if (paymentType!=="trialWithoutCC" && this.state.plan === "Free" && this.agent.payment_method !== "trialWithoutCC" && this.agent.plan_price !== "trialWithoutCC") {
                // logic to give free trial without credit card details
                console.log('------------new url----------->')
                var priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + PAYMENT_TRIAL_PERIOD + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","trialSuccessUrl":"' + success_url + '", "trialIpnUrl":"' + GATEWAY_URL + '/subscription","displayAgent":true}';
                if (paymentType === "pay") {
                    priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + 0 + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","trialSuccessUrl":"' + success_url + '", "trialIpnUrl":"' + GATEWAY_URL + '/subscription","displayAgent":true}';
                }
            }
            else {
                console.log('------------older url----------->')
                var priceKey = {
                    "displayMessage": "myArray",
                    "curentPlanId": this.state.current_plan_id,
                    "currentPlanPeriod": this.state.current_plan_period,
                    "orderId": orderId,
                    "addonType": no_of_agent,
                    "noOfAgents": no_of_agent,
                    "productName": billingData[0],
                    "planId": billingData[1],
                    "planPeriod": billingData[2],
                    "emailId": this.state.owner_email,
                    "productId": this.state.owner_id,
                    "paymentType": paymentType,
                    "trialDays": PAYMENT_TRIAL_PERIOD,
                    "subscriptionType": "subscription",
                    "successUrl": success_url,
                    "cancelUrl": `${DOMAIN_NAME}/${agentData.ownerIdentifire}/paymentfail`,
                    "notifyUrl": `${GATEWAY_URL}/subscription`,
                    "resetUrl": success_url,
                    "currencyCode": this.state.currencyCode,
                    "countryCode": this.state.countryCode,
                    "originalCountryCode": this.state.originalCountryCode,
                    "requestState": "",
                    "displayAgent": true,


                }

                if (paymentType === "pay") {
                    priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + 0 + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","displayAgent":true}';
                } else {
                    priceKey.trialPaymentMethod = "stripe"
                }
            }
            var netprice = parseInt(billingData[4]) * parseInt(no_of_agent);

            var requestResponse = await this.getRequestKey({ stringdata: typeof priceKey != typeof "string" ?JSON.stringify(priceKey):priceKey, action: 'encrypt', no_of_agents: no_of_agent, netprice: netprice, currency: billingData[3], plan_name: 'Premium', plan_id: billingData[1], product_name: billingData[0], plan_period: billingData[2], order_id: orderId, product_id: this.state.owner_id, countryCode: this.state.countryCode, originalCountryCode: this.state.originalCountryCode, currencyCode: this.state.currencyCode });

            if (requestResponse === true) {
                // amplitude code starts for checkout page     
                // amplitude.getInstance().init(AMPLITUDE_KEY, this.state.owner_email); // initializes named instance of Amplitude client			
                // amplitude.getInstance().logEvent("Checkout Page", { 'ownerId': this.state.owner_id, 'email': this.state.owner_email },
                //     function () {
                //         console.log('Amplitude event was logged successfully.');
                //     }, function () {
                //         console.log('Amplitude event was not logged.');
                //     });
                // amplitude code ends
                logActivityInFirestore('Subscription upgrade plan button clicked', "upgrade_plan_btn");
                var form = document.getElementById('checkout');
                form.submit();
            }
            else {
                return false;

            }
        }
    }

    getRequestKey = async (requestData) => {
        //const response = await fetch(MAIL_FUNCTION_URL + "/payment/generateKey", {
        const response = await fetch(GATEWAY_URL + "/generateKey", {
            method: "post",
            body: JSON.stringify(requestData),
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                Accept: 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    this.setState({ requestKey: responseJson.stringdata });
                    return true;
                } else {

                    return false
                }
            });
        return response;
    }

    validateStatusData = () => {
        var checkNumber = isNaN(this.state.totalAgents);

        var billingData = this.state.billing_information.split('_');
        if (checkNumber) {
            this.setState({ loader: false, errormessage: 'Please enter valid no of Agent' });
            return false;
        }
        else if (this.state.totalAgents < 1) {
            this.setState({ loader: false, errormessage: 'Please enter valid number of Agent, agent should be greater than 0' });
            return false;
        }
        else if (this.state.totalAgents > 999) {
            this.setState({ loader: false, errormessage: 'Please enter valid number of Agent, agent should be less than 1000' });
            return false;
        }
        // else if (parseInt(this.state.totalAgents) <= parseInt(this.state.current_agent)) {
        //     this.setState({ loader: false, errormessage: 'Agent count cannot be less than count of agent for which subscription has already been bought' });
        //     return false;
        // }
        /*else if(( this.state.current_plan_period ===  billingData[2] ) && ( parseInt(this.state.no_of_agent) <= parseInt(this.state.current_agent )) )
        {
            this.setState({loader:false,errormessage:'Please plan and no of agent are same, either increase no of agent and change plan period'});	
            return false;
        }*/
        else {
            return true;
        }
    }

    goToLivechat = () => {
        // amplitude code starts
        // amplitude.getInstance().init(AMPLITUDE_KEY, this.state.owner_email); // initializes named instance of Amplitude client
        // amplitude.getInstance().logEvent("Test Drive", { 'ownerId': this.state.owner_id, 'email': this.state.owner_email },
        //     function () {
        //         console.log('Amplitude event was logged successfully.');
        //     }, function () {
        //         console.log('Amplitude event was not logged.');
        //     });
        // amplitude code ends
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails) {
            firebaseServices.db.collection("users").doc(userDetails.ownerId).update({
                visitedUpgrade: true
            }).then(() => {
                userDetails.visitedUpgrade = true;
                localStorage.setItem('agent', JSON.stringify(userDetails));
                const orgnizationAlias = userDetails.organization_alias ? userDetails.organization_alias : userDetails.ownerIdentifire;
                const redirectTo = localStorage.getItem('redirect_to');
                if (redirectTo) {
                    localStorage.removeItem('redirect_to');
                    window.location.href = redirectTo;
                } else {
                    window.location.href = "/" + orgnizationAlias;
                }
            }).catch((err) => {
                console.log('error at upgrade 453 >>> ', err);
                userDetails.visitedUpgrade = true;
                localStorage.setItem('agent', JSON.stringify(userDetails));
                const orgnizationAlias = userDetails.organization_alias ? userDetails.organization_alias : userDetails.ownerIdentifire;
                const redirectTo = localStorage.getItem('redirect_to');
                if (redirectTo) {
                    localStorage.removeItem('redirect_to');
                    window.location.href = redirectTo;
                } else {
                    window.location.href = "/" + orgnizationAlias;
                }
            })
        } else {
            window.location.href = "/";
        }
    }

    // handlePaymentChange = (e) => {
    //     let target = e.target;
    //     let values;
    //     if (target.name === "billing_information") {
    //         var billingData = target.value.split('_');
    //         //alert(this.state.no_of_agent);
    //         if (this.state.totalAgents == '') {
    //             var netprice = parseInt(billingData[4]);
    //             this.setState({
    //                 billing_information: target.value, planprice: billingData[4], totalprice: netprice
    //             })
    //         }
    //         else {
    //             var netprice = parseInt(billingData[4]) * parseInt(this.state.no_of_agent);
    //             this.setState({
    //                 billing_information: target.value, planprice: billingData[4], totalprice: netprice, errormessage: ''
    //             })
    //         }
    //     }
    //     else if (target.name === "no_of_agent") {

    //         var charCode = (e.which) ? e.which : e.keyCode;
    //         //alert(charCode);
    //         //alert(e.target.value);
    //         var valueData = target.value.replace(/\+|-/ig, '');
    //         valueData = valueData.replace('+', '');
    //         valueData = valueData.replace('-', '');
    //         valueData = valueData.replace('.', '');
    //         var billingData = this.state.billing_information.split('_');
    //         var multiplyNumber = parseInt(valueData);
    //         var checkNumber = isNaN(valueData);
    //         //alert(checkNumber)
    //         if (checkNumber === true) {
    //             valueData = this.state.no_of_agent;
    //             multiplyNumber = 1;
    //         }
    //         else if (valueData < 1) {
    //             multiplyNumber = 1;

    //         }
    //         var netprice = parseInt(billingData[4]) * parseInt(multiplyNumber);
    //         this.setState({ no_of_agent: valueData, totalprice: netprice, errormessage: '', totalAgents: valueData ? parseInt(valueData) + 1 : 1 });
    //         /*if(( charCode === 'undefined' || charCode === undefined ) && valueData === '' )
    //         {
    //             var netprice = parseInt(billingData[4])*parseInt(1);
    //             this.setState({no_of_agent:valueData,totalprice:netprice});
    //         }
    //         else if(valueData === '' )
    //         {
    //             e.preventDefault();		
    //         }
    //         else
    //         {
    //             var netprice = parseInt(billingData[4])*parseInt(valueData);
    //             this.setState({no_of_agent:valueData,totalprice:netprice});	 
    //         }
    //               */
    //     } else {
    //         values = target.value;
    //     }

    // }

    // closeModal = () => {
    //     this.setState({
    //         buttonClicked: '',
    //         showModal: false
    //     })
    // }

    render() {
        return (
            <>
                <div className="login-container loginBG minHeight" >
                    <div className="loginPage">
                        <div className="container">
                            <div className="login-header">
                                <img
                                    className="loginHeaderLogo"
                                    src={require("../../assets/images/REO.png")}
                                    alt="logo"
                                />
                            </div>
                        </div>
                        <div className="container planWrapperTop" onMouseLeave={() => {
                            console.log("hello",this.state.crossPopUpClick,this.state.showTryNowButon)
					if (!this.state.crossPopUpClick &&this.state.showTryNowButon) {
						this.setState({ showFree: true })
					}
				}}>
                            {this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}
                            {/* <div className="loginBody pt-5">
                                <div className="login-description signup-description veryfy-your-mail-description">
                                    <h5>Upgrade now to Live Chat Premium</h5>
                                </div>
                                <div id="owner_login" style={{ display: this.state.ownerLogin ? 'block' : 'block' }} >
                                    <div className="login-body" style={{ textAlign: "center" }}>
                                    </div>
                                    <div className="text-center pt-8">
                                        {this.state.hasLoaded ? (<> {this.state.showTryNowButon && !this.state.trialStatus ? (<button onClick={() => {
                                            this.setState({
                                                showModal: true,
                                                buttonClicked: 'try'
                                            })
                                        }}>Try Now</button>) : (<button onClick={() => {
                                            this.setState({
                                                showModal: true,
                                                buttonClicked: 'buy'
                                            })
                                        }}>Buy Now</button>)} <button onClick={this.goToLivechat}>Free start</button> </>) : (null)}
                                    </div>
                                </div>
                            </div> */}


                            <h4>Preview your Livechat for free </h4>
                            <div className="planWrapper mt-8">
                                <div className="planPhoneScreen">
                                    <h4>Premium</h4>{console.log("planPriceYearlyMonth",this.state.planPriceYearlyMonth)}
                                    {!this.state.showTryNowButon|| true ? (<h2 className="bigTxt"><span className="currencySymbol">{this.state.currency}</span> {this.state.planPriceYearlyMonth}<span className="smallTxt"> / agent / month</span></h2>) : (null)}
                                    <h6 className='text-center'>(Billed Annually)</h6>
                                    <div className="highlightBtn mt-3">
                                        {this.state.hasLoaded ? (<> {this.state.showTryNowButon && !this.state.trialStatus ? (<strong style={{ fontSize: '15px' }} onClick={() => this.handleSubmit('trial')}>
                                            <span>Start Free 7 Day Trial</span> <span class="smallBtnText">Before competitors knock you out... </span></strong>) : (<strong style={{ fontSize: '15px' }} onClick={() => this.handleSubmit('buy')}>
                                                <span>Grab<b> NOW </b> at lowest cost !</span><span className="smallBtnText">until our CEO returns from vacation...</span></strong>)} </>) : (null)}
                                    </div>
                                    {this.state.hasLoaded ? (<> {this.state.showTryNowButon && !this.state.trialStatus ? (<p className="termRule">No obligations, no contracts, cancel at any time </p>) : (<p className="termRule">*30-Day money back guarantee </p>)} </>) : (null)}
                                    {this.state.hasLoaded ? (<> {this.state.showTryNowButon && !this.state.trialStatus ? (<p className="termRule">Not Ready to share Credit Card details ? <span className='trialWithoutCC' style={{color: "blue", cursor:"pointer"}} onClick={() => this.handleSubmit('trialWithoutCC')}>Click Here</span> </p>) : (<p className="termRule"> </p>)} </>) : (null)}

                                    <ul className="mt-5">
                                        <li>Custom roles available</li>
                                        <li>Unlimited chat history</li>
                                        <li>Get Enriched Leads</li>
                                        <li>Identify Prospects</li>
                                        <li>Integrate with Twitter account</li>
                                        <li>Integrate with Facebook page</li>
                                        <li>Download transcript</li>
                                        <li>Email support</li>
                                        <li>Live agent support</li>
                                    </ul>
                                </div>

                                <div className="planDetailServices">
                                    <div className="moneyBanner d-flex flex-wrap">
                                        <div className="bannerText">
                                            <p><strong>Why choose us ?</strong></p>
                                            <p><strong>30-day Satisfaction with money back Guarantee</strong></p>
                                            <p>if you're not satisfied with your products we will issue a full refund, no questions asked. </p>
                                        </div>
                                        <div className="moneyImg">
                                            <img src={require("../../assets/img/icon/save-money.svg")} />
                                        </div>
                                    </div>
                                    <ul>
                                        {/* <li>
                                            <img src={require("../../assets/images/trend.svg")} />
                                            <span>Increase sales</span>
                                        </li> */}
                                        <li>
                                            <svg id="problem" xmlns="http://www.w3.org/2000/svg" width="70.099" height="70.114" viewBox="0 0 70.099 70.114">
                                                <path id="Path_100942" data-name="Path 100942" d="M16.2,48.3l-.623-.374c-.2-.12-.39-.253-.586-.378v12.64H17.4V47.545c-.2.126-.387.259-.586.378Z" transform="translate(3.13 9.93)" fill="#484444" />
                                                <path id="Path_100943" data-name="Path 100943" d="M4.615,28.253H5.822V21H4.615a3.627,3.627,0,0,0,0,7.253Z" transform="translate(0.206 4.386)" fill="#484444" />
                                                <path id="Path_100944" data-name="Path 100944" d="M24.938,41.635c-.332-.195-.655-.37-.972-.526a25.474,25.474,0,0,1-3.183,4.041l3.008.5Z" transform="translate(4.341 8.586)" fill="#484444" />
                                                <path id="Path_100945" data-name="Path 100945" d="M30.659,38.692V28.9a6.039,6.039,0,0,1-1.209.122H28.084a12.009,12.009,0,0,1-6.524,8.924l-.574.285v1.85a17.837,17.837,0,0,1,8.126,4.187A10.748,10.748,0,0,0,30.659,38.692Z" transform="translate(4.383 6.036)" fill="#484444" />
                                                <path id="Path_100946" data-name="Path 100946" d="M11.84,47.541l-3.05.508-1.516-5.3C3.755,45.655.259,51.08,0,61.186H13.84L9.505,51.433Z" transform="translate(0 8.927)" fill="#484444" />
                                                <path id="Path_100947" data-name="Path 100947" d="M10.112,50.88,13.587,58.7V46.18l-.728.121Z" transform="translate(2.112 9.645)" fill="#484444" />
                                                <path id="Path_100948" data-name="Path 100948" d="M5.406,22.133V19.715a13.3,13.3,0,1,1,26.595,0v2.418h1.209a6.039,6.039,0,0,1,1.209.122v-2.54a15.715,15.715,0,1,0-31.43,0v2.54A6.03,6.03,0,0,1,4.2,22.133Z" transform="translate(0.624 0.835)" fill="#484444" />
                                                <path id="Path_100949" data-name="Path 100949" d="M20.837,43h-8.27A22.97,22.97,0,0,0,16.7,46.411,23,23,0,0,0,20.837,43Z" transform="translate(2.625 8.981)" fill="#484444" />
                                                <path id="Path_100950" data-name="Path 100950" d="M23.847,41.06c-.447-.137-.845-.236-1.154-.3a2.421,2.421,0,0,1-1.92-2.365V36.97a6.023,6.023,0,0,1-4.835,2.455H13.278A2.4,2.4,0,0,1,11.6,40.76c-.319.065-.713.162-1.158.3.178.267.364.527.552.786H23.3C23.485,41.584,23.671,41.324,23.847,41.06Z" transform="translate(2.181 7.721)" fill="#484444" />
                                                <path id="Path_100951" data-name="Path 100951" d="M6.988,21.935v7.781a9.663,9.663,0,0,0,1.106,4.49,6.02,6.02,0,0,1,4.939-2.6h3.627a6.026,6.026,0,0,1,5.926,7.216,2.339,2.339,0,0,1,.247-.169l.573-.285a9.619,9.619,0,0,0,5.344-8.651V21.9a27.343,27.343,0,0,1-15.334-5.375,12.042,12.042,0,0,1-6.425,5.111Zm16.924,1.209A1.209,1.209,0,1,1,22.7,24.353,1.209,1.209,0,0,1,23.912,23.144Zm-7.126,4.167a1.215,1.215,0,0,1-.127-.54V24.353h2.418v2.132l.54,1.082a2.418,2.418,0,0,1-1.082,3.243l-1.336.668-1.082-2.163,1.336-.668Zm-4.962-4.167a1.209,1.209,0,1,1-1.209,1.209A1.209,1.209,0,0,1,11.824,23.144Z" transform="translate(1.459 3.451)" fill="#484444" />
                                                <path id="Path_100952" data-name="Path 100952" d="M4.988,31.418A2.416,2.416,0,0,0,7.033,33.8,12.03,12.03,0,0,1,5.152,29H4.988Z" transform="translate(1.042 6.057)" fill="#484444" />
                                                <path id="Path_100953" data-name="Path 100953" d="M3.052,37.957a10.758,10.758,0,0,0,1.459,4.407,18.138,18.138,0,0,1,4.935-3.147H7.81a9.6,9.6,0,0,1-4.758-1.261Z" transform="translate(0.637 7.928)" fill="#484444" />
                                                <path id="Path_100954" data-name="Path 100954" d="M12.633,14.557l1.173.977a24.9,24.9,0,0,0,14.941,5.722V18.88a10.88,10.88,0,1,0-21.759,0v1.929a9.606,9.606,0,0,0,4.775-4.51Z" transform="translate(1.459 1.671)" fill="#484444" />
                                                <path id="Path_100955" data-name="Path 100955" d="M11.911,45.153A25.382,25.382,0,0,1,8.72,41.1q-.473.232-.97.523L8.9,45.654Z" transform="translate(1.619 8.584)" fill="#484444" />
                                                <path id="Path_100956" data-name="Path 100956" d="M26.988,21v7.253H28.2A3.627,3.627,0,1,0,28.2,21Z" transform="translate(5.637 4.386)" fill="#484444" />
                                                <path id="Path_100957" data-name="Path 100957" d="M40.431,58.418H55.609A2.42,2.42,0,0,0,58.027,56H41.1A4.762,4.762,0,0,1,40.431,58.418Z" transform="translate(8.444 11.696)" fill="#484444" />
                                                <path id="Path_100958" data-name="Path 100958" d="M19.715,46.3l-.728-.121V58.7l3.475-7.821Z" transform="translate(3.966 9.645)" fill="#484444" />
                                                <path id="Path_100959" data-name="Path 100959" d="M36.988,41.209V56.924h2.418V40H38.2A1.21,1.21,0,0,0,36.988,41.209Z" transform="translate(7.725 8.354)" fill="#484444" />
                                                <path id="Path_100960" data-name="Path 100960" d="M56.7,40H40.988V56.924H57.912V41.209A1.21,1.21,0,0,0,56.7,40ZM49.45,48.462a2.418,2.418,0,1,1,2.418-2.418A2.417,2.417,0,0,1,49.45,48.462Z" transform="translate(8.561 8.354)" fill="#484444" />
                                                <path id="Path_100961" data-name="Path 100961" d="M25.92,56.347h8.014c-1.063-7.048-3.945-11.183-6.848-13.585l-1.509,5.284-3.05-.508,2.334,3.891-4.335,9.753h5.616a3.586,3.586,0,0,1-.222-1.209Z" transform="translate(4.287 8.931)" fill="#484444" />
                                                <path id="Path_100962" data-name="Path 100962" d="M26.988,57.209A1.21,1.21,0,0,0,28.2,58.418h10.88A2.42,2.42,0,0,0,41.494,56H26.988Z" transform="translate(5.637 11.696)" fill="#484444" />
                                                <path id="Path_100963" data-name="Path 100963" d="M60.956,0H39.2a1.21,1.21,0,0,0-1.209,1.209V22.968A1.21,1.21,0,0,0,39.2,24.177h10.88v4.55a.3.3,0,0,0,.54.127l2.339-4.677h8a1.21,1.21,0,0,0,1.209-1.209V1.209A1.21,1.21,0,0,0,60.956,0ZM41.615,21.759a1.209,1.209,0,1,1,1.209-1.209A1.209,1.209,0,0,1,41.615,21.759Zm1.209-4.835H40.406V2.418h2.418Zm6.044,4.835a1.209,1.209,0,1,1,1.209-1.209A1.209,1.209,0,0,1,48.868,21.759Zm4.835,0a1.209,1.209,0,1,1,1.209-1.209A1.209,1.209,0,0,1,53.7,21.759Zm4.835,0a1.209,1.209,0,1,1,1.209-1.209A1.209,1.209,0,0,1,58.539,21.759Z" transform="translate(7.934)" fill="#484444" />
                                                <path id="Path_100964" data-name="Path 100964" d="M8.241,38.885h9.671a3.627,3.627,0,1,0,0-7.253H14.285a3.631,3.631,0,0,0-3.627,3.627v1.209H8.241a4.84,4.84,0,0,1-4.835-4.835v-2.54A6,6,0,0,1,.988,27.975v3.657a7.26,7.26,0,0,0,7.253,7.253Z" transform="translate(0.206 5.843)" fill="#484444" />
                                            </svg>
                                            <span>Solve customer problems in a flash</span>
                                        </li>
                                        {/* <li>
                                            <img src={require("../../assets/images/cost.svg")} />
                                            <span>Cut costs</span>
                                        </li> */}
                                        <li>
                                            <svg id="high-five" xmlns="http://www.w3.org/2000/svg" width="81.32" height="71.114" viewBox="0 0 81.32 71.114">
                                                <path id="Path_100972" data-name="Path 100972" d="M245.765,2.382a2.382,2.382,0,1,0-4.765,0V12.59a2.382,2.382,0,1,0,4.765,0Zm0,0" transform="translate(-202.722)" fill="#484444" />
                                                <path id="Path_100973" data-name="Path 100973" d="M150.4,57.37A2.382,2.382,0,1,0,153.769,54l-5.1-5.1a2.382,2.382,0,0,0-3.369,3.37Zm0,0" transform="translate(-121.631 -40.544)" fill="#484444" />
                                                <path id="Path_100974" data-name="Path 100974" d="M307.648,58.068a2.373,2.373,0,0,0,1.684-.7l5.1-5.1a2.382,2.382,0,0,0-3.369-3.369l-5.1,5.1a2.382,2.382,0,0,0,1.684,4.067Zm0,0" transform="translate(-256.781 -40.544)" fill="#484444" />
                                                <path id="Path_100975" data-name="Path 100975" d="M33.768,141.658l-.071.059-10.3,8.83a5.28,5.28,0,0,1-3.432,1.269H12.587A12.581,12.581,0,0,0,0,164.4v22.97a2.383,2.383,0,0,0,2.382,2.382H22.8a2.383,2.383,0,0,0,2.382-2.382V165.5L37.3,155.11a2.792,2.792,0,0,0,.975-2.121v-9.147A2.78,2.78,0,0,0,33.768,141.658Zm0,0" transform="translate(0 -118.641)" fill="#484444" />
                                                <path id="Path_100976" data-name="Path 100976" d="M296.691,151.815h-7.378a5.277,5.277,0,0,1-3.432-1.269l-10.3-8.829-.071-.059a2.78,2.78,0,0,0-4.51,2.185v9.147a2.792,2.792,0,0,0,.975,2.121L284.1,165.5v21.871a2.382,2.382,0,0,0,2.382,2.382H306.9a2.382,2.382,0,0,0,2.382-2.382V164.4A12.581,12.581,0,0,0,296.691,151.815Zm0,0" transform="translate(-227.957 -118.641)" fill="#484444" />
                                                <path id="Path_100977" data-name="Path 100977" d="M36.142,92.5A10.038,10.038,0,1,0,26.1,102.541,10.049,10.049,0,0,0,36.142,92.5Zm0,0" transform="translate(-13.515 -69.367)" fill="#484444" />
                                                <path id="Path_100978" data-name="Path 100978" d="M389.611,92.5a10.038,10.038,0,1,0-10.038,10.038A10.049,10.049,0,0,0,389.611,92.5Zm0,0" transform="translate(-310.842 -69.367)" fill="#484444" />
                                            </svg>
                                            <span>Build stronger relationships with customers</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="70.118" height="70.114" viewBox="0 0 70.118 70.114">
                                                <g id="rocket" transform="translate(0 -0.001)">
                                                    <path id="Path_100979" data-name="Path 100979" d="M66.271.007c-13.687-.245-29.3,6.909-39.178,18.008A37.073,37.073,0,0,0,1.688,28.834a1.461,1.461,0,0,0,.818,2.486l11.26,1.613L12.375,34.49a1.46,1.46,0,0,0,.058,2.007L31.21,55.273a1.464,1.464,0,0,0,2.007.056l1.557-1.391L36.387,65.2a1.454,1.454,0,0,0,1.034,1.128,1.343,1.343,0,0,0,.418.064,1.65,1.65,0,0,0,1.131-.47A37,37,0,0,0,49.694,40.607C60.8,30.706,68.018,15.1,67.7,1.433A1.468,1.468,0,0,0,66.271.007ZM52.809,25.226a7.3,7.3,0,1,1,0-10.33A7.281,7.281,0,0,1,52.809,25.226Z" transform="translate(2.408 0)" fill="#484444" />
                                                    <path id="Path_100980" data-name="Path 100980" d="M7.958,18.641C4.832,21.767.541,35.889.061,37.488a1.462,1.462,0,0,0,1.4,1.881,1.5,1.5,0,0,0,.421-.061c1.6-.479,15.721-4.771,18.847-7.9a9.03,9.03,0,0,0-12.77-12.77Z" transform="translate(0 30.746)" fill="#484444" />
                                                </g>
                                            </svg>
                                            <span>Boost your credibility</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="70.114" height="70.114" viewBox="0 0 70.114 70.114">
                                                <path id="happy" d="M35.057,0A35.057,35.057,0,1,0,70.114,35.057,35.1,35.1,0,0,0,35.057,0ZM17.489,28.487A2.054,2.054,0,0,1,13.6,27.159a8.97,8.97,0,0,1,16.974-.006,2.054,2.054,0,1,1-3.887,1.331,4.861,4.861,0,0,0-9.2,0Zm31.685,17.96a16.433,16.433,0,0,1-28.213.034,2.054,2.054,0,0,1,3.524-2.111,12.325,12.325,0,0,0,21.16-.026,2.054,2.054,0,1,1,3.529,2.1Zm6.071-16.685a2.054,2.054,0,0,1-2.609-1.278,4.861,4.861,0,0,0-9.2,0,2.054,2.054,0,0,1-3.888-1.328,8.97,8.97,0,0,1,16.974-.006A2.054,2.054,0,0,1,55.245,29.762Z" fill="#484444" />
                                            </svg>
                                            <span>Make your customer happier</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="83.833" height="71.114" viewBox="0 0 83.833 71.114">
                                                <g id="group" transform="translate(0 -38.84)">
                                                    <g id="Group_23076" data-name="Group 23076" transform="translate(65.248 77.233)">
                                                        <g id="Group_23075" data-name="Group 23075">
                                                            <path id="Path_100987" data-name="Path 100987" d="M404.977,273.32h-6.483a16.865,16.865,0,0,1,1.022,5.8v24.5a7.228,7.228,0,0,1-.416,2.42h10.718a7.269,7.269,0,0,0,7.261-7.261V285.422A12.115,12.115,0,0,0,404.977,273.32Z" transform="translate(-398.494 -273.32)" fill="#484444" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_23078" data-name="Group 23078" transform="translate(0 77.233)">
                                                        <g id="Group_23077" data-name="Group 23077">
                                                            <path id="Path_100988" data-name="Path 100988" d="M17.563,279.118a16.864,16.864,0,0,1,1.022-5.8H12.1A12.116,12.116,0,0,0,0,285.423v13.358a7.269,7.269,0,0,0,7.261,7.261H17.98a7.228,7.228,0,0,1-.416-2.421Z" transform="translate(0 -273.321)" fill="#484444" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_23080" data-name="Group 23080" transform="translate(22.404 70.928)">
                                                        <g id="Group_23079" data-name="Group 23079">
                                                            <path id="Path_100989" data-name="Path 100989" d="M163.753,234.815H148.931a12.116,12.116,0,0,0-12.1,12.1v24.5a2.42,2.42,0,0,0,2.42,2.42h34.185a2.42,2.42,0,0,0,2.42-2.42v-24.5A12.116,12.116,0,0,0,163.753,234.815Z" transform="translate(-136.829 -234.815)" fill="#484444" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_23082" data-name="Group 23082" transform="translate(27.363 38.84)">
                                                        <g id="Group_23081" data-name="Group 23081" transform="translate(0 0)">
                                                            <path id="Path_100990" data-name="Path 100990" d="M181.668,38.84a14.551,14.551,0,1,0,14.554,14.554A14.57,14.57,0,0,0,181.668,38.84Z" transform="translate(-167.114 -38.84)" fill="#484444" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_23084" data-name="Group 23084" transform="translate(5.476 52.405)">
                                                        <g id="Group_23083" data-name="Group 23083">
                                                            <path id="Path_100991" data-name="Path 100991" d="M44.327,121.689a10.879,10.879,0,1,0,10.884,10.884A10.9,10.9,0,0,0,44.327,121.689Z" transform="translate(-33.443 -121.689)" fill="#484444" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_23086" data-name="Group 23086" transform="translate(56.589 52.405)">
                                                        <g id="Group_23085" data-name="Group 23085">
                                                            <path id="Path_100992" data-name="Path 100992" d="M356.492,121.689a10.884,10.884,0,1,0,10.884,10.884A10.9,10.9,0,0,0,356.492,121.689Z" transform="translate(-345.607 -121.689)" fill="#484444" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span>Manage your team with ease</span>
                                        </li>
                                        {/* <li>
                                            <img src={require("../../assets/images/web-security.svg")} />
                                            <span>Keep your data secure</span>
                                        </li> */}
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="80.201" height="70.114" viewBox="0 0 80.201 70.114">
                                                <g id="live-chat" transform="translate(0 -38.487)">
                                                    <g id="Group_23087" data-name="Group 23087" transform="translate(0 38.487)">
                                                        <path id="Path_100996" data-name="Path 100996" d="M171.235,66.359c0-15.369-14.91-27.872-33.236-27.872s-33.236,12.5-33.236,27.872S119.673,94.232,138,94.232a40.143,40.143,0,0,0,4.607-.265c7.059,4.484,12.27,6.939,15.5,7.3a3.121,3.121,0,0,0,.336.019,3.078,3.078,0,0,0,2.681-4.588c-.026-.048-2.519-4.6-1.8-8.939C166.906,82.449,171.235,74.694,171.235,66.359Zm-42.262,4.21a4.923,4.923,0,1,1,4.924-4.923A4.923,4.923,0,0,1,128.973,70.569Zm18.053,0a4.923,4.923,0,1,1,4.923-4.923A4.924,4.924,0,0,1,147.026,70.569Z" transform="translate(-91.034 -38.487)" fill="#484444" />
                                                        <path id="Path_100997" data-name="Path 100997" d="M20.75,194.724a30.2,30.2,0,0,1-7.726-9.5,25.868,25.868,0,0,1,0-23.82c.148-.288.3-.573.459-.857C5.344,165.212,0,173,0,181.794c0,7.905,4.258,15.235,11.7,20.165,1.374,5.4-1.814,11.116-1.848,11.175a1.026,1.026,0,0,0,.892,1.532,1.045,1.045,0,0,0,.114-.006c3.722-.413,10.276-4.2,15.242-7.386a37.939,37.939,0,0,0,5.087.342,35.664,35.664,0,0,0,17.6-4.514c-1.12.09-2.247.137-3.372.137A38.608,38.608,0,0,1,20.75,194.724Z" transform="translate(0 -144.552)" fill="#484444" />
                                                    </g>
                                                </g>
                                            </svg>
                                            <span>Connect with your favorite tools</span>
                                        </li>


                                    </ul>
                                </div>


                            </div>

                        </div>
                    </div>

                    {/* {this.state.showFreePopup ? (<div className="trialPopup fixed">
                        <div className="trialPopupBody">
                            <span className="closeBtn" onClick={() => { this.setState({ showFreePopup: false }) }}></span>
                            <img src={require("../../assets/images/free_trial.svg")} />
                            <h4 className="mb-3">Not ready for Livechat Free Trial?</h4>
                            <p className="mb-3">No Problem! We've got you covered...</p>
                            <button className="btnBlueSettings" onClick={this.goToLivechat}>Start Test-drive mode</button>
                        </div>
                    </div>) : (null)} */}

                </div>
                <form name="checkout" id="checkout" method="POST" action={PAYMENT_CHECKOUT_URL + "/index/pay"}>
                    <input type="hidden" name="request" value={this.state.requestKey} />
                </form>
                {/* {this.state.showModal === true ? (<> <form name="checkout" id="checkout" method="POST" action={PAYMENT_CHECKOUT_URL + "/index/pay"}>
                    <input type="hidden" name="request" value={this.state.requestKey} />
                </form>
                    <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>

                    <div className="popupShadow-upgradePlan" style={{ display: 'block' }}></div>

                    <div className="popupInstallLivechat popupLivechat planPopup" style={{ display: 'block' }}>
                        <div className="warning-ms">
                            <p>
                                <span className="info-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info" viewBox="0 0 16 16">
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                    </svg>
                                </span>
                                <span className="plan-txt">Please upgrade to our paid plan to receive inquiries from your visitors in Live mode.</span>
                                <span className="heder-icon-img" onClick={this.closeModal}>✖︎</span>
                            </p>
                        </div>
                        <div className="popupHeader py-4 px-5 k-flex align-items-center">
                            <h6 className="card-title mb-0 white-text">Plan Selection</h6>
                        </div>

                        <div className="popupBody pb-5 px-8 text-center">
                            <div className="billingDetails">
                                <ul>
                                    <li>
                                        <div className="billing-text k-flex align-items-center">
                                            <div className="plan-section-left"><h6>Plan</h6></div>
                                            <div className="plane-section-right"> <p className="ml-auto">Premium</p></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="billing-text k-flex align-items-center">
                                            <div className="plan-section-left"><h6>Billing Cycle</h6></div>
                                            <div className="plane-section-right">
                                                <div className="material-select">
                                                    <FormControl variant="outlined" className="material-outlined w-150 no-border">
                                                        <InputLabel id="billing_information-select-outlined-label">Billing Cycle</InputLabel>
                                                        <Select MenuProps={{ disableScrollLock: true }} labelId="billing_information-select-outlined-label" id="billing_information" name="billing_information" value={this.state.billing_information ? this.state.billing_information : ""} onChange={this.handlePaymentChange} label="Billing Cycle">
                                                            {this.state.plan_list.map((i, k) => {
                                                                return <MenuItem key={i.keyValue} value={i.keyValue}>{i.name}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="billing-text k-flex align-items-center">
                                            <div className="plan-section-left"><h6>Agents</h6></div>
                                            <div className="plane-section-right">
                                                <p className="ml-auto no-border">
                                                    <div className="input-group material-input">
                                                        <TextField label="Agents" id="no_of_agent" type="number" className="form-control w-150" name="no_of_agent" value={this.state.no_of_agent} onChange={this.handlePaymentChange} onKeyUp={this.handlePaymentChange} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: 1 } }} variant="outlined" />
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="billing-text k-flex align-items-center">
                                            <div className="plan-section-left"><h6>Total</h6></div>
                                            <div className="plane-section-right"> <p className="ml-auto">{this.state.currency}{this.state.totalprice}</p></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="keyFeatures mb-5">
                                <div className="DetsilsTitle mb-3 text-left key-features-h mt-5">
                                    <h5>Key features</h5>
                                </div>
                                <div className="keyFeaturesList text-left features">
                                    <ul>
                                        <li>
                                            <div className="keyFeaturesListText k-flex align-items-center">
                                                <img src={require("../../assets/images/checked.svg")} alt="" />
                                                <p className="pl-2">Unlimited chats</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="keyFeaturesListText k-flex align-items-center">
                                                <img src={require("../../assets/images/checked.svg")} alt="" />
                                                <p className="pl-2">Get Enriched Leads and Identify Prospects</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="keyFeaturesListText k-flex align-items-center">
                                                <img src={require("../../assets/images/checked.svg")} alt="" />
                                                <p className="pl-2">Integrate with Twitter account</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="keyFeaturesListText k-flex align-items-center">
                                                <img src={require("../../assets/images/checked.svg")} alt="" />
                                                <p className="pl-2">Integrate with Facebook page</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className={this.state.showTryNowButon && !this.state.trialStatus ? "my-4" : "my-4"}>
                                    {this.state.buttonClicked === 'try' && this.state.showTryNowButon && !this.state.trialStatus ? (<button onClick={(e) => { this.handleSubmit(e, 'trial') }} className="btnBlueSettings add-agent-clik">Try Now</button>) : (<button onClick={this.handleSubmit} className="btnBlueSettings">Buy Now</button>)}
                                </div>
                            </div>
                        </div>

                    </div> </>) : (null)} */}
                    {this.state.showFree ? (
				<div className="trialPopup fixed">
					<div className="trialPopupBody">
						<span onClick={() => { this.setState({ showFree: false, crossPopUpClick: true }) }} className="closeBtn"></span>
						<img src={require("../../assets/images/free_trial.svg")} />
						<h4 className="mb-2">Not ready to share the credit card details?</h4>
						<p className="mb-3">No Problem! We've got you covered...</p>
						<button onClick={() => { this.handleSubmit('trialWithoutCC') }} className="btnBlueThm">Continue without credit card</button>
					</div>
				</div>) : (null)}
            </>
        )
    }
}

export default UpgradeAccpunt;