
/**
 * 
 * @description Widget setup Component page
 * @author Bineet kumar Chaubey <bineet@appypiellp.com>
 * @version 1.0
 */

import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";
import { NotificationContainer, NotificationManager } from "react-notifications";
// import db from '../../config/firebase';
import { getDynamicUrl } from './Model/DbModel'
import { validation } from "./../../config/SignupLogin";
import { mailUrl } from './../../config/siteConfig'
import { DOMAIN_NAME } from './../../config/siteConfig';
import firebaseServices from '../../firebase';
const publicIp = require("public-ip");

class WidgetSetupComponent extends Component {
    constructor(props) {
        super(props)
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {}
        this.state = {
            widgestUrl: '',
            emailUser: '',
            linkUrl: '',
            loader: false,
            openEmailBox: false,
            widgetVarify: false,
            widgetVarifyProcess: false,
            ownerEmail: '',
            company_name:'',
            ownerAgentName:''
        }
    }

    /**
	 * check if login or not
	 */
    componentDidMount = async () => {

        // const host =  window.location.host
        // const protocol =  window.location.protocol
        
        // const dybamicURL = `${protocol}//${host}`
        
        this.setState({ loader: true });
        localStorage.getItem("agent");
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        //  if  Tocken is not verifire use is not authticate then redirect to login page
        if (userDetails && userDetails.ownerId) {
            var res = await validation(userDetails.ownerId);
            if (res.valid) {
                this.setState({ loader: false, ownerEmail: userDetails.email, company_name:userDetails.company_name, ownerAgentName:userDetails.name });

            } else {
                // this.props.history.push("/login");
                window.location = '/login'
                return
            }
        } else {
            this.setState({ loader: false });
            // this.props.history.push("/login");
            window.location = '/login'
            return
        }

        const dybamicURL = getDynamicUrl();
        // const initlaWidgetUrl = `<script id="appyWidget" src="https://localhost:8080/widget/build.js?cid=1001-3002"></script>`;
        // let userDetails = JSON.parse(localStorage.getItem('agent'));
        const uid = userDetails.ownerId
        const BotID = userDetails.agentId
        const initlaWidgetUrl = `<script id="appyWidget" src="${dybamicURL}/widget/build.js?cid=${uid}-${BotID}"></script>`;
        const linkUrl = `id="appyWidget" src="${dybamicURL}/widget/build.js?cid=${uid}-${BotID}"`;
        this.setState({ widgestUrl: initlaWidgetUrl, linkUrl: linkUrl })
        publicIp.v4().then((ip4) => {
            this.setState({
                IPv4:ip4
            })
            let chatURL = DOMAIN_NAME + '/' + userDetails.ownerIdentifire ;
            let emailBody = [`email=${userDetails.email.toLowerCase()}&type=widget_setup_page&URL=${linkUrl}&&chatURL=${chatURL}&&req_ip=${ip4}`];
            this.sendEmail(emailBody);
        });
        const ref = firebaseServices.db.collection('users').doc(uid)
        var unsubscribe = ref.onSnapshot(querySnapshot => {
            if (querySnapshot.data().widget_status) {
                this.props.history.push("/widget-success")
            }
        })
    }

    sendEmail = (body) => {
		fetch(mailUrl, {
			method: "post",
			body: body,
			headers: { "Content-Type": "application/x-www-form-urlencoded" }
		  })
			.then()
			.then(response => {
				// console.log(response);
			})
			.catch(e => {
			  console.log(e, "ERROR");
			});
	}

    /**
     * Onchange handler 
     */
    onChangeHandler = (e) => {
        this.setState({ emailUser: e.target.value })
    }

    copytoclipboard() {
        /* Get the text field */
        var copyText = document.getElementById("myInputCopy");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");
        NotificationManager.success("", 'Code copied', 5000);

        /* Alert the copied text */
        // alert("Copied the text: " + copyText.value);
    }

    /**
     * Validate Email
     */
    validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase());
    }

    /**
     * send Email to User 
     */
    sendEmailCode = () => {

        const { emailUser, linkUrl } = this.state;
        // const data = `${linkUrl}`
        // const data = `Hi Bieeet , This is For test a mail functionality.`;
        
        if (!emailUser) {
            NotificationManager.error("", 'Please provide email to send mail', 5000);
            return;
        }
        
        if (!this.validateEmail(emailUser)) {
            NotificationManager.error("", 'Please provide valid email', 5000);
            return
        }

        const developerMessage = 'We are going to use Appy Pie Livechat on our website and I want you to install the widget code, enclosed in this email, on our website.';
        this.setState({ loader: true });
        // var body = [`to=${emailUser.toLowerCase()}&&data=${data}`];
        let ownerEmail = this.agent.ownerEmail ? this.agent.ownerEmail : this.state.ownerEmail;
        var body = [`email=${emailUser.toLowerCase()}&type=widget_setup&URL=${linkUrl}&instruction=${developerMessage}&&ownername=${this.state.company_name}&&ownerEmail=${ownerEmail}&&ownerAgentName=${this.state.ownerAgentName}`];
        // fetch(`${mailUrl}/mailit`, {
        fetch(`${mailUrl}`, {
            method: "post",
            body: body,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
            .then((response) => {
                
                this.setState({ loader: false, emailUser: '', openEmailBox: !this.state.openEmailBox });
                // sucess login Ny Common LoginAPI  
                NotificationManager.success("", 'Sent widget code successfully ', 5000);
            }).catch((e) => {
                console.log(e, "ERROR");
                this.setState({ loader: false });
            });
    }

    /**
     * verify Widget
     */
    varifyWidget = () => {
    
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        const uid = userDetails.ownerId
        // const BotID = userDetails.agentId

        this.setState({ loader: true });
        firebaseServices.db.collection('users').doc(`${uid}`).get()
            .then(userDocumentSnapshot => {
                if (userDocumentSnapshot.exists) {
                    if (userDocumentSnapshot.data().widget_status) {
                        this.setState({ widgetVarify: true });
                        NotificationManager.success("", 'Successfull install Widegt ', 5000);
                        setTimeout(() => {
                            this.props.history.push("/widget-success")
                        }, 5000)
                    }
                }
                this.setState({ loader: false, widgetVarifyProcess: true });

                setTimeout(() => {
                    this.setState({ widgetVarifyProcess: false });
                }, 5000);

            }).catch(err => {
                console.log(err);
                this.setState({ loader: false });
            })
    }

    /**
     * Next Page
     */
    nextStepPage = () => {
        if (this.state.widgetVarify) {
            this.props.history.push("/widget-success")
        } else {
            let userDetails = JSON.parse(localStorage.getItem('agent'));
            window.location.href = '/' + userDetails.organization_alias;
        }
    }

    /**
     *  open popup box
     */
    openEmailBoxScreen = () => {

        this.setState({ openEmailBox: !this.state.openEmailBox })
    }


    render() {
        return (

            <React.Fragment>
                <NotificationContainer />
                <ReactTooltip />

                {this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}
                <div className="login-container loginBG pt-5">
                    <div className="verificationCodeSection">
                        <div className="container">
                            <div className="login-header">
                                <img className="loginHeaderLogo" src={require('./../../assets/images/REO.png')} />
                            </div>
                        </div>
                        {
                            this.state.openEmailBox &&
                            (
                                <div className="popupShadow" style={{ display: 'block' }}>
                                    <div className="popupInstallLivechat popupLivechat" >
                                        {/* <div className="email-help-para pb-5">
                                                    <p className="text-18">Enter email of a team member whose help you'd like to take</p>
                                                </div>
                                                <div className="mail-widget position-relative">
                                                    <input name="useraname" value={this.state.emailUser} placeholder="Enter email address" onChange={(e) => this.onChangeHandler(e)} />
                                                    <button className="btnBlue" onClick={() => this.sendEmailCode()}>Send email</button>
                                                </div> */}


                                        <div className="popupHeader py-4 px-5 k-flex align-items-center">
                                            <h6 className="card-title mb-0 white-text">Send installation instruction</h6>
                                            <span className="close-popup ml-auto" onClick={() => { this.setState({ openEmailBox: !this.state.openEmailBox }) }} >
                                                <svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
                                                    <path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
                                                    <g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
                                                        <rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
                                                        <rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="popupBody pb-5 px-5">
                                            <div className="py-5 feilds-instruction">
                                                <h6 className="card-title mb-0 text-left">Enter developer's email address</h6>
                                                <input name="email" value={this.state.emailUser} placeholder="Enter email address" onChange={(e) => this.onChangeHandler(e)} />
                                            </div>

                                            <div>
                                                <button onClick={() => this.sendEmailCode()} className="btnBlue mr-2 py-2 px-5">Send mail </button>
                                                <button className="btnWhite py-2 px-5" onClick={() => { this.setState({ openEmailBox: !this.state.openEmailBox }) }}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="custmize-your-widget company-information">
                            <div className="container">
                                <div className="verificationCodePara">
                                    <h4>Install your livechat's widget</h4>
                                    <p className="text-mutede">Paste this code in the &lt;body&gt; section of each page of your website where you'd like to display your chat widget.</p>
                                </div>


                                {this.state.widgetVarifyProcess &&
                                    (
                                        this.state.widgetVarify ?

                                            <span style={{ color: 'green' }}>Widget install successfully</span>
                                            :
                                            <span style={{ color: 'red' }} >Widget not install successfully</span>
                                    )
                                }
                                <div className="custmize-heading text-center py-8 custimize-wraper">


                                    <div className="code-widget-copy">
                                        <div className="text-right mb-2">
                                            <span className="cursorPointer pr-3" onClick={() => this.openEmailBoxScreen()} data-tip="Email widget's code to developer" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.22" height="19.948" viewBox="0 0 25.22 19.948"><g id="Icon_feather-mail" data-name="Icon feather-mail" transform="translate(-1.607 -5)"><path id="Path_2362" data-name="Path 2362" d="M5.244,6H23.192a2.25,2.25,0,0,1,2.244,2.244V21.7a2.25,2.25,0,0,1-2.244,2.244H5.244A2.25,2.25,0,0,1,3,21.7V8.244A2.25,2.25,0,0,1,5.244,6Z" fill="none" stroke="#1b85fc" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} /><path id="Path_2363" data-name="Path 2363" d="M25.435,9,14.218,16.852,3,9" transform="translate(0 -0.756)" fill="none" stroke="#1b85fc" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} /></g></svg>
                                            </span>
                                            <span className="cursorPointer" onClick={() => this.copytoclipboard()} data-tip="Copy code" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17.637" height="20.421" viewBox="0 0 17.637 20.421"><path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M7.641,1.5H18.78a1.862,1.862,0,0,1,1.856,1.856v13H18.78v-13H7.641ZM4.856,5.213H15.067a1.862,1.862,0,0,1,1.856,1.856v13a1.862,1.862,0,0,1-1.856,1.856H4.856A1.862,1.862,0,0,1,3,20.065v-13A1.862,1.862,0,0,1,4.856,5.213Zm0,14.852H15.067v-13H4.856Z" transform="translate(-3 -1.5)" fill="#1b85fc" /></svg>
                                            </span>
                                        </div>
                                        <textarea readOnly={true} id="GFG" row="5" cols="80" id="myInputCopy"
                                            name="GFG_text" value={this.state.widgestUrl} >
                                        </textarea>
                                    </div>

                                    {/* <p className="widget-Or position-relative py-8"><span>OR</span></p>
                                    <div className="email-help-para pb-5">
                                        <p className="text-18">Enter email of a team member whose help you'd like to take</p>
                                    </div>
                                    <div className="mail-widget position-relative">
                                        <input name="useraname" value={this.state.emailUser} placeholder="Enter email address" onChange={(e) => this.onChangeHandler(e)} />
                                        <button className="btnBlue" onClick={() => this.sendEmailCode()}>Send email</button>
                                    </div>
                                    <div className="verify-btn py-10 ">  
                                        <button className="btnBlue btn-radius w-200 mr-4" onClick={() => this.varifyWidget()}> Verify </button>
                                        <button className="btnBlue btn-radius w-200" onClick={() => this.nextStepPage()}> Next </button>
                                    </div>  */}

                                    <div className="widthget-setup-emaile-livechat k-flex align-items-center">
                                        <p className="card-body-text" onClick={() => this.openEmailBoxScreen()}>
                                            {/* Email to developer for help */}
                                        </p>

                                        <p className="card-body-text cursorPointer ml-auto" onClick={() => this.nextStepPage()}  >
                                            Go to livechat
                                            <span className="pl-2 position-relative" style={{ top: '-1' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="6.966" height="12.86" viewBox="0 0 6.966 12.86">
                                                    <g id="next_2_" data-name="next (2)" transform="translate(-122.33 1)">
                                                        <g id="Group_12801" data-name="Group 12801" transform="translate(122.33 -1)">
                                                            <path id="Path_2518" data-name="Path 2518" d="M124.139,6.052,118.245.157a.536.536,0,0,0-.758.758L123,6.43l-5.515,5.515a.536.536,0,1,0,.758.758l5.894-5.894A.535.535,0,0,0,124.139,6.052Z" transform="translate(-117.33 0)" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </p>

                                    </div>
                                    <div className="next-btn text-center py-8"><button className="btnBlueNext" onClick={() => this.copytoclipboard()} >Copy code</button></div>

                                    {
                                        this.state.openEmailBox &&
                                        (
                                            // <div className="popupShadow" style={{display:'block'}}>
                                            <div className="popupInstallLivechat popupLivechat" >
                                                {/* <div className="email-help-para pb-5">
                                                    <p className="text-18">Enter email of a team member whose help you'd like to take</p>
                                                </div>
                                                <div className="mail-widget position-relative">
                                                    <input name="useraname" value={this.state.emailUser} placeholder="Enter email address" onChange={(e) => this.onChangeHandler(e)} />
                                                    <button className="btnBlue" onClick={() => this.sendEmailCode()}>Send email</button>
                                                </div> */}


                                                <div className="popupHeader py-4 px-5 k-flex align-items-center">
                                                    <h6 className="card-title mb-0 white-text">Send installation instruction</h6>
                                                    <span className="close-popup ml-auto" onClick={() => { this.setState({ openEmailBox: !this.state.openEmailBox }) }} >
                                                        <svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
                                                            <path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
                                                            <g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
                                                                <rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
                                                                <rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className="popupBody pb-5 px-5">
                                                    <div className="py-5 feilds-instruction">
                                                        <h6 className="card-title mb-0 text-left">Enter developer's email address</h6>
                                                        <input name="email" value={this.state.emailUser} placeholder="Enter email address" onChange={(e) => this.onChangeHandler(e)} />
                                                    </div>

                                                    <div>
                                                        <button onClick={() => this.sendEmailCode()} className="btnBlue mr-2 py-2 px-5">Send mail </button>
                                                        <button className="btnWhite py-2 px-5" onClick={() => { this.setState({ openEmailBox: !this.state.openEmailBox }) }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                            // </div>

                                        )
                                    }


                                    <p className="card-body-text py-5" style={{ position: 'absolute', top: '100%', left: '0', right: '0', textAlign: 'center' }}><strong>Note: </strong>In case you face any issue with installing your Livechat's widget then email us at <a href="mailto:support@appypiechat.com" target="_blank" >support@appypiechat.com</a></p>

                                    <div>
                                    </div>


                                    {/* {this.state.widgetVarifyProcess &&
                                        (
                                            this.state.widgetVarify ?

                                                null
                                                :
                                                (<div>
                                                    <span>Following are the steps to troubleshoot installation of widget </span>
            
                                                    <ul>
                                                        <li>Check if your internet connect is working fine or not.</li>
                                                        <li>Ensure that the URL you entered is some as the one in which you added the code snippet.</li>
                                                        <li>Ensure that the code is entered in the body section.</li>
                                                    </ul> 

                                                    <span>If the widget is still to appearing even after following the above mentioned steps then please email us atsupport@appypiebot.com.</span>

                                                </div>)
                                        )
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default WidgetSetupComponent;





