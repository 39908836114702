/**
 * @description Cretae A varification page with Authentication process 
 * @version 1.0
 * @author Bineet kumar Chaubey<bineet@appypiellp.com>
 * @file CompanyVerificationComponent.js
 */
import React, { Component } from 'react';
import {
	confirmationCode,
	resendConfirmationCode,
	login,
	isUserExistWithEmail
} from "./../../config/SignupLogin";
import {
	createNewUser,
	getOwnerUserDataForLogin,
	updateSessioninCollection,
	checkCompanyAliasAndCreateUnique
} from './Model/DbModel'
import { checkIsUserOrOwnerLogin, cleanupString } from './OnboardHelper';
import { NotificationContainer, NotificationManager } from "react-notifications";
///import {mailUrl} from "../../config/mailFunctions.js";
import { DOMAIN_NAME, GATEWAY_URL, FUNCTION_API_KEY } from '../../config/siteConfig';
const publicIp = require("public-ip");

// import data from "../../localization/login-registration.json";
class CompanyVerificationComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loader: false,
			code: "",
			code1: "",
			code2: "",
			code3: "",
			code4: "",
			code5: "",
			code6: "",
			IPv4: ""
		};
		this.input2 = React.createRef();
		this.input3 = React.createRef();
		this.input4 = React.createRef();
		this.input5 = React.createRef();
		this.input6 = React.createRef();
	}

	/**
	 * check if login or not
	 */
	componentDidMount = async () => {

		this.setState({ loader: true });
		/**
		 * on this component first check user login then redirect inner page
		 *  if user not loggedin and  company name not set on this page then user is comming from direct here then redirect 
		 *  to first step of on-boarding
		 */
		const islogin = await checkIsUserOrOwnerLogin();
		if (islogin) {
			return
		} else {
			const companyName = localStorage.getItem('companyName');
			if (!companyName) {
				window.location = '/company-info'
				return
			}
		}
		this.setState({ loader: false });
		publicIp.v4().then((ip4) => {
			this.setState({
				IPv4: ip4
			})
		});
	}

	/**
	 * @description Event handle verification Code  In registration Porcecss
	 * If Varification Code is Correct  User Login automatci after 3 second With Previous Password And email Store In LocalStorage
	 * @example 
	 */
	handleConfirmation = async (e) => {

		this.setState({
			code6: e.target.value,
		});
		if (e.target.value.length === 6) {
			this.setState({
				code6: e.target.value,
				showWorng: false,
				loader: true,
			});

			try {
				this.state.code6 = e.target.value;
				var email = localStorage.getItem("_email");
				var password = localStorage.getItem("password");
				const { code1, code2, code3, code4, code5, code6 } = this.state;
				var code = code1 + code2 + code3 + code4 + code5 + code6;

				var res = await confirmationCode(email, code);

				res.status = parseInt(res.status);
				if (res.status === 200) {


					if (localStorage.getItem("skipSignUp") === "true") {
						localStorage.setItem('skipSignUp', 'false')
						const companyName = localStorage.getItem('companyName');
						const settingrgbColor = localStorage.getItem('settingrgbColor')
						const settingbgColor = localStorage.getItem('settingbgColor')
						const clientBackgroundColor = localStorage.getItem('clientBackgroundColor')
						const settingChatIcon = localStorage.getItem('settingChatIcon')
						const chatBlackImage = localStorage.getItem('chatBlackImage')
						const whiteImage = localStorage.getItem('whiteImage')
						const fbwtimage = localStorage.getItem('fbwtimage')

						let neworgAlias = '';
						const company_name = companyName
						if (company_name) {
							// const company_namewithoutSPace =  company_name.split(" ").join("").replace(/[^\w\s]/gi, '').toLowerCase();
							const company_namewithoutSPace = cleanupString(company_name)
							const uniqueResponce = await checkCompanyAliasAndCreateUnique(company_namewithoutSPace);
							if (uniqueResponce) {
								let randValu = Math.floor(Math.random() * 10000);
								neworgAlias = company_namewithoutSPace + randValu;
							} else {
								neworgAlias = company_namewithoutSPace
							}
						}

						// initil Data to create User
						const updateData = {
							companyName: companyName,
							organization_alias: neworgAlias,
							settingrgbColor: settingrgbColor,
							settingbgColor: settingbgColor,
							clientBackgroundColor: clientBackgroundColor,
							settingChatIcon: settingChatIcon,
							email: email,
							whiteImage: whiteImage,
							chatBlackImage: chatBlackImage,
							fbwtimage: fbwtimage,
							password: password,
							accessToken: ''
						}
						// two field or mandatory
						//  Email : accessToken
						// UserDocID get User table Doc ID 
						NotificationManager.success("", "OTP Verfied,Redirecting to Login", 5000);
						await createNewUser(updateData, 5)

						this.props.history.push('/login')
					}
					else {
						await this.loginFunctionality(email, password);
					}


				} else {
					this.setState({ loader: false, showWorng: true });
					setTimeout(() => {
						this.setState({ show_warning: true });

						NotificationManager.error("", "Incorrect verification code. Please enter a correct one.", 5000);
						this.setState({
							code1: "",
							code2: "",
							code3: "",
							code4: "",
							code5: "",
							code6: "",
						});
					}, 100);
				}
			} catch (err) {
				this.setState({ loader: false, showWorng: true });
				console.log(err)
			}
		}
	};

	sendEmail = (body) => {
		fetch(GATEWAY_URL + '/sendEmail', {
			method: "post",
			body: body,
			headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
		})
			.then()
			.then(response => {
				console.log(response);
			})
			.catch(e => {
				console.log(e, "ERROR");
			});
	}

	/**
	 * Login Functiinality 
	 */
	loginFunctionality = async (email, password) => {
		localStorage.setItem("logout", "confirm");
		//send email
		var body = [`email=${email.toLowerCase()}&&type=email_verified&&data=${"data"}&&req_ip=${this.state.IPv4}`];
		this.sendEmail(body);
		// if code is verify Then Login User With by Default 
		setTimeout(async () => {
			// Login happen from Common API 
			login(email, password).then(async (loginRes) => {
				var _data = JSON.parse(loginRes.data);
				const accessToken = _data.accessToken.jwtToken
				localStorage.setItem("accessToken", accessToken);
				const IsEmail = await isUserExistWithEmail(email)
				// if true // then Email is register or not 
				if (IsEmail) {
					// TODO:  A login / Set agent Local storage  An redirect chat page
					this.setState({
						email: "",
						password: ""
					});

					let euserId = IsEmail[0].id
					updateSessioninCollection(accessToken, euserId);

					// TODO : update Tocken In  Redirect to Next Page
					const getloginData = await getOwnerUserDataForLogin(euserId, email);
					delete getloginData.password
					// valid user
					getloginData.agent_name = getloginData.name;
					localStorage.setItem('agent', JSON.stringify(getloginData));
					// this.props.history.push("/");
					window.location = '/'
					this.setState({ loader: false });
					return;
					// this.props.history.push("/agent-setup");

				} else {

					//var _data = JSON.parse(loginRes.data);
					const accessToken = _data.accessToken.jwtToken
					localStorage.setItem("accessToken", accessToken);
					// save Two Step data in this page 
					const companyName = localStorage.getItem('companyName');
					const settingrgbColor = localStorage.getItem('settingrgbColor')
					const settingbgColor = localStorage.getItem('settingbgColor')
					const clientBackgroundColor = localStorage.getItem('clientBackgroundColor')
					const settingChatIcon = localStorage.getItem('settingChatIcon')
					const chatBlackImage = localStorage.getItem('chatBlackImage')
					const whiteImage = localStorage.getItem('whiteImage')
					const fbwtimage = localStorage.getItem('fbwtimage')

					let neworgAlias = '';
					const company_name = companyName
					if (company_name) {
						// const company_namewithoutSPace =  company_name.split(" ").join("").replace(/[^\w\s]/gi, '').toLowerCase();
						const company_namewithoutSPace = cleanupString(company_name)
						const uniqueResponce = await checkCompanyAliasAndCreateUnique(company_namewithoutSPace);
						if (uniqueResponce) {
							let randValu = Math.floor(Math.random() * 10000);
							neworgAlias = company_namewithoutSPace + randValu;
						} else {
							neworgAlias = company_namewithoutSPace
						}
					}

					// initil Data to create User
					const updateData = {
						companyName: companyName,
						organization_alias: neworgAlias,
						settingrgbColor: settingrgbColor,
						settingbgColor: settingbgColor,
						clientBackgroundColor: clientBackgroundColor,
						settingChatIcon: settingChatIcon,
						email: email,
						whiteImage: whiteImage,
						chatBlackImage: chatBlackImage,
						fbwtimage: fbwtimage,
						password: password,
						accessToken: accessToken,
						sendButtonClr: settingbgColor
					}
					// two field or mandatory
					//  Email : accessToken
					// UserDocID get User table Doc ID 

					const UserDocID = await createNewUser(updateData, 6)

					if (UserDocID) {
						// TODO: login User here In system
						// Add User DataENtry Through ThisAPi 
						localStorage.removeItem("password");
						// localStorage.removeItem("OwnerName");
						localStorage.removeItem('settingrgbColor')
						localStorage.removeItem('settingbgColor')
						localStorage.removeItem('clientBackgroundColor')
						localStorage.removeItem('settingChatIcon')
						localStorage.removeItem('chatBlackImage')
						localStorage.removeItem('whiteImage')
						localStorage.removeItem('fbwtimage')
						localStorage.removeItem("_email");
						localStorage.removeItem("companyName");
						this.setState({
							email: "",
							password: ""
						});

						updateSessioninCollection(accessToken, UserDocID);
						const getloginData = await getOwnerUserDataForLogin(UserDocID, email);

						delete getloginData.password
						// valid user
						let chatURL = DOMAIN_NAME + '/' + neworgAlias;
						var body = [`email=${email.toLowerCase()}&&type=welcome_email&&data=${"data"}&&req_ip=${this.state.IPv4}&&chatURL=${chatURL}`];
						this.sendEmail(body);
						getloginData.agent_name = getloginData.name;
						localStorage.setItem('agent', JSON.stringify(getloginData));
						this.props.history.push("/agent-setup");
						this.setState({ loader: false });

					} else {
						// this.props.history.push("/company-login");// TODO: redirect 30  
					}
				}
			}).catch(err => {
				console.log(err);

			})

		}, 600);
	}

	/**
	 *  handle Event When click Reset button  For varifocation code 
	 * 
	 */
	handleResendConfirmation = async () => {
		this.setState({ loader: true });
		var _email = localStorage.getItem("_email")
		var res_resend = await resendConfirmationCode(_email);
		var password = localStorage.getItem("password");



		if (res_resend.status === 200) {
			this.setState({ loader: false });
			NotificationManager.success("", "Verification code has been sent to your email address.", 5000);
		} else if (res_resend.status === 401) {

			await this.loginFunctionality(_email, password);
			this.setState({ loader: false });
		} else {

			this.setState({ loader: false });
		}
	};

	render() {
		return (
			<div className="login-container loginBG">
				<NotificationContainer />
				{this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}
				<div className="verificationCodeSection">
					<div className="container">
						<div className="login-header">
							<img
								className="loginHeaderLogo"
								src={require("../../assets/images/REO.png")}
								alt="logo"
							/>
						</div>
					</div>
					<div className="verificationCode">
						<div className="verificationCodePara verificationCodePara22">
							<h4>Congratulations! Your account has been created</h4>
							<p>
								Enter your verification code to complete verification process
							</p>
						</div>
						{this.state.show_warning ? (
							<div className="Loginwarning" style={{ textAlign: "center" }}>
								<img alt="" src={require("../../assets/images/danger.png")} />
								<span style={{ color: "#000" }}>
									Incorrect verification code. Please enter a correct one.
								</span>
							</div>
						) : null}
						<div className="verifictionCodeFeilds">
							<div className="split_input_item input_wrapper">
								<div className="feildsInputs" style={{ display: "block" }}>
									<input
										// ref={input => (this.input6 = input)}
										type="text"
										maxLength="6"
										value={this.state.code6}
										onChange={this.handleConfirmation}
										style={{
											letterSpacing: "58px",
											padding: "0px 0px 0px 42px",
										}}
									/>
								</div>
							</div>
						</div>
						<div className="verifictionDownEnter">
							<p>
								If you had missed writing in your correct email last time,
								please
								<span className="clickHereVerfCode" onClick={() => this.props.history.push("/company-registration")} > Click here</span>.
							</p>
							<h5 onClick={this.handleResendConfirmation}>
								Resend verification code
							</h5>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CompanyVerificationComponent;