const dev = {
    "domain": window.location.origin,
    "mailFunctionUrl": "https://us-central1-reacttest-29b23.cloudfunctions.net",
    //"mailFunctionUrl" : "https://us-central1-livechat-commonlogin.cloudfunctions.net",
    "apiOnStatus": true,
    "common_api_url": "https://commonlogin.pbodev.info/backend/",
    "common_login_url": "https://commonlogin.pbodev.info",
    "common_login_facebook_login": 'https://appypieuser.auth.us-west-2.amazoncognito.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://commonlogin.pbodev.info/popfacebooklogin&response_type=code&client_id=7ndk9u2pbvd6221qupt6oagvue&scope=aws.cognito.signin.user.admin email openid phone profile',
    "common_login_google_login": 'https://appypieuser.auth.us-west-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://commonlogin.pbodev.info/popgooglelogin&response_type=code&client_id=7ndk9u2pbvd6221qupt6oagvue&scope=aws.cognito.signin.user.admin email openid',
    "closeInactiveChatsFrom": "all", /// login, logout, all
    "PAYMENT_CHECKOUT_URL": "https://checkout-dev.pbodev.info",
    "ipStackUrl": "https://api.ipstack.com",
    "ipstackkey": "7ff1294fa65db1699c226804e9005de3",
    "openAiKey": "sk-HuFHr9K7rllGFkrXm6Z76SSZhKaNLC1WM6SD0VMZ",
    "chatboturl": "https://stagingchatbot.appypie.com",
    "chatbotapikeyurl": "https://us-central1-appycx-beta.cloudfunctions.net/botlist/getapikey",
    "chatbotapilisturl": "https://us-central1-appycx-beta.cloudfunctions.net/botlist/list",
    "realmapiid": "accounts-dev-ctclr",
    "realmapikey": "Ve9dvUzJyMvbT1rkjmTRalt2No5wQhge5J7y0ZQWD6K7dHn9lqFkCgDE9y49X7if",
    "fb_app_id": 518121621664409, // Live : 518121621664409
    "fb_api_version" : "14.0",   // 10.0
    "desk_api_url": "https://us-central1-knowledgebase-production.cloudfunctions.net",
     };

const prod = {
   "domain" : window.location.origin,
   "mailFunctionUrl" : "https://us-central1-livechat-production.cloudfunctions.net",
   "apiOnStatus" : true,
   "common_api_url": "https://backendaccounts.appypie.com/",
   "common_login_url": "https://accounts.appypie.com",
   "common_login_facebook_login": 'https://auth.appypie.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://accounts.appypie.com/popfacebooklogin&response_type=code&client_id=qj3730vso717ssqt9nci91oni&scope=aws.cognito.signin.user.admin email openid phone profile',
   "common_login_google_login": 
   // 'https://auth.appypie.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://accounts.appypie.com/popgooglelogin&response_type=code&client_id=qj3730vso717ssqt9nci91oni&scope=aws.cognito.signin.user.admin email openid',

   "https://authv1.appypie.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://accounts.appypie.com/popgooglelogin&response_type=code&client_id=3bbb9s389cfc9kdokme6laebmr&scope=aws.cognito.signin.user.admin email openid",
   "closeInactiveChatsFrom": "all", /// login, logout, all
   "PAYMENT_CHECKOUT_URL": "https://checkout.appypie.com",
   "ipStackUrl": "https://api.ipstack.com",
   "ipstackkey": "84343356435162ee7f035965a3c4c2fd",
   "openAiKey": "sk-HuFHr9K7rllGFkrXm6Z76SSZhKaNLC1WM6SD0VMZ",
   "chatboturl": "https://chatbot.appypie.com/cx/Chatbot/agent",  // "https://chatbot.appypie.com"
   "chatbotapikeyurl": "https://chatbottest.appypie.com/getapikey",
   "chatbotapilisturl": "https://chatbottest.appypie.com/botlist",
   "realmapiid": "accounts-live-sdpwm",
    "realmapikey": "A1bPAGRUcGsVgupH09R3FLIblKXww60vCKGKnpJVWdCLmdMSzs12ElWCheXWG5XU",
    "fb_app_id": 518121621664409,
    "fb_api_version" : "14.0",
    "desk_api_url": "https://us-central1-knowledgebase-a8b2b.cloudfunctions.net",
};

var domainname;
var mailFunctionUrl;
var apiOnStatus;
var googleTranslationKey;
var commonapi;
var commonloginurl;
var commonloginfacebook;
var commonlogingoogle;
var closeInactiveChats;
var paymentCheckoutUrl;

var ipStackUrl;
var ipstackkey;
var widgetDomain;
var whatsappNumber;

var openAiKey;
var chatboturl;
var chatbotapikeyurl;
var chatbotapilisturl;

var realmapiid;
var realmapikey;
var fb_app_id;
var TEST_TOKEN;
var fb_api_version;

var hideTryNowCountries;
var paymentTrialPeriod;
var functionAPIKey;
var apiGatewayUrl;
var zoomClientId;
var accountId;
var accountBotId;
var switchToCloudFunction;
var deskApiUrl;
var storageBucket;
var froalaeditorkey;

if (process.env.REACT_APP_STAGE === "dev") {
    domainname = dev.domain;
    mailFunctionUrl = dev.mailFunctionUrl;
    apiOnStatus = dev.apiOnStatus;
    commonapi = dev.common_api_url;
    commonloginurl = dev.common_login_url;
    commonloginfacebook = dev.common_login_facebook_login;
    commonlogingoogle = dev.common_login_google_login;
    googleTranslationKey = "AIzaSyBI7FFzhtbYmvBm5GqEq5hYy1kWkA16RLI";
    closeInactiveChats = dev.closeInactiveChatsFrom;
    paymentCheckoutUrl = dev.PAYMENT_CHECKOUT_URL;
    ipstackkey = dev.ipstackkey;
    ipStackUrl = dev.ipStackUrl;
    openAiKey = dev.openAiKey;
    widgetDomain = "https://chat-staging.pbodev.info";
    whatsappNumber = "+14348383375";
    chatboturl = prod.chatboturl;
    chatbotapikeyurl = prod.chatbotapikeyurl;
    chatbotapilisturl = prod.chatbotapilisturl;
    realmapiid = dev.realmapiid;
    realmapikey = dev.realmapikey;
    fb_app_id = dev.fb_app_id;
    TEST_TOKEN = "anuj5555123asdasd123";
    fb_api_version = dev.fb_api_version;
    hideTryNowCountries = ['IN', 'CO', 'MX', 'BR', 'AR'];
    paymentTrialPeriod = 7;
    apiGatewayUrl = "https://livechat-dev-gateway-ddg4oj32.uc.gateway.dev";
    functionAPIKey = "AIzaSyDcNWCwsyIwUozD51utgj87yEQk_zsRNZ8";
    zoomClientId = 'yIFU36eOR5OKFGOJRlHhLQ';
    accountId = "162124136111054796";
    accountBotId = "162124136433553731";
    switchToCloudFunction = false;
    deskApiUrl = dev.desk_api_url;
    storageBucket = "reacttest-29b23.appspot.com";
    froalaeditorkey= "7MD3aA2C2C6C4A3C3F3yQNDMIJd1IQNSEa2EUAf1XVFQa1EaD4D3B2D2A18A14B3C9C3A2=="
}
if (process.env.REACT_APP_STAGE === "prod") {
    domainname = prod.domain;
    mailFunctionUrl = prod.mailFunctionUrl;
    apiOnStatus = prod.apiOnStatus;
    googleTranslationKey = "AIzaSyDGfEVYHhIJTzaRhCbmIrpnT--7CANuQ4k";
    commonapi = prod.common_api_url;
    commonloginurl = prod.common_login_url;
    commonloginfacebook = prod.common_login_facebook_login;
    commonlogingoogle = prod.common_login_google_login;
    closeInactiveChats = prod.closeInactiveChatsFrom;
    paymentCheckoutUrl = prod.PAYMENT_CHECKOUT_URL
    ipstackkey = prod.ipstackkey;
    ipStackUrl = prod.ipStackUrl;
    widgetDomain = 'https://chatwidget.software';
    openAiKey = prod.openAiKey;
    whatsappNumber = "+12075174085";
    chatboturl = prod.chatboturl;
    chatbotapikeyurl = prod.chatbotapikeyurl;
    chatbotapilisturl = prod.chatbotapilisturl;
    realmapiid = prod.realmapiid;
    realmapikey = prod.realmapikey;
    fb_app_id = prod.fb_app_id;
    TEST_TOKEN = "s:a9f5ab2f-f3a4-4a33-bcbe-c8ef27b419e6.xJ+3VKaZJEU2f9HxukiTeYmCBLZu9kezA8rEsZCyl5U";
    fb_api_version = prod.fb_api_version;
    hideTryNowCountries = ['IN', 'CO', 'MX', 'BR', 'AR'];
    paymentTrialPeriod = 7;
    apiGatewayUrl = "https://livechat-gateway-cavg9c0h.uc.gateway.dev";
    functionAPIKey = googleTranslationKey;
    zoomClientId = 'yIFU36eOR5OKFGOJRlHhLQ';
    accountId = "159523784363947423";
    accountBotId = "159523784481563446";
    switchToCloudFunction = false;
    deskApiUrl = prod.desk_api_url;
    storageBucket = "livechat-production.appspot.com";
    froalaeditorkey="7MD3aA2C2C6C4A3C3F3yQNDMIJd1IQNSEa2EUAf1XVFQa1EaD4D3B2D2A18A14B3C9C3A2=="
}

export const commonLoginApi = commonapi;
export const commonLoginFacebook = commonloginfacebook;
export const commonLoginGoogle = commonlogingoogle;
export const COMMON_LOGIN_URL = commonloginurl;
export const currencyData = { "GBP": "£", "MXN": "Mex$", "USD": "$", "AED": "د.إ", "HKD": "HK$", "ARS": "$", "SAR": "ر.س", "ZAR": "R", "NZD": "NZ$", "AUD": "AU $", "CAD": "C$", "INR_old": "<span class='appyicon-indian-rupee'></span>", "RUB": "\u20BD", "JPY": "¥", "EUR": "\u20AC", "MYR": "RM", "QAR": "ر.ق", "BRL": "R$", "COP": "$", "INR": "\u20B9", "KRW": "₩", "KES": "KSh", "IDR": "Rp"};
////export const mailUrl = mailFunctionUrl + '/mailFunctions/sendEmail';
export const mailUrl = apiGatewayUrl + '/sendEmail';
export const ALGOLIA_APP_ID = process.env.REACT_APP_STAGE === 'prod' ? "I4JTU83WUT" : "I4JTU83WUT";
export const ALGOLIA_KEY = process.env.REACT_APP_STAGE === 'prod' ? "21a5aa8a6c98e6840fbc4724e02301a4" : "21a5aa8a6c98e6840fbc4724e02301a4"; //dce399f8c01e707142244a0375aac947
export const DOMAIN_NAME = domainname;
export const MAIL_FUNCTION_URL = mailFunctionUrl;
export const API_ON_STATUS = apiOnStatus;
export const NO_REPLY_EMAIL = "noreply@appypie.com";
export const ENTER_EMAIL = "Enter email";
//  export const INCOMING_AUDIO = "https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/widget_static%2F3.mp3?alt=media&token=8b176af6-b499-4f80-9f2b-57030ba80c89";
export const INCOMING_AUDIO = "https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/widget_static%2F4.mp3?alt=media&token=7caa6643-fde4-4de9-aa4a-550e797f24af&_gl=1*1tehbw4*_ga*OTM2MzMxNDYzLjE2ODI2NjI2MTQ.*_ga_CW55HF8NVT*MTY4NjIwOTAzOC41MS4xLjE2ODYyMDk3MzMuMC4wLjA.";
export const POP_AUDIO = "https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/widget_static%2FPop.mp3?alt=media&token=9d777ef4-5629-48ce-a21f-9aa82aa1fdd9";
export const ICON_BELL = "https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/widget_static%2FNotifications_button_24.png?alt=media&token=3796b2d3-77d9-4e89-ad30-63636d32853b";
export const GOOGLE_TRANSLATION_KEY = googleTranslationKey; //"AIzaSyDEk73YzsV5JC9UXQ7-2iMdByHHYhP6BkA";
export const CLOSE_INACTIVE_CHATS_FROM = closeInactiveChats;
export const DEFAULT_COMMONLOGIN_BLANK_KEYWORD = 'NA';
export const PAYMENT_CHECKOUT_URL = paymentCheckoutUrl;
export const IP_STACK_KEY = ipstackkey;
export const IP_STACK_URL = ipStackUrl;
export const WIDGET_DOMAIN = widgetDomain;
export const OPEN_AI_KEY = openAiKey;
export const WHATS_APP_NUMBER = whatsappNumber;
export const TEST_LOGIN_TOKEN = TEST_TOKEN;
export const CHATBOTURL = chatboturl;
export const CHATBOT_API_KEY_URL = chatbotapikeyurl;
export const CHATBOT_API_LIST_URL = chatbotapilisturl;
export const REACT_APP_REALM_API_ID = realmapiid;
export const REACT_APP_REALM_API_KEY = realmapikey;
export const FB_APP_ID = fb_app_id;
export const API_VERSION = fb_api_version;
export const HIDE_TRY_NOW_COUNTRIES = hideTryNowCountries;
export const PAYMENT_TRIAL_PERIOD = paymentTrialPeriod;
export const FUNCTION_API_KEY = functionAPIKey;
export const GATEWAY_URL = apiGatewayUrl;
export const ZOOM_CLIENT_ID = zoomClientId;
export const APP_FROM_CONNECT = ["k3pplzjk0c7s11f3ce9la56o", "janrt1tf08q0aa8ded8o4z48", "kxain88s0ku4718999fy77so", "jvgl0d4fff345b00ypzx"]; // add janrt1tf08q0aa8ded8o4z48 for facebook
export const CONNECT_DEVELOPER_URL = "https://developer.appypie.com/api/v1/integrations";
export const COOKIES_LIST = { "APPYID" : "s%3A8e16f15d-fc1c-43f9-9202-274fee10c169.BeGYQbGAFvJGzOTjOT%2F5UU675eOSDIlkw9w2lMWBnP4", "common_eml" : "646861726d65736840617070797069656c6c702e636f6d", "79fefaf2564aabb19d44fe324844c86b" : 1 }
export const ACCOUNT_ID = accountId;
export const ACCOUNT_BOT_ID = accountBotId;
export const AVAILALE_LANGUAGES_FOR_LOCALIZATION = ["en", "pt"];
export const CLEARBIT_KEY = 'sk_2956891fa6c9c4cec8eee5ca30faec53:xyz';
export const AMPLITUDE_KEY = '1e1cb57bf9ffae60ae33bbb0bba8d84f';
export const SWITCH_TO_CLOUD_FUNCTION = switchToCloudFunction;
export const DESK_API_URL = deskApiUrl;
export const STORAGE_BUCKET = storageBucket;
export const FROALAEDITORKEY=froalaeditorkey
