import React from 'react';
import { withRouter } from "react-router-dom";
import {getSessionCheck}  from "../config/SignupLogin";
import { getCookieVal } from '../services/service';

class Sessioncheck extends React.Component {
    constructor() {
        super();
       
    }

    componentDidUpdate() {
      
    }

    
    async componentDidMount() {
		try {
                setTimeout(() => {
                    this.callGetSession();
                }, 1000);

                if(window.location.hostname === "localhost" || window.location.origin==="https://reosupport.net") {
                    fetch("https://backendaccounts.appypie.com/getcooky?email=donaldbalganion@gmail.com")
                    .then(res => res.json())
                    .then(async response => {
                        // console.log(response)
                        if(response.status === 200){
                        // if(response.status === 200){
                        //     setCookies(response.data)
                        // }
                        var expiry = 'Thu, 01 Jan 2070 00:00:01 GMT';
                        document.cookie = "APPYID=" + response.cookie + "; expires=" + expiry + "; secure; SameSite=Lax";

                        }
                    })
                }

                const setInterValTime = window.location.hostname === "localhost" ? 10000 : 10000;
				setInterval(async () => {
					this.callGetSession();
				}, setInterValTime);
			  } catch(e) {
				console.log(e);
		  }
    }

    callGetSession = async () => {
        var loginStatus = 0;
        var loginType = "";
        if(window.location.hostname === "localhost") {
            loginStatus = 1
            loginType = "both";
        } else {
            var agentLoginStatus = await getCookieVal("61be0d8c3c195df8308def9fac389355");
            if(agentLoginStatus === "1" || agentLoginStatus === 1) {
                loginStatus = 1;
                loginType = "agent";
            } else {
                loginStatus = await getCookieVal('79fefaf2564aabb19d44fe324844c86b');
                loginType = "owner";
            }
        } 
        if(loginStatus === "1" || loginStatus === 1) {
            await getSessionCheck(true, loginType);
        }
    }
    render() {
        return (
            <div></div>
        );
    }

}

export default withRouter(Sessioncheck);
