import React, { PureComponent } from 'react';
import ChatContext from '../../components/ChatContext';
import Incomingnotification from './Incomingnotification';
// import db from "../../../../config/firebase";

import { ENTER_EMAIL, CLEARBIT_KEY } from '../../../../config/siteConfig';
import { getTimeFromDate, validateEmail, nameIntials } from '../../components/Comman';
import { setCurrentChat } from '../../components/Twilio';
import ListComponent from './list';
import { data } from './object';
import firebaseServices from '../../../../firebase';
const clearbit = require('clearbit')(CLEARBIT_KEY);
let itemStatusMap = {}
const NEW_VISITOR_STATUS = "incoming";

class Incoming extends PureComponent {
    static contextType = ChatContext;  // this.context will work when it defined as is

    incomingSnapshot = null;
    _isMounted = false;
    // To use global data pass second parameter context in constructor
    constructor(props, context) {
        super(props);
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.allIntervels = [];
        this.checkForMessages = [];
        this.visitorData = [];
        this.hasLoadedlazy = false;
        this.closeObj = false;
        this.state = {
            visitors: [],
            filteredVisitors: [],
            displayVisitors: [],
            currentDate: new Date().setHours(0, 0, 0, 0),
            isServerTimeGet: false,
            currentDate_server: '',
            settings: {},
            hasMore: true,
            pagesize: 10000,
            totalPage: 0,
            total: 0,
            currentPage: 0,
            open: true,
            checkReload: false,
            isDisablePanel: false,
            notificationCount: 0,
            messageCount: 0,
            moreItemsLoading: false,
            hasNextPage: true,
            loading: 1,
            loaded: 2,
        };
        this.getSettings();
        this.loadMore = this.loadMore.bind(this);
    }

    /** Adhoc method: For remove unwanted visitor */


    ////////// Private function start //////////

    getSettings = () => {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('settings').doc("IDLETIME").get().then(result => {
            if (result.exists) {
                var setting = result.data();
                setting.id = result.id;
                setting.trigger_message_time = parseInt(setting.trigger_message_time);
                setting.trigger_message_repeat = parseInt(setting.trigger_message_repeat);
                setting.trigger_message_interval = parseInt(setting.trigger_message_interval);
                this.setState({ settings: setting });
            }
        });
    }


    ////////// Private function End //////////

    /* Technique 1 Start */

    componentWillMount = () => {
        /// Increase limit 30 to 300 due to Speed issue on 20 Sep 2021
        this.incomingSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("visitor_status", "==", NEW_VISITOR_STATUS).orderBy('updatedon', 'desc').limit(30).onSnapshot((visitors) => {
            visitors.docChanges().forEach((visitor) => {
                var vistSnap = visitor.doc.data();
                vistSnap.id = visitor.doc.id;
                const visitor_Id = visitor.doc.id;

                /* Check team start */
                if (this.agent.departments.length > 0 && vistSnap.hasOwnProperty('team_id') && vistSnap.team_id && this.agent.departments.indexOf(vistSnap.team_id) === -1) {
                    return false;
                }
                /* Check team end */

                //console.log("Incoming Type: "+ visitor.type + ", ID : " + visitor_Id + ", visitor_status : " + vistSnap.visitor_status+ ", incoming : " + vistSnap.incoming+ ", typeof incoming : " + typeof vistSnap.incoming);

                if (this.context.chatPickStatusTransaction.key === visitor_Id) {
                    if (vistSnap.agent_end === "VISITOR_END_CHAT") {
                        this.closeObj = { key: visitor_Id, status: "close", Text: "Close window", msgToShow: vistSnap.message_to_show };
                    }
                }

                //const client_company_name2 = vistSnap.client_company_name ? vistSnap.client_company_name : "";
                const client_company_name2 = (vistSnap.client_company_name && (vistSnap.client_name === "" || vistSnap.client_name.indexOf("#Visitor") !== -1)) ? vistSnap.client_company_name : "";
                if (client_company_name2) {
                    vistSnap.client_short = nameIntials(client_company_name2);
                } else {
                    vistSnap.client_short = nameIntials(vistSnap.client_name);
                }

                if (vistSnap.geoDetails.country_name === "Country not detected") {
                    vistSnap.geoDetails.country_name = "N/A";
                }

                if (vistSnap.geoDetails.country_ip === "Not detected") {
                    vistSnap.geoDetails.country_ip = "N/A";
                }

                if (!vistSnap.country) {
                    vistSnap.country = "Country not detected";
                }

                if (!vistSnap.operating) {
                    vistSnap.operating = "OS Default";
                }

                if (!vistSnap.browser) {
                    vistSnap.browser = "Browser Default";
                }

                var client_short_name = vistSnap.client_name;
                if (client_short_name.length > 20) {
                    client_short_name = client_short_name.substring(0, 20) + '...';
                }
                vistSnap.client_short_name = client_short_name;
                vistSnap.timevalue = getTimeFromDate(vistSnap.updatedon);
                if (this.context.closeVisitorId !== "" && this.context.closeVisitorId === visitor_Id) {
                    return false;
                } else if (visitor.type === "added") {
                    if (vistSnap.incoming === 1) {
                        const index = this.visitorData.findIndex(x => x.id === visitor_Id);
                        if (index === -1) {
                            this.visitorData.splice(0, 0, vistSnap); // Insert the new one 
                        } else {
                            vistSnap.counter = eval(index) + 1;
                            this.visitorData.splice(index, 1, vistSnap); // replace value of an index
                        }
                    } else if (vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.visitor_status === "close" || vistSnap.visitor_status === "ongoing") {
                        const index = this.visitorData.findIndex(x => x.id === visitor_Id);
                        if (index !== -1) {
                            this.visitorData.splice(index, 1);
                        }
                    }
                } else if (visitor.type === "modified") {
                    if (vistSnap.incoming === 1) {
                        /// Update current visitor data //////
                        if (Object.keys(this.context.currentVisitor).length > 0) {
                            if (this.context.currentVisitor.id === visitor_Id && this.context.currentVisitor.agent_read_count !== vistSnap.agent_read_count) {
                                if (!validateEmail(vistSnap.client_email)) {
                                    vistSnap.client_email = ENTER_EMAIL;
                                }
                                if (!validateEmail(vistSnap.visitor_email)) {
                                    vistSnap.visitor_email = ENTER_EMAIL;
                                }
                                this.context.updateGlobalData("currentVisitor", vistSnap);
                            }
                        }
                        /// Update current visitor data //////
                        const index = this.visitorData.findIndex(x => x.id === visitor_Id);
                        if (index !== -1) {
                            this.visitorData.splice(index, 1, vistSnap); // replace value of an index
                        } else {
                            this.visitorData.splice(0, 0, vistSnap); // Insert the new one 
                        }
                    } else if (vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.visitor_status === "close" || vistSnap.visitor_status === "ongoing") {
                        const index = this.visitorData.findIndex(x => x.id === visitor_Id);
                        if (index !== -1) {
                            this.visitorData.splice(index, 1);
                        }
                    }
                } else if (visitor.type === "removed") {
                    if (vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.visitor_status === "close" || vistSnap.visitor_status === "ongoing") {
                        const index = this.visitorData.findIndex(x => x.id === visitor_Id);
                        if (index !== -1) {
                            this.visitorData.splice(index, 1);
                        }
                    } else {
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(visitor_Id).get().then((doc) => {
                            if (doc.exists) {
                                var _data = doc.data();
                                if (_data.visitor_status === "close" || _data.visitor_status === "ongoing" || _data.incoming === 2 || _data.incoming === 3) {
                                    const index = this.visitorData.findIndex(x => x.id === visitor_Id);
                                    if (index !== -1) {
                                        this.visitorData.splice(index, 1);
                                        this.setState({ visitors: this.visitorData }, () => {
                                            this.searchClient();
                                        });
                                    }
                                }
                            }
                        });
                    }
                }
            });

            if (this.closeObj && this.context.liveChatDocId !== "" && this.closeObj.key === this.context.liveChatDocId && this.visitorData.length > 0) {
                this.context.updateGlobalData("chatPickStatusTransaction", this.closeObj);
            }


            this.setState({ visitors: this.visitorData }, () => {
                this.searchClient();
            });
        }, (error) => {
            try {
                this.reloadClients();
                var addedon = Date.now();
                var docId = 'SLOG' + addedon;
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('snapshotLogs').doc(docId).set({
                    addedon: addedon,
                    agent_id: this.agent.agentId,
                    error_from: "incoming",
                    error: error,
                });
            } catch (err) { }
        });
        /* }); */
    }

    /* Technique 1 End */

    componentDidUpdate = (prevProps, prevState, snapshot) => {
if(this.context.liveChatDocId.length>2){
    let kuchbhi=document.getElementById(this.context.liveChatDocId);
    if(kuchbhi){kuchbhi.classList.add("selected");}
    
}
        /* For Technique 1 Start */
        if (this.context.closeVisitorId !== "") {
            const index = this.visitorData.findIndex(x => x.id === this.context.closeVisitorId);
            if (index !== -1) {
                this.visitorData.splice(index, 1);
                this.setState({ visitors: this.visitorData }, () => {
                    this.searchClient();
                });
            }
        }
        /* For Technique 1 End */

        /* For Lazy Loader Start */

        if (this.hasLoadedlazy === false && (this.state.filteredVisitors).length > 0) {
            this.hasLoadedlazy = true;
            let curpage = this.state.currentPage;
            // let visitors = (this.state.filteredVisitors).slice(curpage * this.state.pagesize, (curpage + 1) * this.state.pagesize);
            // this.setState({ displayVisitors: visitors, totalPage: Math.ceil((this.state.filteredVisitors).length / this.state.pagesize) });
        }
        /* For Lazy Loader End */

        if (prevProps.srchTextVisitor !== this.props.srchTextVisitor) {
            this.searchClient();
        }

        if (this.context.goneOffline !== this.state.checkReload) {
            if (!this.context.goneOffline && this.state.checkReload) {
                this.reloadClients();
            }
            this.state.checkReload = this.context.goneOffline;
        }

        let incomingIds = [];
        if (this.state.displayVisitors.length > 0) {
            this.state.displayVisitors.forEach((vis) => {
                incomingIds.push(vis.client_id);
            })
        }

        let checkArrayEqual = this.arraysEqual(this.context.incomingChatIds, incomingIds);
        // console.log('checkArrayEqual >>> ', checkArrayEqual);
        if (checkArrayEqual === false) {
            this.context.updateGlobalData('incomingChatIds', incomingIds);
        }
    }

    arraysEqual = (a, b) => {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    /////// Reload Ongoing Visitors After Internet reconnect ///////////

    reloadClients = () => {
        let visitorData = [];
        let clearChatWindow = true;
        let currentVisitor = {};
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("visitor_status", "==", NEW_VISITOR_STATUS).orderBy('updatedon', 'desc').get().then((visitors) => {
            visitors.forEach((visitor) => {
                var vist = visitor.data();
                if (vist.incoming === 1) {
                    vist.id = visitor.id;
                    const client_company_name = vist.client_company_name ? vist.client_company_name : "";
                    if (client_company_name) {
                        vist.client_short = nameIntials(client_company_name);
                    } else {
                        vist.client_short = nameIntials(vist.client_name);
                    }

                    vist.client_short = nameIntials(vist.client_name);
                    var client_short_name = vist.client_name;
                    if (client_short_name.length > 20) {
                        client_short_name = client_short_name.substring(0, 20) + '...';
                    }
                    vist.client_short_name = client_short_name;
                    vist.timevalue = getTimeFromDate(vist.updatedon);

                    if (vist.id === this.context.liveChatDocId) {
                        clearChatWindow = false;
                        currentVisitor = vist;
                    }

                    visitorData.push(vist);
                }
            });

            this.setState({ visitors: this.visitorData }, () => {
                if (!clearChatWindow) {
                    this.centerClientLoading(currentVisitor, true);
                }
                this.searchClient();
            });
        });
    }

    searchClient = () => {
        var searchuser = this.props.srchTextVisitor;
        var textToSearch = searchuser.toLowerCase();
        var filterVisitors = this.state.visitors.filter(data => {
            if (new RegExp(textToSearch, "gi").test(data["client_name"])) {
                return true;
            }
            return false;
        });

        filterVisitors.sort(function (a, b) { return b.updatedon - a.updatedon; });
        this.setState({ filteredVisitors: filterVisitors }, () => {
            this.loadMoreVisitors(false);
        })
    }

    loadMoreVisitors = (fromScroll) => {
        if (fromScroll) {
            if (this.state.currentPage + 1 < this.state.totalPage) {
                let curpage = this.state.currentPage < this.state.totalPage ? this.state.currentPage + 1 : this.state.currentPage;
                let visitors = this.state.filteredVisitors.slice(0, (curpage + 1) * this.state.pagesize);
                this.setState({ displayVisitors: visitors, currentPage: curpage });
            } else {
                if (this.state.currentPage + 1 === this.state.totalPage && this.state.hasMore === true) {
                    this.setState({ hasMore: false });
                }
            }
        } else {
            var totalPage = Math.ceil((this.state.filteredVisitors).length / this.state.pagesize);
            if (totalPage === 0) {
                this.setState({ displayVisitors: this.state.filteredVisitors, hasMore: false });
            } else if (this.state.currentPage + 1 < totalPage) {
                let curpage = this.state.currentPage < totalPage ? this.state.currentPage + 1 : this.state.currentPage;
                let visitors = this.state.filteredVisitors.slice(0, (curpage + 1) * this.state.pagesize);
                this.setState({ displayVisitors: visitors, totalPage: totalPage }, () => {
                    setTimeout(() => {
                        console.log("Loaded..")
                    }, 100);
                });
            } else if (this.state.currentPage + 1 === totalPage) {
                this.setState({ displayVisitors: this.state.filteredVisitors, totalPage: totalPage });
            }
        }
    }

   loadMore=()=>{
        if (this.state.currentPage + 1 < this.state.totalPage) {
            let curpage = this.state.currentPage < this.state.totalPage ? this.state.currentPage + 1 : this.state.currentPage;
            let visitors = this.state.filteredVisitors.slice(0, (curpage + 1) * this.state.pagesize);
            this.setState({ displayVisitors: visitors, currentPage: curpage });
        } else {
            if (this.state.currentPage + 1 === this.state.totalPage && this.state.hasMore === true) {
                this.setState({ hasNextPage: false });
            }
        }
    }

    OLD__componentDidMount = () => {
        if ((this.state.filteredVisitors).length === 0 && (this.state.visitors).length > 0 && this.state.currentPage === 0) {
            let curpage = this.state.currentPage;
            let visitors = (this.state.visitors).slice(curpage * this.state.pagesize, (curpage + 1) * this.state.pagesize);
            this.setState({ filteredVisitors: visitors, totalPage: Math.ceil((this.state.visitors).length / this.state.pagesize) });
        } else {
            var totalPage = Math.ceil((this.state.visitors).length / this.state.pagesize);
            if (totalPage === 0) {
                this.setState({ hasMore: false });
            } else if (this.state.currentPage + 1 < totalPage) {
                let curpage = this.state.currentPage < totalPage ? this.state.currentPage + 1 : this.state.currentPage;
                let visitors = this.state.visitors.slice(0, (curpage + 1) * this.state.pagesize);
                this.setState({ filteredVisitors: visitors, totalPage: totalPage });
            } else if (this.state.currentPage + 1 === totalPage) {
                this.setState({ filteredVisitors: this.state.visitors, totalPage: totalPage });
            }
        }
    }

    /* accordion function start */

    toggle = () => {
        this.props.closeHandle();
        this.setState({
            open: true
        });
    }

    centerClientLoading = (visitor, reload = false) => {  
        if(visitor.id !== this.context.liveChatDocId )
            this.context.updateGlobalData("chatLoader",true);
            
        console.log("----data----", visitor,this.context.liveChatDocId, visitor.id)
        if (this.context.incoming_guide === false && this.context.incoming_guide_step === 0) {
            this.guidenextStep('incoming', 1, 'incoming_guide_step')
        }

        let key = visitor.id;
        let country = visitor.country;
        let browser = visitor.browser;
        let operating = visitor.operating;
        if (this.context.isMobileDevice) {
            try {
                document.getElementById('SideBarIO').classList.remove('IOLP');
                document.getElementById('new-Main-Chat').classList.remove('VRP');
                document.getElementById('new-Main-Chat').classList.remove('VRP2');
                document.getElementById('respMobiMenu').classList.add('d-none');
            } catch (err) { }
        }

        if (!reload && this.context.liveChatDocId === key) {
            return;
        }

        try {
            setCurrentChat(null);
        } catch (errrr) { }

        // this.setState({ isDisablePanel: true });

        var arrayToSet = [];
        console.log(validateEmail(visitor.client_email),"validateEmail(visitor.client_email)")
        if (!validateEmail(visitor.client_email)) {
            visitor.client_email = ENTER_EMAIL;
        }
        if (!validateEmail(visitor.visitor_email)) {
            visitor.visitor_email = ENTER_EMAIL;
        }

        var Obj1 = {
            key: "currentVisitor",
            value: visitor
        }

        var Obj2 = {
            key: "midHeadercountry",
            value: country
        }

        var Obj3 = {
            key: "midHeaderbrowser",
            value: browser
        }

        var Obj4 = {
            key: "midHeaderoperating",
            value: operating
        }

        var Obj5 = null;
        if (visitor.agent_end === "VISITOR_END_CHAT") {
            Obj5 = {
                key: "chatPickStatusTransaction",
                value: { key: key, status: "close", Text: "Close window", msgToShow: visitor.message_to_show }
            }
        } else {
            Obj5 = {
                key: "chatPickStatusTransaction",
                value: { key: key, status: "start", Text: "Start Chat", msgToShow: null }
            }
        }

        var Obj6 = {
            key: "showMidHeader",
            value: true
        }

        var Obj7 = {
            key: "liveChatDocId",
            value: key
        }

        var Obj8 = {
            key: "showVisitorPaths",
            value: false
        }

        var Obj9 = {
            key: "rightSectionItemToShow",
            value: "visitorslist"
        }

        var Obj11 = {
            key: "isCloseBtnClicked",
            value: ""
        }

        var Obj12 = {
            key: "showPastChat",
            value: false
        }

        arrayToSet.push(Obj12);
        arrayToSet.push(Obj11);
        arrayToSet.push(Obj1);
        arrayToSet.push(Obj2);
        arrayToSet.push(Obj3);
        arrayToSet.push(Obj4);
        arrayToSet.push(Obj5);
        arrayToSet.push(Obj6);
        arrayToSet.push(Obj7);
        arrayToSet.push(Obj8);
        arrayToSet.push(Obj9);
        this.context.updateGlobalData("array", arrayToSet);

        // Clearbit Enrichement API is called HERE        
        if (this.agent.revealDataEnabled) {
            let enrichmentData = {};
            // console.log("💡🛠 -> file: incoming.js -> line 641 -> visitor.client_email", visitor.client_email);
            // console.log("💡🛠 -> file: incoming.js -> line 641 -> visitor.visitor_email", visitor.visitor_email);

            let mailId = (visitor.visitor_email !== 'noreply@appypie.com' && visitor.visitor_email !== '') ? visitor.visitor_email : visitor.client_email;
            try {
                if (mailId !== 'noreply@appypie.com' && mailId !== '') {
                    firebaseServices.db.collection('enrichmentUsers').doc(mailId).get()
                        .then((doc) => {
                            if (doc.exists) {
                                enrichmentData = doc.data();
                                enrichmentData.visitorId = visitor.id;
                                // console.log("💡🛠 -> file: index.js -> line 278 -> enrichmentData from DB", enrichmentData);
                                this.context.updateGlobalData("enrichmentData", enrichmentData)
                            }
                            else {
                                this.context.updateGlobalData("enrichmentData", "");
                            }
                        })
                        .catch((err) => {
                            console.log('ERROR at clearbit enrichmentData DB: ' + err.message);
                            this.context.updateGlobalData("enrichmentData", "");
                        });
                }
            } catch (err) {
                console.log('ERROR at clearbit enrichmentData: ' + err.message);
                this.context.updateGlobalData("enrichmentData", "");
            }
            // Clearbit Enrichement API is called ENDS HERE
            try {
                firebaseServices.db.collection('premiumUsers').doc(visitor.IPv4).get()
                    .then((docPremium) => {
                        let revealData = "";
                        if (docPremium.exists) {
                            revealData = docPremium.data();
                            revealData.visitorId = visitor.id;
                        }
                        this.context.updateGlobalData("revealData", revealData)
                    }).catch((err) => {
                        console.log('ERROR at clearbit revealData: ' + err.message);
                        this.context.updateGlobalData("revealData", "");
                    });
            } catch (err) {
                console.log('ERROR at clearbit revealData: ' + err.message);
                this.context.updateGlobalData("revealData", "")
            }
        }
        // setTimeout(() => {
        //     this.setState({ isDisablePanel: false });
        // }, 900);
    }

    /* accordion function End */

    /* Unmount all mounted data start */

    componentWillUnmount = () => {
        try {
            this.incomingSnapshot();
            // this._isMounted = false;
        } catch (errr) { }
    }

    /* Unmount all mounted data end */

    endGuideTour = (docId, context) => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails === false) {
            return;
        }

        firebaseServices.db.collection("users").doc(userDetails.ownerId).collection("onboarding_guide").doc(docId).update({
            status: true
        })
        this.context.updateGlobalData(context, true);
    }

    guidenextStep = (docId, step, context) => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails === false) {
            return;
        }
        firebaseServices.db.collection("users").doc(userDetails.ownerId).collection("onboarding_guide").doc(docId).update({
            step: step,
        })
        this.context.updateGlobalData(context, step);
    }

    render() {
        console.log("render_leftsection")
        var incHeight = "100vh";
        // if (this.state.displayVisitors.length < 10) {
        //     incHeight = "auto";
        // }
        console.log(this.state.displayVisitors)
        var disab = "";
        if (this.state.isDisablePanel || this.context.isDisableIncomingPanel) {
            disab = "none";
        }

    
      

        return (<>

            <Incomingnotification />

            {(this.context.livechat_guide === false && this.context.livechat_step === 3) 
            // || (this.context.incoming_guide === false && this.context.incoming_guide_step === 0 && this.state.displayVisitors.length > 0)
             ? (<div className="overlay-guide bottom-div"></div>) : (null)}

            <div className={this.context.livechat_guide === false && this.context.livechat_step === 3 ? "incomingSection position-top" : "incomingSection"}>
                <div className="ongoingng-user left-panel-user my-4">
                    <button onClick={this.toggle.bind(this)} className="btn btn-lg btn-block d-flex align-items-center incoming-outgoing-btn accordion" type="button" style={{ background: this.state.filteredVisitors.length > 0 ? '#e87b2e' : '' }} >
                        <span className="mr-4">
                            {this.state.open ? (<img alt="" src={require('../../../../assets/images/livechat/down.svg')} />) : (<img alt="" src={require('../../../../assets/images/livechat/left.svg')} />)}
                        </span>
                        Incoming Conversation
                        <span className="ml-auto">{this.state.filteredVisitors.length}</span>
                    </button>
                    {this.context.livechat_guide === false && this.context.livechat_step === 3 ? (<div className="tour_guide">
                        <p className="title">Step 4 of 4</p>
                        <p className="text-center">Under this all your incoming conversations will appear.</p>
                        {/* <button className="btn-next" onClick={() => { this.guidenextStep('livechat', 4, 'livechat_step') }}>Next</button> */}
                        <button className="end-btn" onClick={() => { this.endGuideTour('livechat', 'livechat_guide') }}>End tour</button></div>) : (null)}
                </div>
                {!this.props.isOpen?<nav style={{ height: incHeight,  pointerEvents: disab }} id="incoming_collapse" className={"nav d-block list-discussions-js mb-n6 collapse " + (this.state.open ? ' in' : '')}>
                    <ListComponent
                        items={this.state.displayVisitors}
                        // moreItemsLoading={this.state.moreItemsLoading}
                        // loadMore={this.loadMore}
                        // hasNextPage={this.state.hasNextPage}
                        centerClientLoading={this.centerClientLoading}
                        visitor_status={NEW_VISITOR_STATUS}
                        open={this.state.open}

                    />
                </nav>:null}
            </div>
        </>);
    }
}

export default React.memo(Incoming);