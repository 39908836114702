/**
 * @class LoginComponent  
 * @description  Login Class Render Form For Proecss Login  functionality  
 * @version 1.0
 * @author Bineet kumar Chaubey<bineet@appypiellp.com>
 * @file LoginComponent.js
 * 
 */
import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import {
	login, loginWithGoogle,
	isUserExistWithEmail,
	getConvertPassCed,
	liveChatGoogleLogin,
	resendConfirmationCode
} from "./../../config/SignupLogin";
import data from "../../localization/login-registration.json";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import './Login.scss';
import {
	createNewUser,
	checkCompanyAliasAndCreateUnique,
	getOwnerUserDataForLogin,
	updateSessioninCollection,
	updateAgentData,
	getClientIpAndCountry
} from './Model/DbModel'
import { checkIsUserOrOwnerLogin, getUTCTimeDate } from "./OnboardHelper";
import { MAIL_FUNCTION_URL, COMMON_LOGIN_URL, DOMAIN_NAME, GATEWAY_URL, FUNCTION_API_KEY } from '../../config/siteConfig';
import Device from 'react-device';

///import {mailUrl} from "../../config/mailFunctions.js";
const publicIp = require("public-ip");
const firebaseAuthKey = "firebaseAuthInProgress";
class LoginComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			emailError: "",
			password: "",
			passwordError: "",
			loader: false,
			show_hint: false,
			deactivateAccountShow: false,
			userObject: {},
			loginResponse: {},
			loginType: '',
			googleEmail: '',
			UA: '',
			myBrowser: '',
			OS: '',
			IPv4: ''
		};
	}

	async componentWillMount() {
		getClientIpAndCountry();
		let agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
		let commonUserDetails = localStorage.getItem('userdata') ? JSON.parse(localStorage.getItem('userdata')) : false;
		let organization_alias = agent.ownerIdentifire ? agent.ownerIdentifire : agent.organization_alias;
		organization_alias = organization_alias ? organization_alias.trim() : "";
		if (agent) {
			window.location.href = '/' + organization_alias;
		} else if (commonUserDetails) {
			window.location.href = '/company-info';
		} else {
			window.location.href = COMMON_LOGIN_URL + "/login?frompage=" + encodeURIComponent(DOMAIN_NAME + '/common-session') + "&website=" + encodeURIComponent(DOMAIN_NAME);
		}
		return false;
	}

	/**
	 *  best deal
	 */
	componentDidMount = async () => {
		localStorage.removeItem('loggingout');
		this.setState({ loader: true });
		await checkIsUserOrOwnerLogin();
		this.setState({ loader: false });
	}
	/**
	 * validate email
	 */
	validateEmail = email => {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};
	/**
	 * validate email
	 */
	validatePassword = password => {
		var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]{8,16}$/;
		return re.test(password);
	};

	handleEmail = e => {
		var _email = e.target.value.trim();
		if (!this.validateEmail(_email)) {
			this.setState({
				emailError: data.error.email_error,
				email: _email
			});
		} else {
			if (e.key === "Enter") {
				this.handleLogin();
			}
			this.setState({
				email: _email,
				emailError: ""
			});
		}
	};
	handlePassword = e => {
		if (e.key === "Enter") {
			this.handleLogin();
		}
		this.setState({
			password: e.target.value,
			passwordError: ""
		});
		// }
	};
	handleLogin = async () => {
		this.setState({ loader: true });
		if (this.state.email.trim() === "") {
			this.state.emailError = "Required field cannot be left blank";
			this.setState({ loader: false });
			return;
		}
		if (this.state.password.trim() === "") {
			this.state.passwordError = "Required field cannot be left blank";
			this.setState({ loader: false });
			return;
		}

		const { email, password, emailError, passwordError } = this.state;
		if (emailError !== "" || passwordError !== "") {
			this.setState({ loader: false });
			return;
		} else {
			var res = await login(email, password);
			this.setState({
				loginResponse: res
			});
			if (res.message === "Incorrect username or password") {
				const IsEmail = await isUserExistWithEmail(email)
				if (IsEmail === false) {
					await resendConfirmationCode(email)
					localStorage.setItem("_email", email)
					localStorage.removeItem('companyName')
					localStorage.removeItem('settingrgbColor')
					localStorage.removeItem('settingbgColor')
					localStorage.removeItem('clientBackgroundColor')
					localStorage.removeItem('chatBlackImage')
					localStorage.removeItem('whiteImage')
					localStorage.removeItem('fbwtimage')
					localStorage.setItem('skipSignUp', 'true')
					this.props.history.push('/')
				} else {
					NotificationManager.error("", "Incorrect username or password.", 3000);
					this.setState({
						passwordError: "Incorrect username or password",
						loader: false
					});
				}
			} else if (parseInt(res.status) === 200) {
				const IsEmail = await isUserExistWithEmail(email)
				console.log('IsEmail >>> ', IsEmail);
				if (IsEmail) {
					const userObject = IsEmail[0];
					this.setState({
						userObject: userObject
					})
					if (userObject.deactivated === true) {
						this.setState({
							deactivateAccountShow: true,
							loader: false
						})
						return false;
					}
					const userId = userObject.id
					const getloginData = await getOwnerUserDataForLogin(userId, email);
					delete getloginData.password
					// valid user
					// now user id found in this user is in our  system
					localStorage.removeItem("_email");
					localStorage.removeItem("OwnerName");
					this.setState({
						email: "",
						password: ""
					});
					var _data = JSON.parse(res.data);
					const accessToken = _data.accessToken.jwtToken
					localStorage.setItem("accessToken", accessToken);
					await updateSessioninCollection(accessToken, userId);
					// const getUserLogin = localStorage.getItem('redirectPage');
					getloginData.ownerEmail = getloginData.email;
					getloginData.agent_name = getloginData.name;
					localStorage.setItem('agent', JSON.stringify(getloginData));
					const agentId = getloginData.agentId
					const ownerId = getloginData.ownerId
					const newAgentData = {
						last_login: getUTCTimeDate(),
						online_status: 1
					}


					await updateAgentData(ownerId, agentId, newAgentData)

					localStorage.removeItem("password");
					// localStorage.removeItem("OwnerName");
					localStorage.removeItem('settingrgbColor')
					localStorage.removeItem('settingbgColor')
					localStorage.removeItem('settingChatIcon')
					localStorage.removeItem('chatBlackImage')
					localStorage.removeItem('whiteImage')
					localStorage.removeItem('fbwtimage')
					localStorage.removeItem("_email");
					localStorage.removeItem("companyName");

					// Now check If User Check Company name 
					if (!getloginData.company_name || getloginData.company_name === '') {
						localStorage.removeItem('redirectPage');
						this.props.history.push('company-info');
						return;
					}
					window.location = '/' + getloginData.organization_alias;
					return;
					// this.setState({loader: false});

				} else {
					// User Is Login but Not in system Create User In system and make Login
					var _data = JSON.parse(res.data);
					const accessToken = _data.accessToken.jwtToken
					localStorage.setItem("accessToken", accessToken);
					// save Two Step data in this page 
					const companyName = localStorage.getItem('companyName');
					const settingrgbColor = localStorage.getItem('settingrgbColor')
					const settingbgColor = localStorage.getItem('settingbgColor')
					const clientBackgroundColor = localStorage.getItem('clientBackgroundColor')
					const settingChatIcon = localStorage.getItem('settingChatIcon')
					const chatBlackImage = localStorage.getItem('chatBlackImage')
					const whiteImage = localStorage.getItem('whiteImage')
					const fbwtimage = localStorage.getItem('fbwtimage')

					let neworgAlias = '';
					if (companyName) {
						const companyNamewithoutSPace = companyName.split(" ").join("").replace(/[^\w\s]/gi, '').toLowerCase();
						const uniqueResponce = await checkCompanyAliasAndCreateUnique(companyNamewithoutSPace);


						if (uniqueResponce) {
							let randValu = Math.floor(Math.random() * 10000);
							neworgAlias = companyNamewithoutSPace + randValu;
						} else {
							neworgAlias = companyNamewithoutSPace
						}
					}

					// initil Data to create User
					const updateData = {
						companyName: companyName,
						organization_alias: neworgAlias,
						settingrgbColor: settingrgbColor,
						settingbgColor: settingbgColor,
						settingChatIcon: settingChatIcon,
						email: email,
						whiteImage: whiteImage,
						chatBlackImage: chatBlackImage,
						fbwtimage: fbwtimage,
						password: password,
						accessToken: accessToken,
						clientBackgroundColor: clientBackgroundColor
					}

					if (companyName === null || companyName === '' || companyName === undefined || typeof companyName === undefined) {
						localStorage.setItem('updataAfterLogin', JSON.stringify(updateData));

						this.props.history.push('/');
						return;
					} else {
						const UserDocID = await createNewUser(updateData, 7)
						if (UserDocID) {
							// TODO: login User here In system
							// Add User DataENtry Through ThisAPi 
							localStorage.removeItem("password");
							// localStorage.removeItem("OwnerName");
							localStorage.removeItem('settingrgbColor')
							localStorage.removeItem('settingbgColor')
							localStorage.removeItem('clientBackgroundColor')
							localStorage.removeItem('settingChatIcon')
							localStorage.removeItem('chatBlackImage')
							localStorage.removeItem('whiteImage')
							localStorage.removeItem('fbwtimage')
							localStorage.removeItem("_email");
							localStorage.removeItem("companyName");
							this.setState({
								email: "",
								password: "",
							});
							await updateSessioninCollection(accessToken, email);
							const getloginData = await getOwnerUserDataForLogin(UserDocID, email);
							delete getloginData.password
							// valid user
							getloginData.ownerEmail = getloginData.email;
							getloginData.agent_name = getloginData.name;
							localStorage.setItem('agent', JSON.stringify(getloginData));
							// return;
							// this.props.history.push("/agent-setup");
							// const getUserLogin = localStorage.getItem('redirectPage');
							let chatURL = DOMAIN_NAME + '/' + neworgAlias;
							var body = [`email=${email.toLowerCase()}&type=welcome_email&data=${"data"}&req_ip=${this.state.IPv4}&&chatURL=${chatURL}`];
							this.sendEmail(body);
							this.props.history.push('/agent-setup');
						}
					}
					return;
				}

			}
			else if (parseInt(res.status) === 401) {
				// Wrong Password OR User IS Not exist 
				NotificationManager.error("", "Incorrect username or password.", 3000);
				this.setState({
					passwordError: "Incorrect username or password",
					loader: false
				});
			}
			return
		}
	};


	sendEmail = (body) => {
		fetch(GATEWAY_URL + '/sendEmail', {
			method: "post",
			body: body,
			headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
		})
			.then()
			.then(response => {
				console.log(response);
			})
			.catch(e => {
				console.log(e, "ERROR");
			});
	}

	/**
	 * Handle Google Login
	 * 
	 * local database 
	 * 
	 */
	handleGoogleLogin = async () => {
		var res = await loginWithGoogle();

		if (!res.error) {
			console.clear();

			localStorage.setItem(firebaseAuthKey, "1");
			var email = res.result.user.email;
			var uid = res.result.user.uid;
			var token = res.result.credential.accessToken;

			this.setState({ loader: true });

			const credential = getConvertPassCed(email);
			const IsEmail = await isUserExistWithEmail(email)


			if (!IsEmail) {
				// insert into AWS page 
				liveChatGoogleLogin(email, credential, uid, "google", token).then(Result => {
					/*
					 * both case or handle If User Already in common  login || OR New User 
					 * This will Fail if user have change his gmail email account Password on AWS common login Through 
					 * Reset password Featured,  and then Try to login With google 
					 *  
					 * console.log('checking');
					 * console.log('Result',Result);
					 * return ;
					 */

					// Login happen from Common API 
					// login With AWS confnito common and Create User IN Our System In Firebase Collection
					login(email, credential).then(async (loginRes) => {

						console.log(loginRes);

						if (loginRes.status === '401') {
							/* Gmail Deafult password is not varify With Common Login API
							* it is possbility that User have Login This gmail With A custom password before By Username/ppassword Method
							*  Or User Have Changed His Google Default Password
							*/
							// NotificationContainer.error('','Please Use Email ans Passowrd Method to signup/login')
							NotificationManager.error("", "Please use email and passowrd method to signup/login", 5000);
							this.setState({ loader: false });
							return;
						}
						// now check Is User Exists

						var _data = JSON.parse(loginRes.data);
						const accessToken = _data.accessToken.jwtToken
						localStorage.setItem("accessToken", accessToken);

						const companyName = localStorage.getItem('companyName');
						const settingrgbColor = localStorage.getItem('settingrgbColor')
						const settingbgColor = localStorage.getItem('settingbgColor')
						const clientBackgroundColor = localStorage.getItem('clientBackgroundColor')
						const settingChatIcon = localStorage.getItem('settingChatIcon')
						const chatBlackImage = localStorage.getItem('chatBlackImage')
						const whiteImage = localStorage.getItem('whiteImage')
						const fbwtimage = localStorage.getItem('fbwtimage')

						let neworgAlias = '';

						var updateData = {
							companyName: companyName,
							settingrgbColor: settingrgbColor,
							settingbgColor: settingbgColor,
							settingChatIcon: settingChatIcon,
							email: email,
							whiteImage: whiteImage,
							chatBlackImage: chatBlackImage,
							fbwtimage: fbwtimage,
							password: credential,
							accessToken: accessToken,
							organization_alias: neworgAlias,
							clientBackgroundColor: clientBackgroundColor
						}

						if (companyName === null || companyName === '' || companyName === undefined || typeof companyName === undefined) {

							localStorage.setItem('updataAfterLogin', JSON.stringify(updateData));
							this.props.history.push('/');
							return;
						} else {
							if (companyName) {
								const companyNamewithoutSPace = companyName.split(" ").join("").replace(/[^\w\s]/gi, '').toLowerCase();
								const uniqueResponce = await checkCompanyAliasAndCreateUnique(companyNamewithoutSPace);
								if (uniqueResponce) {
									let randValu = Math.floor(Math.random() * 10000);
									neworgAlias = companyNamewithoutSPace + randValu;
								} else {
									neworgAlias = companyNamewithoutSPace
								}
							}

							updateData.organization_alias = neworgAlias;

							const UserDocID = await createNewUser(updateData, 8)

							this.setState({ loader: true });
							if (UserDocID) {
								// Add User DataENtry Through ThisAPi 
								localStorage.removeItem("password");
								// localStorage.removeItem("OwnerName");
								localStorage.removeItem('settingrgbColor')
								localStorage.removeItem('settingbgColor')
								localStorage.removeItem('settingChatIcon')
								localStorage.removeItem('chatBlackImage')
								localStorage.removeItem('whiteImage')
								localStorage.removeItem('fbwtimage')
								localStorage.removeItem("_email");
								// localStorage.removeItem("companyName");
								this.setState({
									email: "",
									password: ""
								});

								// update common apo session in DB
								updateSessioninCollection(accessToken, UserDocID);
								const getloginData = await getOwnerUserDataForLogin(UserDocID, email);
								// console.log(getloginData);
								delete getloginData.password
								// Valid user
								getloginData.ownerEmail = getloginData.email;
								getloginData.agent_name = getloginData.name;
								localStorage.setItem('agent', JSON.stringify(getloginData));
								let chatURL = DOMAIN_NAME + '/' + neworgAlias;
								var body = [`email=${email.toLowerCase()}&type=welcome_email&data=${"data"}&req_ip=${this.state.IPv4}&chatURL=${chatURL}`];
								this.sendEmail(body);
								if (!getloginData.company_name) {
									this.props.history.push('company-info');
									return
								}
								this.props.history.push("/agent-setup");
								// this.setState({loader: false});
								return

							} else {
								console.log('User Not Created');
								// this.props.history.push("/company-erropage");// TODO: redirect 30 
								this.setState({ loader: false });
							}
						}

					}).catch(err => {
						console.log(err);
						this.setState({ loader: false });
					})

				}).catch(err => {
					console.log(err);
					this.setState({ loader: false });
				})

			} else {

				login(email, credential).then(async (loginRes) => {

					if (loginRes.status === '401') {
						/* Gmail Deafult password is not varify With Common Login API
						* it is possbility that User have Login This gmail With A custom password before By Username/ppassword Method
						*  Or User Have Changed His Google Default Password
						*/
						// NotificationContainer.error('','Please Use Email ans Passowrd Method to signup/login')
						NotificationManager.error("", "Please use email and passowrd method to signup/login", 5000);
						this.setState({ loader: false });
						return;
					}

					// Email is exits in our database And Login User Have Done
					// TODO: 
					this.setState({
						email: "",
						password: "",
						googleEmail: email
					});
					var _data = JSON.parse(loginRes.data);
					const accessToken = _data.accessToken.jwtToken
					localStorage.setItem("accessToken", accessToken);
					const userObject = IsEmail[0];
					const UserDocID = userObject.id
					updateSessioninCollection(accessToken, UserDocID);

					this.setState({
						userObject: userObject,
						loginType: 'google'
					})
					if (userObject.deactivated === true) {
						this.setState({
							deactivateAccountShow: true,
							loader: false
						})
						return false;
					}

					const getloginData = await getOwnerUserDataForLogin(UserDocID, email);

					delete getloginData.password
					// valid user
					getloginData.ownerEmail = getloginData.email;
					getloginData.agent_name = getloginData.name;
					localStorage.setItem('agent', JSON.stringify(getloginData));

					const agentId = getloginData.agentId
					const ownerId = getloginData.ownerId
					const newAgentData = {
						last_login: getUTCTimeDate(),
						online_status: 1
					}
					await updateAgentData(ownerId, agentId, newAgentData)

					// this.props.history.push("/agent-setup");
					const getUserLogin = localStorage.getItem('redirectPage');

					if (getUserLogin) {
						localStorage.removeItem('redirectPage');
						this.props.history.push(getUserLogin);
						return
					} else {
						window.location = '/' + getloginData.organization_alias;
						// this.props.history.push("/");
						return
					}

				}).catch(err => {

					console.log(err.message);
					this.setState({ loader: false });
				})

			}


		}

	};



	cancelReactivateAccount = () => {
		this.setState({
			deactivateAccountShow: false
		})
	}

	confirmReactivateAccount = async () => {
		this.setState({
			loader: true
		})
		var userObject = this.state.userObject;
		const userId = userObject.id
		const { email, loginType } = this.state;
		var getloginData = {}
		if (loginType === 'google') {
			getloginData = await getOwnerUserDataForLogin(userId, this.state.googleEmail);
		} else {
			getloginData = await getOwnerUserDataForLogin(userId, email);
		}
		var body = [`ownerId=${userObject.id}&ownerEmail=${userObject.email.toLowerCase()}&browser=${this.state.myBrowser}&OS=${this.state.OS}&ownerName=${getloginData.name}&companyName=${userObject.company_name}`]
		//fetch(MAIL_FUNCTION_URL + "/loginApi/reactivateAccount", {
		fetch(GATEWAY_URL + "/reactivateAccount", {
			method: "post",
			body: body,
			headers: { "X-API-Key": FUNCTION_API_KEY, "Content-type": "application/x-www-form-urlencoded" }
		})
			.then(response => response.json())
			.then(async (response) => {
				if (response.status === true) {
					const { email, loginType } = this.state;
					if (loginType === 'google') {
						// const getloginData = await getOwnerUserDataForLogin(userId, this.state.googleEmail);

						delete getloginData.password
						// valid user
						getloginData.ownerEmail = getloginData.email;
						localStorage.setItem('agent', JSON.stringify(getloginData));
						const agentId = getloginData.agentId
						const ownerId = getloginData.ownerId
						const newAgentData = {
							last_login: getUTCTimeDate(),
							online_status: 1
						}
						await updateAgentData(ownerId, agentId, newAgentData)

						// this.props.history.push("/agent-setup");
						const getUserLogin = localStorage.getItem('redirectPage');

						if (getUserLogin) {
							localStorage.removeItem('redirectPage');
							this.props.history.push(getUserLogin);
							return
						} else {
							window.location = '/' + getloginData.organization_alias;
							// this.props.history.push("/");
							return
						}
					} else {
						// const getloginData = await getOwnerUserDataForLogin(userId, email);
						delete getloginData.password
						// valid user
						// now user id found in this user is in our  system
						localStorage.removeItem("_email");
						localStorage.removeItem("OwnerName");
						this.setState({
							email: "",
							password: ""
						});
						var res = this.state.loginResponse;
						var _data = JSON.parse(res.data);
						const accessToken = _data.accessToken.jwtToken
						localStorage.setItem("accessToken", accessToken);
						await updateSessioninCollection(accessToken, userId);
						getloginData.ownerEmail = getloginData.email;
						// const getUserLogin = localStorage.getItem('redirectPage');
						getloginData.agent_name = getloginData.name;
						localStorage.setItem('agent', JSON.stringify(getloginData));
						const agentId = getloginData.agentId
						const ownerId = getloginData.ownerId
						const newAgentData = {
							last_login: getUTCTimeDate(),
							online_status: 1
						}


						await updateAgentData(ownerId, agentId, newAgentData)

						localStorage.removeItem("password");
						// localStorage.removeItem("OwnerName");
						localStorage.removeItem('settingrgbColor')
						localStorage.removeItem('settingbgColor')
						localStorage.removeItem('settingChatIcon')
						localStorage.removeItem('chatBlackImage')
						localStorage.removeItem('whiteImage')
						localStorage.removeItem('fbwtimage')
						localStorage.removeItem("_email");
						localStorage.removeItem("companyName");

						// Now check If User Check Company name 
						if (!getloginData.company_name || getloginData.company_name === '') {
							localStorage.removeItem('redirectPage');
							this.props.history.push('company-info');
							return;
						}
						window.location = '/' + getloginData.organization_alias;
						return;
					}
				} else {
					NotificationManager.error("", "Something went wrong, please try after some time", 5000);
					this.setState({
						loader: false
					})
				}
			});
	}

	onChangee = (deviceInfo) => {
		this.setState({
			UA: deviceInfo.ua,
			myBrowser: deviceInfo.browser.name,
			OS: deviceInfo.os.name
		})
	}

	render() {
		return (null);
	}

	OLD___render() {
		const { user, signOut, signInWithGoogle } = this.props;
		return (
			<div style={{ width: '100%' }}>
				<Device onChange={this.onChangee} />
				<div className="login-container loginBG">
					<NotificationContainer />
					{this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}

					<div className="loginPage">
						<div className="container">
							<div className="login-header">
								<img
									className="loginHeaderLogo"
									src={require("../../assets/images/REO.png")}
									alt="logo"
								/>
							</div>
						</div>
						<div className="">
							<div className="loginBody pt-5">
								<div className="login-description signup-description">
									{/* <h5>{data.description.h1}</h5> */}
									<h5>{data.description.h3}</h5>
								</div>

								<div className="login-body">
									<div className="loginFormOrlienparent">
										<div className="login-form orLine">
											<TextField
												error={this.state.emailError === "" ? false : true}
												id="standard-password-input"
												label={data.hint.email}
												type="text"
												margin="normal"
												onChange={this.handleEmail}
												onKeyPress={this.handleEmail}
												value={this.state.email}
												required
											/>
											{this.state.emailError !== "" ? <div className="Loginwarning"><img alt="" src={require("../../assets/images/danger.png")} /><span>{this.state.emailError}</span></div> : <div className="Loginwarning" style={this.state.emailError === "" ? { display: "block" } : { display: "none" }}></div>}
											<div className="loginPassInfo">
												<TextField
													error={this.state.passwordError === "" ? false : true}
													id="standard-password-input"
													label={data.hint.password}
													type="password"
													margin="normal"
													onChange={this.handlePassword}
													onKeyPress={this.handlePassword}
													// value={this.state.password}
													autoComplete="new-password"
													required
												/>
												<span className="logininfoIcon" onMouseEnter={() => this.setState({ show_hint: true })} onMouseLeave={() => this.setState({ show_hint: false })}>
													<img alt=""
														src={require("../../assets/img/icon/infoIcon.svg")}
													/>
													{this.state.show_hint ? <span className="titleShowHover w200px" style={this.state.show_hint ? { display: "block" } : null}>{data.message}</span> : null}
												</span>
											</div>
											{this.state.passwordError !== "" ? <div className="Loginwarning" ><img src={require("../../assets/images/danger.png")} alt="" /><span>{this.state.passwordError}</span></div> : null}
											<div onClick={this.handleLogin}>
												<div className="loginSignupBTN">
													<div className="singnInText">{data.button.login}</div>
												</div>
											</div>
											<div className="forgotOrSignupLoginPage">
												<p>
													<a className="forgot-password-l"
														onClick={() => this.props.history.push("/forget-password")} >
														{data.forgetPassword}
													</a>
												</p>
												<p>
													(New User?{" "}
													<a style={{ color: "#007bff" }}
														onClick={() => this.props.history.push("/company-registration")}
														className="signup" >
														{data.button.signup}
													</a>{" "}
													to Register)
												</p>
											</div>
										</div>
									</div>
									<div className="login-social">
										<div className="socialBtnCenter">
											<div className="socialMediaBtnLink">
												<div className="socialLink"
													onClick={this.handleGoogleLogin} >
													<span className="gp">
														<img alt="" src={"https://d2wuvg8krwnvon.cloudfront.net/newui/images/g-normal.png"} />
														{data.social.googlein}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="termsAndConditionPrivacyPolicy">
									<p className="seureArea">
										<img src={require("../../../src/assets/images/lock2.svg")} alt="" />
										Secure Area
									</p>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="login-footer">
								<div className="login-footer-1"></div>
								<div className="loginFooterReview loginImgFilter">
									<div className="container">
										<div className="review-section">
											<div className="review-companies">
												<div className="reviewcompanieswrapper">
													<div className="reviewcompaniesimg">
														<a
															href="https://privacy.truste.com/privacy-seal/validation?rid=aaa1a089-4a08-4066-867e-29f8b4ebce47"
															target="_blank"
															rel="nofollow noopener"
														>
															<img alt="" src={require('../../../src/assets/images/login/login1.png')} />
														</a>
													</div>
												</div>
												<div className="reviewcompanieswrapper">
													<div className="reviewcompaniesimg">
														<a
															target="_blank"
															rel="nofollow noopener"
														>
															<img alt="" src={require('../../../src/assets/images/login/login2.png')} />
														</a>
													</div>

												</div>
												<div className="reviewcompanieswrapper">
													<div className="reviewcompaniesimg">
														<a
															target="_blank"
															rel="nofollow noopener"
														>
															<img alt="" src={require('../../../src/assets/images/login/login3.png')} />
														</a>
													</div>
												</div>
												<div className="reviewcompanieswrapper">
													<div className="reviewcompaniesimg">
														<a
															target="_blank"
															rel="nofollow noopener"
														>
															<img alt="" src={require('../../../src/assets/images/login/login4.png')} />
														</a>
													</div>

												</div>
												<div className="reviewcompanieswrapper">
													<div className="reviewcompaniesimg">
														<a
															target="_blank"
															rel="nofollow noopener"
														>
															<img alt="" src={require('../../../src/assets/images/login/login5.png')} />
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.deactivateAccountShow ? (<div id="shadowMasking" className="popupShadow" style={{ display: 'block' }}></div>) : (null)}
				{this.state.deactivateAccountShow ? (<div className="popupInstallLivechat popupLivechat revoke-consent">
					<div className="popupHeader py-4 px-5 k-flex align-items-center">
						<h6 className="card-title mb-0 white-text">Confirm</h6>
						<span className="close-popup ml-auto cursor-pointer" onClick={this.cancelReactivateAccount}><svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41"><path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" /><g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)"><rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" /><rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" /></g></svg></span>
					</div>
					<div className="popupBody py-5 px-5 my-4 mx-4">
						<div className="pb-5 feilds-instruction">
							<p className="card-body-text pb-4 text-black">Your account is deactivated. Do you want to reactivate your account?</p>
						</div>
						<div>
							<button className="btnBlueSettings mr-2" onClick={this.confirmReactivateAccount}>Yes</button>
							<button className="btnWhiteSettings" onClick={this.cancelReactivateAccount}>No</button>
						</div>
					</div>
				</div>) : (null)}
			</div>
		);
	}
}

export default LoginComponent;
