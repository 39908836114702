import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      let cookieEnabled = navigator.cookieEnabled;
      if (!cookieEnabled){ 
          document.cookie = "testcookie";
          cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
      }
      this.state = { hasError: !cookieEnabled };
    }
    
    componentDidMount () {
        setInterval(() => {
            const cookieEnabled = this.checkCookie();
            if(cookieEnabled && this.state.hasError) {
                window.location.reload(true);
            }
        }, 3000);
    }

    checkCookie = () => {
        let cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled){ 
            document.cookie = "testcookie";
            cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
        }
        return cookieEnabled;
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      //return { hasError: error };
      return null;
    }
  
    componentDidCatch(error, errorInfo) {
        console.log('----21---');
      // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <div className="login-container loginBG">
        <div className="loginPage">
            <div className="container">
                <div className="login-header">
                    <img
                        className="loginHeaderLogo"
                        src={require("../assets/images/REO.png")}
                        alt="logo"
                    />
                </div>
            </div>
            <div className="container">
                <div className="company-information company-information1">
                    <div className="login-description pb-10 mb-5">
                        <h2 style={{textAlign:'center'}}>Your browser has cookies disabled. Make sure that your cookies are enabled and try again.</h2>
                    </div>
                </div>
            </div>

            <div className="footerStepbyStep loginFooterReview">
                <div className="container">
                    <div className="catfeacture-section-2" style={{display: 'block'}}>
                    <h4 className="clicntLogo-heading">Join <strong>7 million +</strong> other businesses:</h4>
                    <ul>
                        <li><img alt="" src={require('./../assets/images/footer-logo/accenture.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/assurant.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/decathlon.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/deloitte.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/escorts.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/loreal.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/mitie.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/nike.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/pinnacle.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/sodexo.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/shangri.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/southwest.png')} /></li>
                        <li><img alt="" src={require('./../assets/images/footer-logo/thehomedepot.png')} /></li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>;
      }
      return this.props.children; 
    }
  }

  export default ErrorBoundary;