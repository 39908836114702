import React, { Component } from "react";
// import RawTooltip from "./../../components/Tooltip/rawtooltip";
import TextField from "@material-ui/core/TextField";
import data from "../../localization/login-registration.json";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import './../organization/Login.scss';
import { checkIsUserOrOwnerLogin } from "./../organization/OnboardHelper";
import { MAIL_FUNCTION_URL, GATEWAY_URL, FUNCTION_API_KEY } from '../../config/siteConfig';
import Device from 'react-device';
const publicIp = require("public-ip");
const md5 = require("md5");

class GlobinLoginComponent extends Component {
	constructor(props) {

		super(props);
		this.state = {
			name: "",
			email: "",
			emailError: "",
			password: "",
			passwordError: "",
			loader: false,
			show_hint: false,
			deactivateAccountShow:false,
			userObject:{},
			loginResponse:{},
			loginType:'',
			googleEmail:'',
			UA:'',
            myBrowser:'',
			OS:'',
			IPv4:''
		};
	}
	async componentWillMount() {

		publicIp.v4().then((ip4) => {
            this.setState({
                IPv4:ip4
            })
        });

	}

	/**
	 *  best deal
	 */
	componentDidMount = async () => {

		this.setState({ loader: true });
		await checkIsUserOrOwnerLogin();
		this.setState({ loader: false });
	}
	/**
	 * validate email
	 */
	validateEmail = email => {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};
	/**
	 * validate email
	 */
	validatePassword = password => {
		var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]{8,16}$/;
		return re.test(password);
	};

	handleEmail = e => {
		var _email = e.target.value.trim();
		if (!this.validateEmail(_email)) {
			this.setState({
				emailError: data.error.email_error,
				email: _email
			});
		} else {
			if (e.key === "Enter") {
				this.handleLogin();
			}
			this.setState({
				email: _email,
				emailError: ""
			});
		}
	};
	handlePassword = e => {
		if (e.key === "Enter") {
			this.handleLogin();
		}
		this.setState({
			password: e.target.value,
			passwordError: ""
		});
		// }
	};
	getCookie(name) {
		const cookies = document.cookie.split(';');
		for (let cookie of cookies) {
			cookie = cookie.trim();
			if (cookie.startsWith(name + '=')) {
				return cookie.substring(name.length + 1);
			}
		}
		return null;
	}
	handleLogin = async () => {
		this.setState({ loader: true });
		if (this.state.email.trim() === "") {
			this.state.emailError = "Required field cannot be left blank";
			this.setState({ loader: false });
			return;
		}
		if (this.state.password.trim() === "") {
			this.state.passwordError = "Required field cannot be left blank";
			this.setState({ loader: false });
			return;
		}

		const { email, password, emailError, passwordError, IPv4 } = this.state;
		if (emailError !== "" || passwordError !== "") {
			this.setState({ loader: false });
			return;
		} else {

			let appyId = this.getCookie('APPYID');
            let body = [
                `email=${email}&appyId=${appyId}&password=${md5(password)}&IPv4=${IPv4}`
            ]
            ///fetch(MAIL_FUNCTION_URL + '/globalLogin', {
			fetch(GATEWAY_URL + '/globalLogin', {
                method:"post",
                body:body,
                headers : { "X-API-Key" : FUNCTION_API_KEY, "Content-type": "application/x-www-form-urlencoded" }
            })
            .then((response) => response.json())
            .then((response) => {
				if(response.status === 200) {
					console.log('response', response);
					let ownerData = response.ownerData.docData;
					let ownerId = response.ownerData.docId;
					let awsData = response.awsData;
					if(ownerData.deactivated===true) {
						NotificationManager.error("", "Account is deactivated", 5000);
					} else {
						let _data = response.agentData;
						_data.agent_name = _data.name;
						if(_data.alias !== "") {
							_data.name = _data.alias;
						}
						_data.ownerId =ownerId;
						localStorage.setItem('accessToken',_data.jwToken);
						delete _data.jwToken;
						_data.ownerIdentifire = ownerData.organization_alias;
						_data.organization_alias = ownerData.organization_alias
						_data.ownerEmail = awsData.userdata.email ? awsData.userdata.email : ownerData.email;
						_data.email = _data.ownerEmail;
						_data.globalLogin = true;
						_data.company_name = ownerData.company_name;
						_data.phone_call = ownerData.phone_call ? ownerData.phone_call : 0;
						_data.voip_call = ownerData.voip_call ? ownerData.voip_call : 0;
						_data.widget_voip_call = ownerData.widget_voip_call ? ownerData.widget_voip_call : 0;
						_data.whatsapp_msg = ownerData.whatsapp_msg ? ownerData.whatsapp_msg : 0;
						_data.isInHouseAccount = ownerData.isInHouseAccount ? ownerData.isInHouseAccount:0;
						_data.fb_page_token = ownerData.fb_page_token ? ownerData.fb_page_token: "";
						_data.tdm_tokens = ownerData.tdm_tokens ? ownerData.tdm_tokens: "";
						_data.smart_compose = ownerData.smart_compose ? ownerData.smart_compose: 0;
						_data.triggers_status = ownerData.triggers_status ? ownerData.triggers_status: 0;
						localStorage.setItem('agent',JSON.stringify(_data));
						window.location.href = "/"+ownerData.organization_alias;
					}
				} else {
					let message = response.msg ? response.msg : "Something went wrong, please try after some time";
					NotificationManager.error("", message, 5000);
				}
				this.setState({ loader: false });
            })

		}

	};

	
	onChangee = (deviceInfo) => {
        this.setState({
            UA:deviceInfo.ua,
            myBrowser:deviceInfo.browser.name,
            OS:deviceInfo.os.name
        })
      }

	render() {
		//const { user, signOut, signInWithGoogle } = this.props;
		return (
			<div style={{ width: '100%' }}>
				<Device onChange={this.onChangee} />
				<div className="login-container loginBG">
					<NotificationContainer />
					{this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}

					<div className="loginPage">
						<div className="container">
							<div className="login-header">
								<img
									className="loginHeaderLogo"
									src={require("../../assets/images/REO.png")}
									alt="logo"
								/>
							</div>
						</div>
						<div className="">
							<div className="loginBody pt-5">
								<div className="login-description signup-description">
									{/* <h5>{data.description.h1}</h5> */}
									<h5>{data.description.h3}</h5>
								</div>

								<div className="login-body">
									<div className="loginFormOrlienparent">
										<div className="login-form">
											<TextField
												error={this.state.emailError === "" ? false : true}
												id="standard-password-input"
												label={data.hint.email}
												type="text"
												margin="normal"
												onChange={this.handleEmail}
												onKeyPress={this.handleEmail}
												value={this.state.email}
												required
											/>
											{this.state.emailError !== "" ? <div className="Loginwarning"><img alt="" src={require("../../assets/images/danger.png")} /><span>{this.state.emailError}</span></div> : <div className="Loginwarning" style={this.state.emailError === "" ? { display: "block" } : { display: "none" }}></div>}
											<div className="loginPassInfo">
												<TextField
													error={this.state.passwordError === "" ? false : true}
													id="standard-password-input"
													label={data.hint.password}
													type="password"
													margin="normal"
													onChange={this.handlePassword}
													onKeyPress={this.handlePassword}
													// value={this.state.password}
													autoComplete="new-password"
													required
												/>
												<span className="logininfoIcon" onMouseEnter={() => this.setState({ show_hint: true })} onMouseLeave={() => this.setState({ show_hint: false })}>
													<img alt="" 
														src={require("../../assets/img/icon/infoIcon.svg")}
													/>
													{this.state.show_hint ? <span className="titleShowHover w200px" style={this.state.show_hint ? { display: "block" } : null}>{data.message}</span> : null}
												</span>
											</div>
											{this.state.passwordError !== "" ? <div className="Loginwarning" ><img src={require("../../assets/images/danger.png")} alt="" /><span>{this.state.passwordError}</span></div> : null}
											<div onClick={this.handleLogin}>
												<div className="loginSignupBTN">
													<div className="singnInText">{data.button.login}</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="termsAndConditionPrivacyPolicy">
									<p className="seureArea">
										<img src={require("../../../src/assets/images/lock2.svg")}  alt="" />
											    Secure Area
											</p>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="login-footer">
								<div className="login-footer-1"></div>
								<div className="loginFooterReview loginImgFilter">
									<div className="container">
										<div className="review-section">
											<div className="review-companies">
												<div className="reviewcompanieswrapper">
													<div className="reviewcompaniesimg">
														<a
															href="https://privacy.truste.com/privacy-seal/validation?rid=aaa1a089-4a08-4066-867e-29f8b4ebce47"
															target="_blank"
															rel="nofollow noopener"
														>
															<img  alt="" src={require('../../../src/assets/images/login/login1.png')} />
														</a>
													</div>
												</div>
												<div className="reviewcompanieswrapper">
													<div className="reviewcompaniesimg">
														<a
															// href="#"
															target="_blank"
															rel="nofollow noopener"
														>
															<img alt="" src={require('../../../src/assets/images/login/login2.png')} />
														</a>
													</div>

												</div>
												<div className="reviewcompanieswrapper">
													<div className="reviewcompaniesimg">
														<a
															// href="#"
															target="_blank"
															rel="nofollow noopener"
														>
															<img  alt="" src={require('../../../src/assets/images/login/login3.png')} />
														</a>
													</div>
												</div>
												<div className="reviewcompanieswrapper">
													<div className="reviewcompaniesimg">
														<a
															// href="#"
															target="_blank"
															rel="nofollow noopener"
														>
															<img alt="" src={require('../../../src/assets/images/login/login4.png')} />
														</a>
													</div>

												</div>
												<div className="reviewcompanieswrapper">
													<div className="reviewcompaniesimg">
														<a
															// href="#"
															target="_blank"
															rel="nofollow noopener"
														>
															<img  alt="" src={require('../../../src/assets/images/login/login5.png')} />
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				{this.state.deactivateAccountShow ? (<div id="shadowMasking" className="popupShadow" style={{display:'block'}}></div>) : (null)}
                    {this.state.deactivateAccountShow ? (<div className="popupInstallLivechat popupLivechat revoke-consent">
					<div className="popupHeader py-4 px-5 k-flex align-items-center">
                        <h6 className="card-title mb-0 white-text">Confirm</h6>
                        <span className="close-popup ml-auto cursor-pointer" onClick={this.cancelReactivateAccount}><svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41"><path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" /><g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)"><rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" /><rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" /></g></svg></span>
                    </div>
					<div className="popupBody py-5 px-5 my-4 mx-4">
                        <div className="pb-5 feilds-instruction">
                            <p className="card-body-text pb-4 text-black">Your account is deactivated. Do you want to reactivate your account?</p>
                        </div>
                        <div>
                            <button className="btnBlueSettings mr-2" onClick={this.confirmReactivateAccount}>Yes</button>
                            <button className="btnWhiteSettings" onClick={this.cancelReactivateAccount}>No</button>
                        </div>
                    </div>
                </div>) : (null)}
			</div>
		);
	}
}

export default GlobinLoginComponent;
