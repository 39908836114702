import React from 'react';
import ReactTooltip from "react-tooltip";
// import db from '../../config/firebase';
import { toast } from 'react-toastify';
import { MAIL_FUNCTION_URL,DOMAIN_NAME, FB_APP_ID, API_VERSION, GATEWAY_URL, FUNCTION_API_KEY, APP_FROM_CONNECT } from '../../config/siteConfig';
import { getZoomURL } from '../../services/service';

import LeftPanel from './LeftPanel';
import moment from 'moment-timezone';

import { getAppListFromConnect, getIntigrationUrl, getConnectedApps, deletedConnectedApp, connectApps, getMatches, deleteConnect, subscribePage, unsubscribePage, unInstallConnectedApp } from "../../services/connectIntegration";

import TextField from '@material-ui/core/TextField';
import { unsubscribe } from 'medium-editor';
import { getOwnerSnapShot } from '../../services/payment';
import PopUp from '../../layout/components/popup';
import firebaseServices from '../../firebase';
const imageURL = `https://mediaconnect.appypie.com/media/icons/128x128/`;

class Integration extends React.Component {
    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (this.agent === false) {
            window.location.href = "/"
        } 
        // else if (this.agent.roleType !== 'Owner' && this.agent.roleType !== 'Admin') {
        //     const orgnizationAlias = this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire;
        //     window.location.href = "/" + orgnizationAlias;  
        // }
        this.state = {
            showType: 0,
            pageList: [],
            allApps: [],
            installedApp: [],
            loader: true,
            appToConnect: {},
            allMatches: [],
            allUserData: {},
            userSnapShot: "",
            pagesSnapshot: "",
            instaSnapshot: "",
            noLinkedAccount: false,

            consumer_key: "",
            consumer_secret: "",
            access_token: "",
            access_secret: "",
            error_consumer_key: "",
            error_consumer_secret: "",
            error_access_token: "",
            error_access_secret: "",
            twitterTokens: {},
            error_twitter_dm: "",
            success_twitter_dm: "",
            page_token: {},
            instaPagesList: [],

            teleToken:"",
            error_teleToken:"",
            success_teleToken:"",
            teleUser:"",


            whatsappAccessToken:"",
            whatsappAccessId:"",
            whatsappPhoneId:"",
            error_whatsappToken:"",
            success_whatsappToken:"",
            whatsappUser:"",
            payment_status: true,
        }
    }

    async componentDidMount() {
        await this.userData();
        this.getAppList();
        this.getFbPages();
        this.getInstaPages();
        this.getInstagramPages();
        this.getWhatsappDeploy()
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        if(userDetails.ownerId) {
            getOwnerSnapShot(userDetails.ownerId, async (results) => {
                if (results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        payment_status:  _data.payment_status !== undefined  ? true : false
                    })
                    
                } else {
                    this.setState({
                        payment_status: false
                    })
                    
                }
            })
        }
        // this.getTeleTokens();
        

    }

    updateLocalStorage = (appName, value) => {
        var loggedInAgent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (appName === 'instagrambusiness_status' && loggedInAgent) {
            loggedInAgent.instagrambusiness_status = value;
        }
        else if (appName === 'whatsappbusinessmessenger_status' && loggedInAgent) {
            loggedInAgent.whatsappbusinessmessenger_status = value;
        }
        else {
            console.log('App name not matched or localStorage empty');
        }
        // this.agent = loggedInAgent;
        // console.log("\n\n\n💡🛠 -> file: index.js -> line 56 -> loggedInAgent", loggedInAgent);
        localStorage.setItem('agent', JSON.stringify(loggedInAgent));
    }

    userData = () => {
        return new Promise((resolve, reject) => {
            this.state.userSnapShot = firebaseServices.db.collection("users").doc(this.agent.ownerId).onSnapshot((doc) => {
                if (doc.exists) {
                    const _data = doc.data();
                    this.setState({
                        allUserData: _data
                    })
                }
                resolve(true);
            })
        })
    }

    componentWillUnmount() {
        try {
            this.state.userSnapShot();
            this.state.pagesSnapshot();
            this.state.instaSnapshot();
        } catch (err) {

        }

    }

    getAppList = async () => {
        const appList = await getAppListFromConnect();
        if (appList.status === 200) {
            const apps = appList.data.data;
            const filteredApps = apps.filter(obj => {
                return obj.appId !== "jvgl0d4fff345b00ypzx";
            });
            this.setState({
                loader: false,
                allApps: filteredApps,
            });
        } else {
            this.setState({
                loader: false,
                allApps: []
            })
        }
    }

    getAppList_OLD = async () => {
        const promise1 = new Promise(async (res1, rej1) => {
            const appList = await getAppListFromConnect();
            res1(appList);
        })

        const promise2 = new Promise(async (res2, rej2) => {
            const connectedApps = await getConnectedApps(this.agent.ownerId);
            res2(connectedApps);
        })

        Promise.all([promise1, promise2]).then((results) => {
            const allApps = results[0];
            let obj = {
                loader: false,
                allApps: [],
                installedApp: []
            }
            if (allApps.status === 200) {
                const apps = allApps.data.data;
                obj.allApps = apps;
            }

            const connectApp = results[1];
            if (connectApp.status === 200) {
                obj.installedApp = connectApp.data;
            }
            this.setState(obj);
        })
    }

    getFbPages = () => {
        this.pagesSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('fb_pages_connect').onSnapshot(async (querySnapshot) => {
            let pageList = [];
            let pagetoken = {};
            querySnapshot.forEach((doc) => {
                const { addedon, page_category, page_name, page_token, recipient_id, subscribe } = doc.data();
                pagetoken[recipient_id] = page_token;
                let addDate = moment(addedon).format("DD MMM YYYY hh:mm A");
                pageList.push({
                    key: doc.id,
                    addedon: addDate,
                    page_category,
                    page_name,
                    page_token,
                    recipient_id,
                    subscribe,
                    facToLivConnect: doc.data().facToLivConnect ? doc.data().facToLivConnect : false,
                    liveToFacConnect: doc.data().liveToFacConnect ? doc.data().liveToFacConnect : false
                });
            });

            if (Object.keys(pagetoken).length > 0) {
                var loggedInAgent = this.agent;
                loggedInAgent.fb_page_token = pagetoken;
                this.agent = loggedInAgent;
                localStorage.setItem('agent', JSON.stringify(loggedInAgent));
            }

            this.setState({ page_token: pagetoken, pageList: pageList });
        });
    }

    getInstaPages = () => {
        this.state.instaSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('insta_pages_connect').onSnapshot(async (querySnapshot) => {
            let pageList = [];
            let pagetoken = {};
            querySnapshot.forEach((doc) => {
                const { addedon, page_category, page_name, page_token, recipient_id, subscribe } = doc.data();
                pagetoken[recipient_id] = page_token;
                let addDate = moment(addedon).format("DD MMM YYYY hh:mm A");
                pageList.push({
                    key: doc.id,
                    addedon: addDate,
                    page_category,
                    page_name,
                    page_token,
                    recipient_id,
                    subscribe,
                    facToLivConnect: doc.data().facToLivConnect ? doc.data().facToLivConnect : false,
                    liveToFacConnect: doc.data().liveToFacConnect ? doc.data().liveToFacConnect : false,
                    connectType: 'insta'
                });
            });

            if (Object.keys(pagetoken).length > 0) {
                var loggedInAgent = this.agent;
                loggedInAgent.fb_page_token = pagetoken;
                this.agent = loggedInAgent;
                localStorage.setItem('agent', JSON.stringify(loggedInAgent));
            }

            this.setState({ instaPageList: pageList });
        });
    }

    connectDisconnectPage = (pageDetail) => {
        if (pageDetail.subscribe) {
            this.disconnectPage(pageDetail);
        } else {
            this.connectPage(pageDetail);
        }
    };

    disconnectPage = async (pageDetail) => {
        console.log(pageDetail);
        const { key, facToLivConnect, liveToFacConnect, connectType } = pageDetail;
        this.setState({ loader: true, noLinkedAccount: false });
        const unsubs = await unsubscribePage(this.agent.ownerId, key, facToLivConnect, liveToFacConnect, connectType);
        if (unsubs && unsubs.status === 200) {

        } else {
            toast.error("Something went wrong, please try again after some time!");
        }
        this.setState({ loader: false });
    }

    connectPage = async (pageDetail) => {
        const { key, facToLivConnect, liveToFacConnect, connectType, page_name } = pageDetail;
        this.setState({ loader: true, noLinkedAccount: false });
        const subsc = await subscribePage(this.agent.ownerId, key, facToLivConnect, liveToFacConnect, connectType, page_name);
        if (subsc && subsc.status === 200) {
            this.setState({ loader: false });
        } else {
            let alertMsg = subsc.msg ? subsc.msg : "Something went wrong, please try again after some time!";
            if (alertMsg === "In order to get connected, you need to first link your Instagram account with your Facebook page") {
                alertMsg = "";
                this.setState({
                    noLinkedAccount: true,
                    loader: false
                })
            } else {
                toast.error(alertMsg);
                this.setState({
                    noLinkedAccount: "",
                    loader: false
                })
            }
        }
    }

    /* Facebook functions end */

    getIntigrateUrl = async (app, installed) => {
        if (installed === true) {
            return false;
        }
        this.setState({ loader: true })
        const { appId, integrationUrl } = app;
        const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails) {
            const intigrateUrl = await getIntigrationUrl(appId, integrationUrl, userDetails.ownerEmail, userDetails.ownerId, userDetails.organization_alias);
            if (intigrateUrl.success === true && intigrateUrl.data.url) {
                let url = intigrateUrl.data.url;
                var newWindow = window.open(url, 'popup', 'width=600,height=600');
                if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
                    this.setState({
                        loader: false
                    }, () => {
                        toast.error('Please allow window open for this website');
                    })
                } else {
                    var timer = setInterval(async () => {
                        if (newWindow.closed) {
                            clearInterval(timer);
                            this.setState({
                                loader: false
                            })
                        }
                    }, 500)
                }
            } else {
                this.setState({ loader: false })
                toast.error("Something went wrong, Please try again after sometime!");
            }
        } else {
            this.setState({ loader: false })
            toast.error("Something went wrong, please try again after some time");
        }
    }

    removeApp = async (appUrlName) => {
        const isDeleted = await deletedConnectedApp(this.agent.ownerId, appUrlName);
        console.log("remove-->", appUrlName, "--> response ->", isDeleted);
    }

    connectApp = async (app) => {
        console.log(app,"APp to connect")
        this.setState({
            appToConnect: app,
            showType: 30
        })
    }

    connectApp_OLD = async (app) => {
        this.setState({
            loader: true
        })
        const preferedMatches = await getMatches(app.appId, this.agent.ownerEmail);
        console.log('preferedMatches >>>', preferedMatches);
        if (preferedMatches.status === 200) {
            if (preferedMatches.data.length > 0) {
                this.setState({
                    appToConnect: app,
                    showType: 30,
                    allMatches: preferedMatches.data,
                    loader: false
                })
            } else {
                this.setState({
                    appToConnect: {},
                    allMatches: [],
                    loader: false
                }, () => {
                    toast.error("Something went wrong, Please try again after some time!");
                })
            }
        }
    }

    connectAppWithConnect = async (apptoConnect, matchId) => {
        // const { appId } = this.state.appToConnect;
        // return false;
        const { seoLink, appType, desc, appId, displayName, integrationUrl } = apptoConnect
        const appConnect = await connectApps(this.agent.ownerId, matchId);
        if (appConnect.status === 200) {
            const addFields = { ...apptoConnect, ...appConnect.data };
            firebaseServices.db.collection("users").doc(this.agent.ownerId).collection('connectedApp').add(addFields)
                .then((res) => {

                })
        }
        console.log(appConnect);
    }

    uninstallApp = async (app) => {
        this.setState({ loader: true })
        var appSeoLink = app.seoLink;
        if (app.seoLink === "whatsapp-business-messenger") {
            appSeoLink = "whatsappbusinessmessenger";
        }
        const fieldName = appSeoLink + '_status';
        firebaseServices.db.collection("users").doc(this.agent.ownerId).update({
            [fieldName]: 0
        })
        // .then(() => {
        if (app.seoLink === "whatsapp-business-messenger") {
            try {
                let connectRef = await firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("connectedApps").doc(appSeoLink).collection("connectType").doc("whatsappToLivechat").collection("connects").get();
                let connectRef2 = await firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("connectedApps").doc(appSeoLink).collection("connectType").doc("livechatToWhatsapp").collection("connects").get();

                // connectRef.forEach((doc) => {
                //     deleteConnect(this.agent.ownerId, doc.data().connectId);
                // })

                // connectRef2.forEach((doc) => {
                //     deleteConnect(this.agent.ownerId, doc.data().connectId);
                // })

                firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("connectedApps").doc(appSeoLink).collection("connectType").doc("whatsappToLivechat").collection("connects").get().then(querySnapshot => {
                    querySnapshot.docs.forEach(snapshot => {
                        snapshot.ref.delete();
                    })
                })

                firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("connectedApps").doc(appSeoLink).collection("connectType").doc("livechatToWhatsapp").collection("connects").get().then(querySnapshot => {
                    querySnapshot.docs.forEach(snapshot => {
                        snapshot.ref.delete();
                    })
                })

                this.removeApp(appSeoLink);

                firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("connectedApps").doc(appSeoLink).delete();
                this.setState({ loader: false }, () => {
                    toast.success(`You have succesfully uninstalled ${app.displayName}`);
                })

            }
            catch (err) {
                console.log('Error ->', JSON.stringify(err));
                this.setState({ loader: false }, () => {
                    toast.success(`Something went wrong, Please try again after some time`);
                })
            }
        }
        else {
            firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("connectedApps").doc(appSeoLink).get().then((docs) => {
                if (docs.exists) {
                    firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("connectedApps").doc(appSeoLink).delete();
                    deleteConnect(this.agent.ownerId, docs.data().connectId);
                    this.setState({ loader: false }, () => {
                        toast.success(`You have succesfully uninstalled ${app.displayName}`);
                    })
                } else {
                    this.setState({ loader: false }, () => {
                        toast.success(`Something went wrong, Please try again after some time`);
                    })
                }
            })
        }
        // })
    }

    diconnectApp = async (app) => {
        this.setState({ loader: true, noLinkedAccount: false })
        const disConnectFb = await unInstallConnectedApp(this.agent.ownerId, app.seoLink);
        if (disConnectFb.status === 200) {
            this.setState({
                showType: 0,
                loader: false
            }, () => {
                let succesMsg = app.seoLink === "instagram-for-business" ? "You have successfully disconnected your Instagram account" : "You have successfully disconnected your Facebook account";
                toast.success(succesMsg);
            })
        } else {
            this.setState({
                showType: 0,
                loader: false
            }, () => {
                toast.error("Something went wrong, Please try again after some time!");
            })
        }
    }

    /* Instagram functions start */

    installInstaMessenger = () => {
        let domain_name = DOMAIN_NAME;
        if (window.location.hostname === "localhost") {
            domain_name = "http://localhost:3000";
        }
        let cbUrl = domain_name + "/cb-insta-messenger/pagesetup";
        let state22 = Math.random().toString(36).substring(7);
        let params = `scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=900,height=500,left=100,top=100`;
        /*  window.open("https://www.facebook.com/v" + API_VERSION + "/dialog/oauth?response_type=code&client_id=" + FB_APP_ID + "&redirect_uri=" + cbUrl + "&state=" + state22 + "&scope=pages_messaging,pages_show_list,instagram_basic,instagram_manage_messages,public_profile,instagram_manage_insights,instagram_manage_comments,email,pages_read_engagement,pages_manage_metadata", "Instagram Messenger", params); */
        window.open("https://www.facebook.com/v" + API_VERSION + "/dialog/oauth?response_type=code&client_id=" + FB_APP_ID + "&redirect_uri=" + cbUrl + "&state=" + state22 + "&scope=pages_messaging,pages_show_list,instagram_basic,instagram_manage_messages,public_profile,instagram_manage_insights,instagram_manage_comments,email,pages_read_engagement,pages_manage_metadata", "Instagram Messenger", params);
    }

    connectDisconnectInstaPage = (pageDetail) => {
        if (pageDetail.subscribe) {
            this.disconnectInstagramPage(pageDetail);
        } else {
            this.setState({ loader: true });
            let pageToken = pageDetail.page_token ? encodeURIComponent(pageDetail.page_token) : "";
            let body22 = [`ownerId=${this.agent.ownerId}&pageId=${pageDetail.recipient_id}&pageToken=${pageToken}`];
            //fetch(MAIL_FUNCTION_URL + "/facebook/ig_connect", {
            fetch(GATEWAY_URL + "/ig_connect", {
                method: "POST",
                body: body22,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json()).then(async (response) => {
                console.log("-----------------   response  ---------------------");
                console.log(response);
                this.setState({ loader: false });
                if (response.status === "error") {
                    toast.error(response.message);
                }
            });
        }
    };

    getInstagramPages = () => {
        this.instaPagesSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('instagram_pages').onSnapshot(async (querySnapshot) => {
            let instaPagesList = [];
            let pagetoken = {};
            querySnapshot.forEach((doc) => {
                const { addedon, page_category, page_name, page_token, recipient_id, subscribe } = doc.data();
                pagetoken[recipient_id] = page_token;
                let addDate = moment(addedon).format("DD MMM YYYY hh:mm A");
                instaPagesList.push({
                    key: doc.id,
                    addedon: addDate,
                    page_category,
                    page_name,
                    page_token,
                    recipient_id,
                    subscribe
                });
            });

            if (Object.keys(pagetoken).length > 0) {
                var loggedInAgent = this.agent;
                loggedInAgent.insta_pages_token = pagetoken;
                this.agent = loggedInAgent;
                localStorage.setItem('agent', JSON.stringify(loggedInAgent));
            }

            this.setState({ instaPagesList: instaPagesList }, () => {
                this.setState({ loader: false });
            });
        });
    }

    deleteInstaPage = () => {
        this.setState({ loader: true });
        let body22 = [`ownerId=${this.agent.ownerId}`];
        //fetch(MAIL_FUNCTION_URL + "/facebook/ig_delete", {
        fetch(GATEWAY_URL + "/ig_delete", {
            method: "POST",
            body: body22,
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(async (response) => {
            if (response.status === "success") {
                let agent22 = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
                agent22.insta_pages_token = {};
                this.agent = agent22;
                localStorage.setItem('agent', JSON.stringify(agent22));
                this.setState({ loader: false });
            } else {
                this.setState({ loader: false });
            }
        });
    }

    disconnectInstagramPage = (pageDetail) => {
        this.setState({ loader: true });
        let recipientId = pageDetail.recipient_id;
        let pageToken = pageDetail.page_token ? encodeURIComponent(pageDetail.page_token) : "";
        let body22 = [`ownerId=${this.agent.ownerId}&pageId=${recipientId}&pageToken=${pageToken}`];
        //fetch(MAIL_FUNCTION_URL + "/facebook/ig_disconnect", {
        fetch(GATEWAY_URL + "/ig_disconnect", {
            method: "post",
            body: body22,
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(async (response) => {
            this.setState({ loader: false });
        });
    }

    /* Instagram functions end */

    /* Twitter functions start */

    getTwitterTokens = () => {
        let tdmFrmData = { owner_id: this.agent.ownerId };
        //fetch(MAIL_FUNCTION_URL + "/twitter/gettokens", {
        fetch(GATEWAY_URL + "/gettokens", {
            method: "POST",
            body: JSON.stringify(tdmFrmData),
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Accept": 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === "success") {
                    if (Object.keys(responseJson.allTokens).length > 0) {
                        this.setState({ twitterTokens: responseJson.allTokens, consumer_key: responseJson.allTokens.consumer_key, consumer_secret: responseJson.allTokens.consumer_secret, access_token: responseJson.allTokens.access_token, access_secret: responseJson.allTokens.access_secret, loader: false });
                    }
                }
            });
    }

    handleTDMChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    

    handleTDMFormSubmit = (e) => {
        e.preventDefault();

        let error_consumer_key = "";
        let error_consumer_secret = "";
        let error_access_token = "";
        let error_access_secret = "";

        if (this.state.consumer_key === "") {
            error_consumer_key = "Please enter consumer key!";
        }

        if (this.state.consumer_secret === "") {
            error_consumer_secret = "Please enter consumer secret!";
        }

        if (this.state.access_token === "") {
            error_access_token = "Please enter account token!";
        }

        if (this.state.access_secret === "") {
            error_access_secret = "Please enter account secret!";
        }

        if (error_consumer_key !== "" || error_consumer_secret !== "" || error_access_token !== "" || error_access_secret !== "") {
            this.setState({ error_consumer_key: error_consumer_key, error_consumer_secret: error_consumer_secret, error_access_token: error_access_token, error_access_secret: error_access_secret, error_twitter_dm: "", success_twitter_dm: "" });
            return false;
        }

        this.setState({ loader: true });
        let tdmFrmData = { owner_id: this.agent.ownerId, consumer_key: encodeURIComponent(this.state.consumer_key), consumer_secret: encodeURIComponent(this.state.consumer_secret), access_token: encodeURIComponent(this.state.access_token), access_secret: encodeURIComponent(this.state.access_secret) };
        //fetch(MAIL_FUNCTION_URL + "/twitter/subscribe", {
        fetch(GATEWAY_URL + "/subscribe", {
            method: "POST",
            body: JSON.stringify(tdmFrmData),
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Accept": 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === "success") {
                    this.setState({ error_twitter_dm: "", success_twitter_dm: "Live Chat now connected to Twitter.", error_consumer_key: "", error_consumer_secret: "", error_access_token: "", error_access_secret: "" });
                } else {
                    this.setState({ loader: false, error_twitter_dm: "Something went wrong. please check details once and submit again!", success_twitter_dm: "", error_consumer_key: "", error_consumer_secret: "", error_access_token: "", error_access_secret: "" });
                }
                setTimeout(() => {
                    this.getTwitterTokens();
                }, 2000);
            });

    }


    disconnectTwitterDm = (e) => {

        this.setState({ loader: true });
        let tdmFrmData = { owner_id: this.agent.ownerId, consumer_key: encodeURIComponent(this.state.consumer_key), consumer_secret: encodeURIComponent(this.state.consumer_secret), access_token: encodeURIComponent(this.state.access_token), access_secret: encodeURIComponent(this.state.access_secret) };

        //fetch(MAIL_FUNCTION_URL + "/twitter/disconnectTDM", {
        fetch(GATEWAY_URL + "/disconnectTDM", {
            method: "POST",
            body: JSON.stringify(tdmFrmData),
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Accept": 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === "success") {
                    this.setState({ twitterTokens: null, consumer_key: "", consumer_secret: "", access_token: "", access_secret: "", loader: false, error_twitter_dm: "", success_twitter_dm: "Live Chat disconnected with twitter successfully.", error_consumer_key: "", error_consumer_secret: "", error_access_token: "", error_access_secret: "" });
                } else {
                    this.setState({ loader: false, error_twitter_dm: responseJson.message, success_twitter_dm: "", error_consumer_key: "", error_consumer_secret: "", error_access_token: "", error_access_secret: "" });
                }
            });
    }

    /* Twitter functions end */


    /* Telegram, functions start */

    getTeleTokens = () => {
        let tdmFrmData = { owner_id: this.agent.ownerId };
        fetch(MAIL_FUNCTION_URL + "/telegram/get_token", {
            // fetch("http://localhost:5001/livechat-production/us-central1/telegram/get_token",{
        // fetch(GATEWAY_URL + "/gettokens", {
            method: "POST",
            body: JSON.stringify(tdmFrmData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.username) {
                        this.setState({ teleToken: responseJson.TeleToken,teleUser:responseJson.username,loader: false });
                }
            });
    }

    handleTeleChange = (e) => {
        this.setState({ teleToken: e.target.value });
    }


    handleTeleFormSubmit = (e) => {
        e.preventDefault();

        let error_teleToken = "";
      

       

        if (this.state.teleToken === "" ) {
            error_teleToken = "Please enter Bot Token!";
            this.setState({ error_teleToken: error_teleToken});
            return false;

        }else if (!this.state.teleToken.includes(":") ) {
            error_teleToken = "Please enter correct Bot Token!";
            this.setState({ error_teleToken: error_teleToken});
            return false;

        }



        this.setState({ loader: true });
        var raw = JSON.stringify({
            "bId": this.state.teleToken.split(":")[0],
            "token": this.state.teleToken.split(":")[1],
            "owner_id":this.agent.ownerId,
            "agentId":this.agent.agentId,
          });
          
          var requestOptions = {
            method: 'POST',
            headers:  {'Content-Type': 'application/json'},
            body: raw,
            redirect: 'follow'
          };
          fetch(MAIL_FUNCTION_URL + "/telegram/setup_webhook", requestOptions)
        // fetch("http://localhost:5001/livechat-production/us-central1/telegram/setup_webhook", requestOptions)
            // .then(response => response.text())
            // .then(result => console.log(result))
            // .catch(error => console.log('error', error));

        // fetch(GATEWAY_URL + "/subscribe", {
        //     method: "POST",
        //     body: JSON.stringify(tdmFrmData),
        //     headers: {
        //         "X-API-Key": FUNCTION_API_KEY,
        //         "Accept": 'application/x-www-form-urlencoded',
        //         'Content-Type': 'application/json'
        //     }
        // })
        .then((response) => response.json())
            .then((responseJson) => {
                
                if (responseJson.username) {
                    var userdata=JSON.parse(localStorage.getItem('agent'))
                    console.log(userdata,'PREVERIFY')
                    userdata["TeleToken"]=this.state.teleToken
                    localStorage.setItem("agent",JSON.stringify(userdata))
                    this.agent=userdata
                    
                    this.setState({ loader: false,error_teleToken: "",teleUser:responseJson.username, success_teleToken: `Live Chat now connected to Telegram.You can test by texting on @${responseJson.username}.`});
                } else {
                    this.setState({ loader: false, error_teleToken: "Something went wrong. please check details once and submit again!" });
                }
                // setTimeout(() => {
                //     this.getTeleTokens();
                // }, 2000);
            });

    }

    disconnectTelegram = (e) => {

        this.setState({ loader: true });
        let tdmFrmData = { owner_id: this.agent.ownerId };

        fetch(MAIL_FUNCTION_URL + "/telegram/disconnect", {
        // fetch(GATEWAY_URL + "/disconnectTDM", {
            method: "POST",
            body: JSON.stringify(tdmFrmData),
            headers: {
               
               
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson,responseJson.status,"responseJsonTELEDISCONNCECT")
                if (responseJson.status === "success") {  
                    this.setState({ teleToken: null, loader: false, error_teleToken: "", success_teleToken: "Live Chat disconnected with telegram successfully.", error_consumer_key: "", error_consumer_secret: "", error_access_token: "", error_access_secret: "",teleUser:"" });
                    document.getElementById('consumer_key_telegram').value = ''
                } else {
                    this.setState({ loader: false, error_teleToken: "Failed", success_teleToken: "" });
                }
            });
    }

    /* Telegram functions end */


     /* Whatsapp, functions start */

     getWhatsappDeploy = () => {
        // let tdmFrmData = { owner_id: this.agent.ownerId };
        // fetch(MAIL_FUNCTION_URL + "/whatsapp/get_token", {
        //     // fetch("http://localhost:5001/livechat-production/us-central1/telegram/get_token",{
        // // fetch(GATEWAY_URL + "/gettokens", {
        //     method: "POST",
        //     body: JSON.stringify(tdmFrmData),
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // })
        // .then((response) => response.json())
        // firebaseServices.db.collection("users").doc(this.agent.ownerId).get()
        //     .then((responseJson) => {
            console.log(this.agent.whatsapp,"this.agent.whatsapp")
                if(this.agent.whatsapp){
                    if (this.agent.whatsapp.whatsappAccessToken&&this.agent.whatsapp.whatsappAccessId&&this.agent.whatsapp.whatsappPhoneId) {
                            
                            this.setState({ whatsappAccessToken: this.agent.whatsapp.whatsappAccessToken,whatsappAccessId:this.agent.whatsapp.whatsappAccessId,whatsappPhoneId:this.agent.whatsapp.whatsappPhoneId,whatsappUser:true });
                    }
                }
            // });
    }

    handleWhatsappChange = (e) => {
        console.log(e.target.id,e.target.value,"ONTYPEWHATSAPP")
        if(e.target.id=="whatsapp_token"){
            this.setState({ whatsappAccessToken: e.target.value });
          }else if (e.target.id=="whatsapp_bus_id"){
            this.setState({ whatsappAccessId: e.target.value });
          }else{
            this.setState({ whatsappPhoneId: e.target.value });
          }
    }


    handleWhatsappFormSubmit = async (e) => {
        e.preventDefault();

        let error_teleToken = "";
      

       

        if (this.state.whatsappAccessToken === ""||this.state.whatsappAccessId==""||this.state.whatsappPhoneId=="" ) {
            error_teleToken = "Please enter Bot Token!";
            this.setState({ error_whatsappToken: error_teleToken});
            return false;

        }
        let data={
            "whatsappAccessToken":this.state.whatsappAccessToken,
            "whatsappAccessId":this.state.whatsappAccessId,
            "whatsappPhoneId":this.state.whatsappPhoneId
        }



        this.setState({ loader: true });
        await firebaseServices.db.collection('Whatsapp_Integrations').doc(this.state.whatsappAccessId).set({
        
            isActive: true,
            owner_id:this.agent.ownerId,
            "whatsappAccessToken":this.state.whatsappAccessToken,
            "whatsappAccessId":this.state.whatsappAccessId,
            "whatsappPhoneId":this.state.whatsappPhoneId

        })
        firebaseServices.db.collection('Whatsapp_Integrations').doc(this.state.whatsappAccessId).collection("Visitor").get()
        .then(docs=>{
          
          docs.docs.forEach(element => {
            firebaseServices.db.collection('Whatsapp_Integrations').doc(this.state.whatsappAccessId).collection("Visitor").doc(element.id).delete()
            
          
        })
      }).catch(err=>{
          console.log(err,"LENGTH")
        })
        firebaseServices.db.collection("users").doc(this.agent.ownerId).update({"whatsapp":data})

          
       
            .then((responseJson) => {
                
                // if (responseJson) {
                    var userdata=JSON.parse(localStorage.getItem('agent'))
                    console.log(userdata,'PREVERIFY')
                    userdata["whatsapp"]=data
                    localStorage.setItem("agent",JSON.stringify(userdata))
                    this.agent=userdata
                    
                    this.setState({ loader: false,error_whatsappToken: "",whatsappUser:true, success_whatsappToken: `Live Chat now connected to Whatsapp.You can test by texting on your number.`});
                // } else {
                //     this.setState({ loader: false, error_whatsappToken: "Something went wrong. please check details once and submit again!",success_whatsappToken:"" });
                // }
                // setTimeout(() => {
                //     this.getTeleTokens();
                // }, 2000);
            });

    }

    disconnectWhatsapp = (e) => {

        this.setState({ loader: true });
        // let tdmFrmData = { owner_id: this.agent.ownerId };
        firebaseServices.db.collection("users").doc(this.agent.ownerId).update({"whatsapp":""})
        
            .then(async (responseJson) => {
                await firebaseServices.db.collection('Whatsapp_Integrations').doc(this.agent.ownerId).delete()
                firebaseServices.db.collection('Whatsapp_Integrations').doc(this.agent.ownerId).collection("Visitor").get()
                .then(docs=>{
                  
                  docs.docs.forEach(element => {
                    firebaseServices.db.collection('Whatsapp_Integrations').doc(this.agent.ownerId).collection("Visitor").doc(element.id).delete()
                    
                  
                })

                    var userdata=JSON.parse(localStorage.getItem('agent'))
                    console.log(userdata,'PREVERIFY')
                    delete userdata.whatsapp
                    localStorage.setItem("agent",JSON.stringify(userdata))
                    this.agent=userdata
                this.setState({ teleToken: null, loader: false,whatsappAccessId:"",whatsappAccessToken:"",whatsappPhoneId:"", error_whatsappToken: "", success_whatsappToken: "Live Chat disconnected with Whatsapp successfully.", error_consumer_key: "", error_consumer_secret: "", error_access_token: "", error_access_secret: "",whatsappUser:"" });
                document.getElementById('whatsapp_token').value = ''
                document.getElementById('whatsapp_bus_id').value = ''
                document.getElementById('whatsapp_num_id').value = ''
            })
                
               
                   

               
            });
    }

    /* Whatsapp functions end */

    render() {

        return (
            <>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
              
              {this.state.payment_status ? <> <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                    <h3 className="heding-text">All Integrations</h3>
                    <div className="mobile-menu ml-auto" onClick={this.sideMenuePhone}>
                        <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                    </div>
                </header>
               
                {this.state.setup_guide === false && this.state.setup_guide_step < 3 && this.state.showType === 2 && !this.state.openEmailBox ? (<div className="overlay-guide"></div>) : (null)}
                <ReactTooltip />
                <div className="page-wrapper" data-mobile-height="">
                    {/* Main HTML Start */}
                   <div className="main-wrapper container-fluid">
                        <div className="chatform-Preview bg-white mt-1">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 borderRight">
                                        <div className="chat-forms py-5 px-5 position-relative">
                                            {this.state.showType === 30 ? (<div className="sidePopup">
                                                <div className="sidePopupBody" style={{ overflowY: "scroll" }}>
                                                    <div className="popHead">
                                                        <h5>{this.state.appToConnect.displayName}</h5>
                                                        <span className="cross" onClick={() => { this.setState({ showType: 0, noLinkedAccount: false }) }}>
                                                            <img src={require("../../assets/img/icon/right-panel-crose.svg")} />
                                                        </span>
                                                    </div>
                                                    <div className="contentPop d-flex setting-section">
                                                        <div className="contentImg">
                                                            <img src={imageURL + this.state.appToConnect.image} />
                                                        </div>
                                                        <div className="content-detail pl-5">
                                                            <h5>{this.state.appToConnect.displayName}</h5>
                                                            <p>{this.state.appToConnect.desc}</p>
                                                            <br />
                                                            {this.state.appToConnect.appId === "janrt1tf08q0aa8ded8o4z48" || this.state.appToConnect.appId === "jvgl0d4fff345b00ypzx" ? (<div className="fb-btn">
                                                                <button onClick={() => this.diconnectApp(this.state.appToConnect)}><span><img src={require('../../assets/img/icon/link-symbol.svg')} /></span>Disconnect</button>
                                                            </div>) : (null)}

                                                        </div>
                                                    </div>

                                                    {this.state.appToConnect.appId === "janrt1tf08q0aa8ded8o4z48" && this.state.pageList.length > 0 ? (
                                                        <table className="table mt-10 fb-tbl-view">
                                                            <thead>
                                                                <tr>
                                                                    <th>Page Name</th>
                                                                    {/* <th>Page Category</th>
                                                                    <th>Page Id</th>
                                                                    <th>Added Date</th> */}
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.pageList.map((pageDet, indx) => {
                                                                    return <tr key={"keyy_" + indx}>
                                                                        <td>{pageDet.page_name}</td>
                                                                        {/* <td>{pageDet.page_category}</td>
                                                                        <td>{pageDet.recipient_id}</td>
                                                                        <td>{pageDet.addedon}</td> */}
                                                                        <td>
                                                                            <div className="custom-switch">
                                                                                <input type="checkbox" checked={pageDet.subscribe ? "checked" : ""} onChange={() => this.connectDisconnectPage(pageDet)} className="custom-control-input" id={"switch_" + pageDet.recipient_id} name="connectdisconnect" />
                                                                                <label className="custom-control-label cursor-pointer" htmlFor={"switch_" + pageDet.recipient_id}></label>
                                                                                <span>{pageDet.subscribe ? "Connected" : "Not Connected"}</span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    ) : (null)}

                                                    {this.state.appToConnect.appId === "jvgl0d4fff345b00ypzx" && this.state.instaPageList.length > 0 ? (
                                                        <>
                                                            {this.state.noLinkedAccount ? (<p className='error' style={{ padding: '15px' }}>In order to get connected, you need to first link your Instagram account with your Facebook page. <a href='https://www.facebook.com/help/1148909221857370' target="_blank">help?</a></p>) : (null)}
                                                            <table className="table fb-tbl-view">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Page Name</th>
                                                                        {/* <th>Page Category</th>
                                                                    <th>Page Id</th>
                                                                    <th>Added Date</th> */}
                                                                        <th>Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.instaPageList.map((pageDet, indx) => {
                                                                        return <tr key={"keyy_" + indx}>
                                                                            <td>{pageDet.page_name}</td>
                                                                            {/* <td>{pageDet.page_category}</td>
                                                                        <td>{pageDet.recipient_id}</td>
                                                                        <td>{pageDet.addedon}</td> */}
                                                                            <td>
                                                                                <div className="custom-switch">
                                                                                    <input type="checkbox" checked={pageDet.subscribe ? "checked" : ""} onChange={() => this.connectDisconnectPage(pageDet)} className="custom-control-input" id={"switch_" + pageDet.recipient_id} name="connectdisconnect" />
                                                                                    <label className="custom-control-label cursor-pointer" htmlFor={"switch_" + pageDet.recipient_id}></label>
                                                                                    <span>{pageDet.subscribe ? "Connected" : "Not Connected"}</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </>
                                                    ) : (null)}
                                                </div>
                                            </div>) : (null)}

                                            <div className="setup-section">
                                                <div className="setup-list-design pt-5 integration-card">
                                                    {/* <h5>Appointments</h5> */}
                                                    <ul className="title-card" style={{ width: '100%' }}>
                                                        {/* <li key={'TwitterDM'} style={{ cursor: 'pointer' }}>
                                                            <span className="k-flex flex-wrap InstalledApp">
                                                                <span className="social-img heightEqual">
                                                                    <svg viewBox="0 0 24 24" aria-hidden="true" className="install-twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg>
                                                                    <span>Twitter</span>
                                                                    <button className='titleInstall' onClick={() => { this.setState({ showType: 20, error_consumer_key: "", error_consumer_secret: "", error_access_token: "", error_access_secret: "" }) }}>Install</button>
                                                                </span></span>
                                                        </li> */}
                                                        {/* <li key={'InstagramDM'} style={{ cursor: 'pointer' }}>
                                                            <span className="k-flex flex-wrap InstalledApp">
                                                                <span className="social-img">
                                                                    <img src={require('../../assets/images/instagram.svg')} />
                                                                    <span>Instagram</span>
                                                                    <button className='titleInstall' onClick={() => { this.setState({ showType: 22 }) }}>Install</button>
                                                                </span></span>
                                                        </li> */}
                                                        <li key={'Telegram'} style={{ cursor: 'pointer' }}>
                                                            <span className="k-flex flex-wrap InstalledApp" >
                                                                
                                                                <span className="social-img">
                                                                    <img src={"https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"} />
                                                                    <span>Telegram</span>
                                                                    <button className='titleInstall' onClick={() => { this.setState({ showType: 24 }) }}>{this.state.teleUser?"Edit":"Install"}</button>
                                                                    {this.state.teleUser ? (<p>Installed</p>) : (null)}
                                                                </span></span>
                                                        </li>
                                                        <li key={'Whatsapp'} style={{ cursor: 'pointer' }}>
                                                            <span className="k-flex flex-wrap InstalledApp" >
                                                                
                                                                <span className="social-img">
                                                                    <img src={require('../../assets/images/whatsapp.svg')} />
                                                                    <span>Whatsapp</span>
                                                                    <button className='titleInstall' onClick={() => { this.setState({ showType: 25 }) }}>{this.state.whatsappUser?"Edit":"Install"}</button>
                                                                    {this.state.whatsappUser ? (<p>Installed</p>) : (null)}
                                                                </span></span>
                                                        </li>
                                                        {console.log("this.state.allApps", this.state.allApps)}
                                                        {this.state.allApps.map((app, index) => {
                                                            const appImage = imageURL + app.image;
                                                            const filteredApp = this.state.installedApp.filter(iApp => iApp.appId === app.appId);
                                                            let appStatus = app.seoLink.replace("-","") + '_status';
                                                            // if (app.seoLink === 'facebook-messenger') {
                                                            //     appStatus = "facebookmessenger_status";
                                                            // } else if (app.seoLink === 'instagram-for-business') {
                                                            //     appStatus = "instagrambusiness_status";
                                                            // }
                                                            if (app.seoLink === "whatsapp-business-messenger") {
                                                                appStatus = "whatsappbusinessmessenger_status";
                                                            }

                                                            let installed = this.state.allUserData[appStatus] === 1 ? true : false;
                                                            console.log("installed APP",appStatus, installed)
                                                            this.updateLocalStorage(appStatus, installed)
                                                            if (app.seoLink!="livechat") {
                                                                return (<li key={index} style={{ cursor: 'pointer' }}>
                                                                    <span className="k-flex flex-wrap InstalledApp connect">
                                                                        <span className="social-img">
                                                                            <img src={appImage} />
                                                                            <span>{app.displayName}</span>
                                                                            { installed ? (
                                                                                (app.seoLink === "zoom" || app.seoLink === "whatsapp-business-messenger") ?
                                                                                    <button className="titleInstall" onClick={() => this.uninstallApp(app)}>Uninstall</button> :
                                                                                    <button className="titleInstall connect" onClick={() => this.connectApp(app)}>Connect</button>
                                                                            ) : (<button className="titleInstall" onClick={() => this.getIntigrateUrl(app)}>Install</button>)}
                                                                            { installed ? (<p>Installed</p>) : (null)}
                                                                        </span>
                                                                    </span>
                                                                </li>)
                                                            } else {
                                                                return null;
                                                            }

                                                        })}
                                                        {/* <li onClick={() => this.setState({ showType: 6 })}>
                                                            <span className="k-flex flex-wrap InstalledApp">
                                                                <span className="social-img">
                                                                    <img src={require('../../assets/img/icon/appypie-livechat.svg')} />
                                                                    <span>Appy Pie Livechat</span>
                                                                    <button className="titleInstall">Install</button>
                                                                    <p className>Installed</p>
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li onClick={() => this.setState({ showType: 6 })}>
                                                            <span className="k-flex flex-wrap">
                                                                <span className="social-img">
                                                                    <img src={require('../../assets/img/icon/calendly.svg')} />
                                                                    <span>Calendly</span>
                                                                    <button className="titleInstall">Install</button>
                                                                    <p className>Installed</p>
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li onClick={() => this.setState({ showType: 21 })}>
                                                            <span className="k-flex flex-wrap">
                                                                <span className="social-img">
                                                                    <img src={require('../../assets/img/icon/zoom.svg')} />
                                                                    <span>Zoom</span>
                                                                    <button className="titleInstall">Install</button>
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li onClick={() => this.setState({ showType: 6 })}>
                                                            <span className="k-flex flex-wrap">
                                                                <span className="social-img">
                                                                    <img src={require('../../assets/img/icon/whatsapp.svg')} />
                                                                    <span>VoIP Calling</span>
                                                                    <button className="titleInstall">Install</button>
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li onClick={() => this.setState({ showType: 6 })}>
                                                            <span className="k-flex flex-wrap">
                                                                <span className="social-img">
                                                                    <img src={require('../../assets/img/icon/whatsapp.svg')} />
                                                                    <span>VoIP Calling</span>
                                                                    <button className="titleInstall">Install</button>
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li onClick={() => this.setState({ showType: 6 })}>
                                                            <span className="k-flex flex-wrap">
                                                                <span className="social-img">
                                                                    <img src={require('../../assets/img/icon/whatsapp.svg')} />
                                                                    <span>VoIP Calling</span>
                                                                    <button className="titleInstall">Install</button>
                                                                </span>
                                                            </span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                                {/* <div className="setup-list-design pt-5">
                                                    <h5>Social Media</h5>
                                                    <ul className="title-card" style={{ width: '100%' }}>
                                                        <li onClick={() => this.setState({ showType: 7 })} style={{ cursor: 'pointer' }}>
                                                            
                                                            <span className="k-flex flex-wrap">
                                                                <span className="social-img">
                                                                    <img src={require('../../assets/images/fb-logo.svg')} />
                                                                    <span>Facebook</span>
                                                                    <button className="titleInstall">Install</button>
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li onClick={() => this.setState({ showType: 20 })} style={{ cursor: 'pointer' }}>
                                                            <span className="k-flex flex-wrap">
                                                                <span className="social-img">
                                                                    <svg viewBox="0 0 20 30" aria-hidden="true" className="install-twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg>
                                                                    <span>Twitter</span>
                                                                    <button className="titleInstall">Install</button>
                                                                </span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Main HTML Ends */}
                    {/* Twitter HTML Start */}
                    {this.state.showType === 20 ? (
                        <div className="main-wrapper container-fluid">
                            <div className='chatform-Preview'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8'>
                                            <span className='text-light-blue d-inline-block cursor-pointer mb-3' onClick={() => { this.setState({ showType: 0 }) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.459" height="10.459" viewBox="0 0 20.459 10.459">
                                                    <path id="ic_arrow_forward_24px" d="M-.771,4l.922.922L-3.5,8.576H14.459V9.883H-3.5L.151,13.537l-.922.922L-6,9.229Z" transform="translate(6 -4)" fill="#0176ff" />
                                                </svg>

                                                &nbsp;Back</span>
                                            <div className='bg-white left-widgetsetings-box mb-foter-Fixed'>
                                                <div className="setting-section pb-1 pb-5">

                                                    <div className="mx-8 d-flex align-items-center">

                                                        <div className="main-heading main-heading-title py-5 ">
                                                            <h3 className="heding-text">Twitter</h3>

                                                        </div>
                                                        <div className='text-right ml-auto'>
                                                            <a href="https://www.appypie.com/faqs/how-to-deploy-livechat-bot-on-twitter" classnames="text-light-blue d-inline-block cursor-pointer" style={{ color: '#0176ff' }} target="_blank" className="link-type"><img src={require('../../assets/img/icon/book.svg')} className="pr-3" />User Guide</a>
                                                        </div>
                                                    </div>

                                                    {this.state.twitterTokens && this.state.twitterTokens.is_subscribe && (<div className="mx-8 pb-5">
                                                        <div className="main-heading main-heading-title py-5">
                                                            <h4>Your Website Is Connected To Twitter.</h4><p>Already connected. Reconnect if you have any trouble.</p>
                                                        </div>
                                                    </div>)}

                                                    {(this.state.success_twitter_dm || this.state.error_twitter_dm) && (<div className="mx-8">
                                                        <div className="main-heading main-heading-title pb-5">
                                                            {this.state.success_twitter_dm && (<span style={{ color: "#1ca91c" }}>{this.state.success_twitter_dm}</span>)}
                                                            {this.state.error_twitter_dm && (<span style={{ color: "#d12828" }}>{this.state.error_twitter_dm}</span>)}
                                                        </div>
                                                    </div>)}

                                                    <form onSubmit={this.handleTDMFormSubmit}>
                                                        <div className="settings-form-feilds mb-8 mx-8">
                                                            <div className="row">
                                                                <div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
                                                                    <TextField className="w-100" id="consumer_key" label="Twitter Consumer Key" variant="outlined" name="consumer_key" value={this.state.consumer_key} onChange={this.handleTDMChange} error={this.state.error_consumer_key ? true : false} helperText={this.state.error_consumer_key ? this.state.error_consumer_key : ""} />
                                                                    {/* <div className="settings-feld k-flex align-items-center mb-2">
                                                                                                        <h6 className="card-title mb-0">Twitter Consumer Key</h6>
                                                                                                    </div>
                                                                                                    <div className="wrapperInfo">
                                                                                                        <input type="text" className="w-100" name="consumer_key" value={this.state.consumer_key} placeholder="Your Twitter Consumer Key" onChange={this.handleTDMChange} />
                                                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="settings-form-feilds mb-8 mx-8">
                                                            <div className="row">
                                                                <div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
                                                                    <TextField className="w-100" id="consumer_secret" label="Twitter Consumer Secret" variant="outlined" name="consumer_secret" value={this.state.consumer_secret} onChange={this.handleTDMChange} error={this.state.error_consumer_secret ? true : false} helperText={this.state.error_consumer_secret ? this.state.error_consumer_secret : ""} />
                                                                    {/* <div className="settings-feld k-flex align-items-center mb-2">
                                                                                                        <h6 className="card-title mb-0">Twitter Consumer Secret</h6>
                                                                                                    </div>
                                                                                                    <div className="wrapperInfo">
                                                                                                        <input type="text" className="w-100" name="consumer_secret" value={this.state.consumer_secret} placeholder="Your Twitter Consumer Secret" onChange={this.handleTDMChange} />
                                                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="settings-form-feilds mb-8 mx-8">
                                                            <div className="row">
                                                                <div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
                                                                    <TextField className="w-100" id="access_token" label="Twitter Account Token" variant="outlined" name="access_token" value={this.state.access_token} onChange={this.handleTDMChange} error={this.state.error_access_token ? true : false} helperText={this.state.error_access_token ? this.state.error_access_token : ""} />
                                                                    {/* <div className="settings-feld k-flex align-items-center mb-2">
                                                                                                        <h6 className="card-title mb-0">Twitter Account Token</h6>
                                                                                                    </div>
                                                                                                    <div className="wrapperInfo">
                                                                                                        <input type="text" className="w-100" name="access_token" value={this.state.access_token} placeholder="Your Twitter Account Token" onChange={this.handleTDMChange} />
                                                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="settings-form-feilds mb-8 mx-8">
                                                            <div className="row">
                                                                <div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
                                                                    <TextField className="w-100" id="access_secret" label="Twitter Account Secret" variant="outlined" name="access_secret" value={this.state.access_secret} onChange={this.handleTDMChange} error={this.state.error_access_secret ? true : false} helperText={this.state.error_access_secret ? this.state.error_access_secret : ""} />
                                                                    {/* <div className="settings-feld k-flex align-items-center mb-2">
                                                                                                        <h6 className="card-title mb-0">Twitter Account Secret</h6>
                                                                                                    </div>
                                                                                                    <div className="wrapperInfo">
                                                                                                        <input type="text" className="w-100" name="access_secret" value={this.state.access_secret} placeholder="Your Twitter Account Secret" onChange={this.handleTDMChange} />
                                                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button className="btnBlueSettings mr-2 mx-8" type="submit">{this.state.twitterTokens && this.state.twitterTokens.is_subscribe ? "Reconnect my Twitter account" : "Connect my Twitter account"}</button>

                                                        {this.state.twitterTokens && this.state.twitterTokens.is_subscribe && (<button className="btnBlueSettings mr-2 mx-8" type="button" onClick={this.disconnectTwitterDm}>Disconnect</button>)}

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (null)}
                    {/* Twitter HTML End */}
                    {/* Instagram HTML Start */}
                    {(this.state.showType === 22) ? (
                        <div className="main-wrapper container-fluid">
                            <div className='chatform-Preview'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8'>
                                            <span className='text-light-blue d-inline-block cursor-pointer mb-3' onClick={() => { this.setState({ showType: 0 }) }}><svg xmlns="http://www.w3.org/2000/svg" width="20.459" height="10.459" viewBox="0 0 20.459 10.459">
                                                <path id="ic_arrow_forward_24px" d="M-.771,4l.922.922L-3.5,8.576H14.459V9.883H-3.5L.151,13.537l-.922.922L-6,9.229Z" transform="translate(6 -4)" fill="#0176ff" />
                                            </svg>

                                                &nbsp;Back</span>
                                            <div className='bg-white left-widgetsetings-box mb-foter-Fixed'>
                                                <div className="pt-5 setup-list-design">
                                                    <div className="setting-section idle-section all-setting-bg-section-feilds pb-1">
                                                        <div className="mx-8">
                                                            <div className="k-flex align-items-center">
                                                                <div className="fb-details">
                                                                    <h2>Instagram</h2>
                                                                    <p>Reply to Instagram messages right from your inbox</p>
                                                                    {/* <div>
                                                                        <a href="#" target="_blank" className="link-type"><img src={require('../../../assets/img/icon/book.svg')} />User Guide</a>
                                                                    </div> */}
                                                                </div>

                                                                <div className="fb-btn">
                                                                    {this.state.instaPagesList.length > 0 ? (<button onClick={this.deleteInstaPage}><span><img src={require('../../assets/img/icon/check-mark.svg')} /></span>Disconnect</button>) : (<button onClick={this.installInstaMessenger}><span><img src={require('../../assets/img/icon/link-symbol.svg')} /></span>Connect</button>)}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {this.state.instaPagesList.length > 0 ? (
                                                            <div className='table-responsive px-8'>
                                                                <table className="table mt-10 table-bordered">
                                                                    <thead className='thead-dark'>
                                                                        <tr>
                                                                            <th>Page Name</th>
                                                                            <th>Page Category</th>
                                                                            <th>Page Id</th>
                                                                            <th>Added Date</th>
                                                                            <th>Status</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.instaPagesList.map((pageDet, indx) => {
                                                                            return <tr key={"keyy_" + indx}>
                                                                                <td>{pageDet.page_name}</td>
                                                                                <td>{pageDet.page_category}</td>
                                                                                <td>{pageDet.recipient_id}</td>
                                                                                <td>{pageDet.addedon}</td>
                                                                                <td>
                                                                                    <div className="custom-switch">
                                                                                        <input type="checkbox" checked={pageDet.subscribe ? "checked" : ""} onChange={() => this.connectDisconnectInstaPage(pageDet)} className="custom-control-input" id={"switch_" + pageDet.recipient_id} name="connectdisconnect" />
                                                                                        <label className="custom-control-label cursor-pointer" htmlFor={"switch_" + pageDet.recipient_id}></label>
                                                                                        <span>{pageDet.subscribe ? "Connected" : "Not Connect"}</span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        ) : (null)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    ) : (null)}
                    {/* Instagram HTML Ends */}
                    {/* Telegram, HTML Start */}
                    {this.state.showType === 24 ? (
                        <div className="main-wrapper container-fluid">
                            <div className='chatform-Preview'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8'>
                                            <span className='text-light-blue d-inline-block cursor-pointer mb-3' onClick={() => { this.setState({ showType: 0 }) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.459" height="10.459" viewBox="0 0 20.459 10.459">
                                                    <path id="ic_arrow_forward_24px" d="M-.771,4l.922.922L-3.5,8.576H14.459V9.883H-3.5L.151,13.537l-.922.922L-6,9.229Z" transform="translate(6 -4)" fill="#0176ff" />
                                                </svg>

                                                &nbsp;Back</span>
                                            <div className='bg-white left-widgetsetings-box mb-foter-Fixed'>
                                                <div className="setting-section pb-1 pb-5">

                                                    <div className="mx-8 d-flex align-items-center">

                                                        <div className="main-heading main-heading-title py-5 ">
                                                            <h3 className="heding-text">Telegram</h3>

                                                        </div>
                                                        <div className='text-right ml-auto'>
                                                            <a href="https://snappy.desk.appypie.com/kb/article/how-to-integrate-telegram-with-livechat" classnames="text-light-blue d-inline-block cursor-pointer" style={{ color: '#0176ff' }} target="_blank" className="link-type"><img src={require('../../assets/img/icon/book.svg')} className="pr-3" />User Guide</a>
                                                        </div>
                                                    </div>

                                                    {this.state.teleUser  && (<div className="mx-8 pb-5">
                                                        <div className="main-heading main-heading-title py-5">
                                                            <h4>Your Livechat Is Connected To Telegram. You can test by texting on @{this.state.teleUser}</h4><p>Already connected. Reconnect if you have any trouble.</p>
                                                        </div>
                                                    </div>)}

                                                    {(this.state.success_teleToken || this.state.error_teleToken) && (<div className="mx-8">
                                                        <div className="main-heading main-heading-title pb-5">
                                                            {this.state.success_teleToken && (<span style={{ color: "#1ca91c" }}>{this.state.success_teleToken}</span>)}
                                                            {this.state.error_teleToken && (<span style={{ color: "#d12828" }}>{this.state.error_teleToken}</span>)}
                                                        </div>
                                                    </div>)}

                                                    <form onSubmit={this.handleTeleFormSubmit}>
                                                        <div className="settings-form-feilds mb-8 mx-8">
                                                            <div className="row">
                                                                <div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
                                                                    <TextField className="w-100" id="consumer_key_telegram" label="Telegram Bot Token" variant="outlined" name="consumer_key" value={this.state.teleToken} onChange={this.handleTeleChange} error={this.state.error_teleToken ? true : false} helperText={this.state.error_teleToken ? this.state.error_teleToken : ""} />
                                                                    {/* <div className="settings-feld k-flex align-items-center mb-2">
                                                                                                        <h6 className="card-title mb-0">Twitter Consumer Key</h6>
                                                                                                    </div>
                                                                                                    <div className="wrapperInfo">
                                                                                                        <input type="text" className="w-100" name="consumer_key" value={this.state.consumer_key} placeholder="Your Twitter Consumer Key" onChange={this.handleTDMChange} />
                                                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        

                                                        

                                                        <button className="btnBlueSettings mr-2 mx-8" type="submit">{this.state.teleUser  ? "Reconnect my Telegram account" : "Connect my Telegram account"}</button>

                                                        {this.state.teleUser  && (<button className="btnBlueSettings mr-2 mx-8" type="button" onClick={this.disconnectTelegram}>Disconnect</button>)}

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (null)}
                   
                    {/* Telegram HTML Ends */}
                    {/* Whatsapp HTML Start */}
                    {this.state.showType === 25 ? (
                        <div className="main-wrapper container-fluid">
                            <div className='chatform-Preview'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8'>
                                            <span className='text-light-blue d-inline-block cursor-pointer mb-3' onClick={() => { this.setState({ showType: 0 }) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.459" height="10.459" viewBox="0 0 20.459 10.459">
                                                    <path id="ic_arrow_forward_24px" d="M-.771,4l.922.922L-3.5,8.576H14.459V9.883H-3.5L.151,13.537l-.922.922L-6,9.229Z" transform="translate(6 -4)" fill="#0176ff" />
                                                </svg>

                                                &nbsp;Back</span>
                                            <div className='bg-white left-widgetsetings-box mb-foter-Fixed'>
                                                <div className="setting-section pb-1 pb-5">

                                                    <div className="mx-8 d-flex align-items-center">

                                                        <div className="main-heading main-heading-title py-5 ">
                                                            <h3 className="heding-text">Whatsapp</h3>

                                                        </div>
                                                        <div className='text-right ml-auto'>
                                                            <a href="https://snappy.desk.appypie.com/kb/article/how-to-deploy-livechat-on-whatsapp" classnames="text-light-blue d-inline-block cursor-pointer" style={{ color: '#0176ff' }} target="_blank" className="link-type"><img src={require('../../assets/img/icon/book.svg')} className="pr-3" />User Guide</a>
                                                        </div>
                                                    </div>

                                                    {this.state.whatsappUser  && (<div className="mx-8 pb-5">
                                                        <div className="main-heading main-heading-title py-5">
                                                            <h4>Your Livechat Is Connected To Whatsapp. You can test by texting on your number.</h4><p>Already connected. Reconnect if you have any trouble.</p>
                                                        </div>
                                                    </div>)}

                                                    {(this.state.success_whatsappToken || this.state.error_whatsappToken) && (<div className="mx-8">
                                                        <div className="main-heading main-heading-title pb-5">
                                                            {this.state.success_whatsappToken && (<span style={{ color: "#1ca91c" }}>{this.state.success_whatsappToken}</span>)}
                                                            {this.state.error_whatsappToken && (<span style={{ color: "#d12828" }}>{this.state.error_whatsappToken}</span>)}
                                                        </div>
                                                    </div>)}

                                                    <form onSubmit={this.handleWhatsappFormSubmit}>
                                                        <div className="settings-form-feilds mb-8 mx-8">
                                                            <div className="row">
                                                                <div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
                                                                    <TextField className="w-100" required={true} id="whatsapp_token" label="Whatsapp Bot Token" variant="outlined" name="consumer_key" value={this.state.whatsappAccessToken} onChange={this.handleWhatsappChange} error={this.state.error_teleToken ? true : false} helperText={this.state.error_teleToken ? this.state.error_teleToken : ""} />
                                                                    <TextField className="w-100"required={true}style={{marginTop:'10px',marginBottom:"10px"}} id="whatsapp_bus_id" label="Whatsapp Business Account Id" variant="outlined" name="consumer_key" value={this.state.whatsappAccessId} onChange={this.handleWhatsappChange} error={this.state.error_teleToken ? true : false} helperText={this.state.error_teleToken ? this.state.error_teleToken : ""} />
                                                                    <TextField className="w-100" required={true}id="whatsapp_num_id" label="Whatsapp Number Id" variant="outlined" name="consumer_key" value={this.state.whatsappPhoneId} onChange={this.handleWhatsappChange} error={this.state.error_teleToken ? true : false} helperText={this.state.error_teleToken ? this.state.error_teleToken : ""} />

                                                                    {/* <div className="settings-feld k-flex align-items-center mb-2">
                                                                                                        <h6 className="card-title mb-0">Twitter Consumer Key</h6>
                                                                                                    </div>
                                                                                                    <div className="wrapperInfo">
                                                                                                        <input type="text" className="w-100" name="consumer_key" value={this.state.consumer_key} placeholder="Your Twitter Consumer Key" onChange={this.handleTDMChange} />
                                                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        

                                                        

                                                        <button className="btnBlueSettings mr-2 mx-8" type="submit">{this.state.whatsappUser  ? "Reconnect my Whatsapp account" : "Connect my Whatsapp account"}</button>

                                                        {this.state.whatsappUser  && (<button className="btnBlueSettings mr-2 mx-8" type="button" onClick={this.disconnectWhatsapp}>Disconnect</button>)}

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (null)}
                   
                    {/* Whatsapp HTML Ends */}
                </div>  </>: <PopUp />}

            </>
        );
    }
}



export default Integration;